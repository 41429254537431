import React, {useEffect, useState} from 'react'
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  Tooltip,
  ListItemSecondaryAction,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AvatarMenu } from './AvatarMenu'
import Icon from '../../lib/assets/images/UmbrellaIcon.png'
import Logo from '../../lib/assets/images/UmbrellaLogo.png'
import menuItems from './menuItems'

export const MainMenu = ({ openMenu = false }) => {
    const location = useLocation()
    const showSubChildren = false
    const [open, setOpen] = useState(() => {
        const savedMenuState = localStorage.getItem('menuOpen');
        return savedMenuState !== null ? JSON.parse(savedMenuState) : openMenu;
    });
    const [openSubMenu, setOpenSubMenu] = useState(() => {
        const savedSubMenuState = localStorage.getItem('submenuOpen');
        return savedSubMenuState !== null ? JSON.parse(savedSubMenuState) : {};
    });
    useEffect(() => {
        localStorage.setItem('menuOpen', JSON.stringify(open));
    }, [open]);
    useEffect(() => {
        localStorage.setItem('submenuOpen', JSON.stringify(openSubMenu));
    }, [openSubMenu]);
  const handleToggleDrawer = () => {
    setOpen(!open)
  }
  const handleClick = (slug) => {
    if (openSubMenu === slug) {
      setOpenSubMenu(null)
    } else {
      setOpenSubMenu(slug)
    }
  }

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? 260 : 56,
        flexShrink: 0,
        transition: 'width 300ms ease',
        '& .MuiDrawer-paper': {
          width: open ? 260 : 56,
          overflowX: 'hidden',
          transition: 'width 300ms ease',
        },
      }}
    >
      <img
        onClick={handleToggleDrawer}
        src={open ? Logo : Icon}
        alt="Umbrella Index"
        style={{
          width: open ? 230 : open ? 'auto' : 36,
          height: 36,
          margin: open ? '20px auto 20px' : '10px auto 10px',
          display: open ? 'block' : 'inherit',
        }}
      />
      <Divider />
      <List disablePadding
         dense
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          maxHeight: open ? '70%' : '60%',
          overflowY: 'auto',
        }}
      >
        {/* Dynamic Navigation Items with Nested Children */}
        {menuItems
          .filter((item) => !item.disabled)
          .map((item, index, array) => {
            // Determine if a divider is needed
            const showDivider =
              index < array.length - 1 &&
              item.category !== array[index + 1].category
              const isActive = location.pathname.includes(item.path); // Check if the item's path is in the current URL

            return (
              <React.Fragment key={item.slug || index}>
                <Tooltip title={item.tooltip || item.text} placement="right" arrow>
                  <ListItem
                     dense
                    button
                    component={item.path ? RouterLink : 'div'}
                    to={item.path ? item.path : null}
                    onClick={(e) => {
                      if (!item.path && item.children) {
                        e.stopPropagation()
                        handleClick(item.slug);
                        /*setOpenSubMenu((prev) => ({
                          ...prev,
                          [item.slug]: !prev[item.slug],
                        }))*/
                      }
                    }}
                    sx={{
                        backgroundColor: isActive ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                    }}
                  >
                     <ListItemIcon
                        sx={{
                           color: isActive ? 'primary.main' : 'action.active', // Applying primary color when item is active
                        }}
                     >{item.icon}</ListItemIcon>
                    {open && <ListItemText primary={item.text} />}
                    {open && item.children && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={(e) => {
                            e.stopPropagation()
                              handleClick(item.slug);
                              /*setOpenSubMenu((prev) => ({
                                ...prev,
                                [item.slug]: !prev[item.slug],
                              }))*/
                          }}
                        >
                          {/*{openSubMenu[item.slug] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}*/}
                            {openSubMenu === item.slug ? <ExpandLess /> : <ExpandMore />}

                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </Tooltip>
                {open && item.children && (
                  <Collapse
                      in={openSubMenu === item.slug}
                      // in={openSubMenu[item.slug]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                       dense
                      component="div"
                      disablePadding
                      style={{ overflowY: 'auto', maxHeight: 225}}
                    >
                      {item.children.map((child, idx) => {
                         const isChildActive = location.pathname.includes(child.path);
                         return (
                            <React.Fragment key={child.slug || idx}>
                               <Tooltip title={child.tooltip || child.text} placement="right" arrow>
                                  <ListItem
                                     dense
                                     button
                                     component={child.path ? RouterLink : 'div'}
                                     to={child.path ? child.path : null}
                                     
                                     sx={{
                                        pl: 4,
                                        backgroundColor: isChildActive ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                                        
                                     }}
                                  >
                                     <ListItemIcon sx={{color: isChildActive ? 'primary.main' : 'action.active'}}>
                                        {child.icon}
                                     </ListItemIcon>
                                     {open && <ListItemText primary={child.text}/>}
                                     {showSubChildren && child.children && (
                                        <ListItemSecondaryAction>
                                           <IconButton
                                              edge="end"
                                              onClick={(e) => {
                                                 e.stopPropagation() // Prevent parent navigation
                                                 setOpenSubMenu((prev) => ({
                                                    ...prev,
                                                    [child.slug]: !prev[child.slug],
                                                 }))
                                              }}
                                           >
                                              {openSubMenu[child.slug] ? (
                                                 <ExpandLess/>
                                              ) : (
                                                 <ExpandMore/>
                                              )}
                                           </IconButton>
                                        </ListItemSecondaryAction>
                                     )}
                                  </ListItem>
                               </Tooltip>
                               {showSubChildren && child.children && (
                                  <Collapse
                                     in={openSubMenu[child.slug]}
                                     timeout="auto"
                                     unmountOnExit
                                  >
                                     <List component="div" disablePadding dense>
                                        {child.children.map((subChild, subIdx) => (
                                           <ListItem
                                              button
                                              key={subChild.slug || subIdx}
                                              component={RouterLink}
                                              to={subChild.path}
                                              sx={{pl: 8}}
                                           >
                                              <ListItemIcon>{subChild.icon}</ListItemIcon>
                                              {open && (
                                                 <ListItemText primary={subChild.text}/>
                                              )}
                                           </ListItem>
                                        ))}
                                     </List>
                                  </Collapse>
                               )}
                            </React.Fragment>
                         )
                      })}
                    </List>
                  </Collapse>
                )}
                {showDivider && <Divider />}
              </React.Fragment>
            )
          })}
      </List>
      <AvatarMenu expanded={open} />
    </Drawer>
  )
}
