const actionList = {
    services: [
        {
            "name": "api-ingestion-service",
            "description": "Handles the ingestion of data from various APIs, ensuring data is captured efficiently and stored for processing."
        },
        {
            "name": "document-processing-service",
            "description": "Processes various document formats to extract and structure data, making it usable for other services."
        },
        {
            "name": "email-processing-service",
            "description": "Analyzes and processes email content, extracting relevant information for further action and response handling."
        },
        {
            "name": "fax-processing-service",
            "description": "Converts faxed documents into digital format and processes content for digital usage."
        },
        {
            "name": "film-processing-service",
            "description": "Handles the processing of film media, converting analog film into digital formats for archiving and editing."
        },
        {
            "name": "media-processing-service",
            "description": "Processes various media types including video, audio, and images for editing, storage, and distribution."
        },
        {
            "name": "open-action-service",
            "description": "Provides an open platform for automating actions based on processed data inputs from other services."
        },
        {
            "name": "queue-manager-service",
            "description": "Manages message queues across services, ensuring that data flows smoothly and reliably from one service to another."
        },
        {
            "name": "sftp-ingestion-service",
            "description": "Securely ingests files and data over SFTP, ensuring data integrity and security during transfer."
        },
        {
            "name": "transcription-gpt-service",
            "description": "Utilizes GPT models to transcribe spoken language into written text with high accuracy."
        },
        {
            "name": "url-processing-service",
            "description": "Processes URLs to fetch data, handle redirections, and analyze web page content for further actions."
        },
        {
            "name": "url-scraping-service",
            "description": "Extracts data from web pages by scraping, supporting data collection and analysis efforts."
        },
        {
            "name": "vision-gpt-service",
            "description": "Leverages GPT-powered models to analyze and interpret visual content from images and videos."
        },
        {
            "name": "data-source-service",
            "description": "Centralizes and manages access to various data sources, providing a streamlined data access layer for other services."
        },
        {
            "name": "transcription-service",
            "description": "Provides accurate transcription of audio to text by using advanced machine learning techniques, supporting multiple languages."
        },
        {
            "name": "url-discovery-service",
            "description": "Automatically discovers new URLs based on predefined criteria and user behavior, aiding in data collection and web navigation."
        },
        {
            "name": "semantic-entity-generator-service",
            "description": "Analyzes text to identify and extract entities and relationships, creating structured semantic data from unstructured text inputs."
        },
        {
            "name": "deduplication-service",
            "description": "Removes duplicate entries from data sets, ensuring unique and clean data is available for processing and analysis."
        },
        {
            "name": "gpt-column-mapping-service",
            "description": "Maps user-provided data columns to predefined data structures using GPT models, facilitating automated data integration."
        }
    ],
    actions: [
        {
            "name": "Sync with Calabrio, then Transcribe",
            "description": "Automates the ingestion and transcription of audio call data from Calabrio. This process involves pulling audio files, ensuring they meet quality standards, and transcribing them to text for analysis.",
            "steps": [
                {
                    "service": "api-ingestion-service",
                    "description": "Connects to Calabrio's API to retrieve audio files.",
                    "config": {
                        "endpoint": "https://api.calabrio.com/getAudioFiles",
                        "method": "GET",
                        "headers": {
                            "Authorization": "Bearer ${API_TOKEN}"
                        },
                        "queryParams": {
                            "date": "${yesterday}",
                            "format": "mp3"
                        },
                        "retryPolicy": {
                            "maxAttempts": 3,
                            "backoffStrategy": "exponential"
                        }
                    }
                },
                {
                    "service": "media-processing-service",
                    "description": "Ensures audio files meet the predefined quality standards for transcription, including volume normalization and noise reduction.",
                    "config": {
                        "volumeNormalizationEnabled": true,
                        "noiseReductionLevel": "high"
                    }
                },
                {
                    "service": "transcription-service",
                    "description": "Transcribes the cleaned audio files to text using machine learning models.",
                    "config": {
                        "language": "English",
                        "model": "advanced",
                        "outputFormat": "json",
                        "timestampEnabled": true,
                        "speakerDiarization": {
                            "enabled": true,
                            "minSpeakerCount": 2,
                            "maxSpeakerCount": 5
                        }
                    }
                }
            ],
            "onSuccess": {
                "service": "data-source-service",
                "description": "Stores the transcribed text in the central data warehouse for further analysis.",
                "config": {
                    "storageService": "AWS S3",
                    "bucketName": "calabrio-transcriptions",
                    "dataFormat": "json"
                }
            },
            "onFailure": {
                "service": "notification-service",
                "description": "Sends an alert to the system administrator if the workflow fails at any step.",
                "config": {
                    "method": "email",
                    "recipients": ["admin@company.com"],
                    "message": "Failure encountered during the 'Sync with Calabrio, then Transcribe' action."
                }
            }
        },
        {
            "name": "Document Digitization and Indexing",
            "description": "Automates the conversion of physical documents to digital format and indexes the content for easy retrieval and analysis.",
            "steps": [
                {
                    "service": "fax-processing-service",
                    "config": {
                        "source": "Fax Machine",
                        "outputFormat": "PDF"
                    }
                },
                {
                    "service": "document-processing-service",
                    "config": {
                        "OCR": true
                    }
                },
                {
                    "service": "semantic-entity-generator-service",
                    "config": {
                        "extractEntities": true
                    }
                },
                {
                    "service": "data-source-service",
                    "config": {
                        "database": "DocumentDB",
                        "operation": "insert"
                    }
                }
            ]
        },
        {
            "name": "Automated Customer Support Interaction",
            "description": "Processes incoming customer emails and faxes, categorizes them, and provides automated responses or escalates issues as needed.",
            "steps": [
                {
                    "service": "email-processing-service",
                    "config": {
                        "filterRules": ["support", "inquiry", "urgent"]
                    }
                },
                {
                    "service": "fax-processing-service",
                    "config": {
                        "outputFormat": "text"
                    }
                },
                {
                    "service": "media-processing-service",
                    "config": {
                        "textAnalysis": true
                    }
                },
                {
                    "service": "open-action-service",
                    "config": {
                        "actionType": "generateResponse",
                        "escalateIfNecessary": true
                    }
                }
            ]
        },
        {
            "name": "Web Content Monitoring and Archival",
            "description": "Monitors specified URLs for changes, archives the content, and analyzes it for predefined keywords and entities.",
            "steps": [
                {
                    "service": "url-discovery-service",
                    "config": {
                        "targets": ["competitor websites", "news sites"],
                        "frequency": "daily"
                    }
                },
                {
                    "service": "url-scraping-service",
                    "config": {
                        "depth": 2
                    }
                },
                {
                    "service": "semantic-entity-generator-service",
                    "config": {
                        "context": "market trends"
                    }
                },
                {
                    "service": "data-source-service",
                    "config": {
                        "storageSolution": "Cloud Archive",
                        "dataRetention": "5 years"
                    }
                }
            ]
        },
        {
            "name": "E-commerce Product Data Enhancement",
            "description": "Integrates product data from various sources, enhances it with rich media processing, and updates the e-commerce platform listings.",
            "steps": [
                {
                    "service": "api-ingestion-service",
                    "config": {
                        "sourceAPIs": ["supplier APIs", "stock management"]
                    }
                },
                {
                    "service": "media-processing-service",
                    "config": {
                        "enhancements": ["image optimization", "video transcoding"]
                    }
                },
                {
                    "service": "data-source-service",
                    "config": {
                        "updateMethod": "Batch Update",
                        "target": "Product Listings Database"
                    }
                }
            ]
        },
        {
            "name": "Data Cleanup and Mapping",
            "description": "Performs data deduplication and maps user-provided data columns to internal structures using GPT.",
            "steps": [
                {
                    "service": "deduplication-service",
                    "config": {
                        "deduplicationKey": "email",
                        "retain": "mostRecent"
                    }
                },
                {
                    "service": "gpt-column-mapping-service",
                    "config": {
                        "model": "gpt-4",
                        "mappingRules": "Auto-map columns to predefined structures"
                    }
                },
                {
                    "service": "data-source-service",
                    "config": {
                        "database": "CleanedDataDB",
                        "operation": "insert"
                    }
                }
            ]
        }
    ]
}
export default actionList
