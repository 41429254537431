//PortalModeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react'
import { getSubdomain } from '../helpers/helpers'

const ModeContext = createContext()

export function useMode() {
  return useContext(ModeContext)
}

export const ModeProvider = ({ children }) => {
  const [mode, setMode] = useState('track') // default mode

  useEffect(() => {
    const subdomain = getSubdomain()
    if (subdomain !== 'localhost') {
      setMode(subdomain)
    }
  }, [])

  return (
    <ModeContext.Provider value={{ mode }}>{children}</ModeContext.Provider>
  )
}
