import React, {useCallback, useContext, useState} from 'react';
import {
   Button,
   Grid,
   LinearProgress,
   TextField,
   IconButton,
   InputAdornment,
   useTheme,
   useMediaQuery,
   Tab,
   Box
} from '@mui/material';  // Most UI components come from @mui/material
import { makeStyles } from '@mui/styles';  // makeStyles comes from @mui/styles
import { TabContext, TabList } from '@mui/lab';  // TabContext and TabList come from @mui/lab

import {SearchContext} from "../../lib/contexts";
import SearchResults from "../SearchResults";
import {
  Groups, Close,
  Email,
   Dashboard, Public, Search,
   Settings, Storage,
   ShoppingBasket,  SettingsSuggest,
} from '@mui/icons-material';
import SettingsMenu from "../SettingsMenu";

const useStyles = makeStyles(theme => ({
   tabPanel:{
      margin:'25px 0px 0px',
      padding:0,
      width: '100%'
   },
   loading:{width:'100%',margin:50, color:'inherit'},
   icon:{
      display:'inline',
      '& svg':{
         marginBottom:-5,
         marginRight:5
      }
   }
}));
const IntegrationsFilter = ({showSearch = false}) => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const {
      state: { query, resultsLoading, type, showExtendedCategories },
      actions: { setQuery, setType, forceSearch },
   } = useContext(SearchContext);

   const [menuAnchor, setMenuAnchor] = useState(null);
   const toggleMenu = useCallback((event) => {
      setMenuAnchor(menuAnchor ? null : event.currentTarget);
   },[menuAnchor]);

   const a11yProps = (index, active=true) => {
      return {
         value: String(index),
         id: `scrollable-tab-${index}`,
         'aria-controls': `tab-${index}`,
      };
   }
   const handleTabChange = useCallback((event, value)=>{
      setType(value);
   },[setType])
   const tabData = [
      { label: "All", icon: Public, a11yKey: '' },
      { label: "CRM Platforms", icon: Groups, a11yKey: 'crm-platforms' },
      { label: "Email Marketing", icon: Email, a11yKey: 'email-marketing' },
      { label: "Automation Tools", icon: SettingsSuggest, a11yKey: 'automation-tools' },
      { label: "Productivity Apps", icon: Dashboard, a11yKey: 'productivity-apps' },
      { label: "Data Management", icon: Storage, a11yKey: 'data-management' },
   ];

   return (
      <Grid
         container
         direction="row"
         justifyContent="space-evenly"
         alignItems="flex-start"
         spacing={2}
      >
         {showSearch && <Grid item xs={12}>
            <TextField
                fullWidth
                value={query}
                onChange={event => setQuery(event.target.value)}
                label="Seek and find..."
                margin="normal"
                variant="outlined"
                InputProps={{
                   endAdornment: <InputAdornment position="end">
                      {query && <IconButton
                          aria-label="clear"
                          onClick={() => setQuery('')}
                      >
                         <Close/>
                      </IconButton>}
                      <IconButton
                          aria-label="search"
                          onClick={forceSearch}
                      >
                         <Search/>
                      </IconButton>
                   </InputAdornment>
                }}
            />
         </Grid>}
         <Grid item xs={12} container
               spacing={0}
               direction="column"
               alignItems="center"
               justifyContent="center"
               style={{textAlign:'center', marginLeft: 0}}>
            <TabContext value={type}>
               <Grid item xs={12} container justifyContent={'space-between'}>
                  <Grid item xs={11}>
                     <TabList
                         style={{ width: '100%' }}
                         value={type}
                         onChange={handleTabChange}
                         indicatorColor="primary"
                         textColor="primary"
                        // variant={showExtendedCategories || matchSM ? "scrollable" : "standard"}
                         scrollButtons="auto"
                         aria-label="search categories"
                         centered={true}
                     >
                        {tabData.map((tab, index) => (
                            <Tab
                                key={index}
                                label={
                                   <span className={classes.icon}>
                    <tab.icon fontSize="small" />&nbsp;{tab.label}
                </span>
                                }
                                {...a11yProps(tab.a11yKey)}
                            />
                        ))}
                     </TabList>

                  </Grid>
                  {showSearch && <Grid item xs={1} style={{position: 'relative', marginTop: 5, left: matchSM ? -15 : 0}}>
                     <Button onClick={toggleMenu}><Settings/></Button>
                     {menuAnchor && <SettingsMenu menuAnchor={menuAnchor} toggleMenu={toggleMenu}/>}
                  </Grid>}
               </Grid>


               <Grid
                  container
                  item
                  xs={12}
               >
                  <Box className={classes.tabPanel}>
                     <Grid
                        container
                        item
                        xs={12}
                        justifyContent={'space-between'}
                        spacing={1}
                     >
                        { resultsLoading ? <LinearProgress color="secondary" className={classes.loading} /> : <SearchResults/> }
                     </Grid>
                  </Box>
               </Grid>
            </TabContext>
         </Grid>
      </Grid>
   );
};

export default IntegrationsFilter;
