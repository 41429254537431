//GlobalContext.js
import React, { createContext, useContext, useState } from 'react'

const GlobalContext = createContext()

export const useGlobal = () => useContext(GlobalContext)

export const GlobalProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  })
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  const value = {
    snackbar,
    setSnackbar,
    handleCloseSnackbar,
  }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}
