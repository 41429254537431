import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@mui/styles'
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { Backup, CloudUpload } from '@mui/icons-material'
import useUploadPresign from '../../lib/hooks/useUploadPresign'
import { DemoContext } from '../../lib/contexts/DemoContext'
import { getVideoFileDuration } from '../../lib/helpers/helpers'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    /* width: 'fit-content',*/
    '& svg': {
      marginBottom: -6,
      marginRight: 4,
    },
    '& input': {
      borderColor: theme.palette.primary.main,
    },
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft: 0,
    '& svg': {
      marginBottom: -6,
    },
  },
  addPatientListSmall: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 180,
    height: 44,
    margin: '20px auto 0px',
    fontSize: 13,
    display: 'block',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *': {
      color: theme.palette.primary.contrastText,
      display: 'inline',
    },
  },
  addPatientListText: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 214,
    height: 50,
    display: 'block',
    margin: '40px auto 0px !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  addPatientList: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 214,
    height: 50,
    marginTop: -25,
    marginRight: 25,
    display: 'block',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  shareSwitch: {
    color: theme.palette.primary.main,
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    color: theme.palette.primary.main,
  },
  container: {
    maxHeight: 250,
    overflowY: 'auto',
    margin: '20px auto 20px',
    textAlign: 'center',
    '& *': {
      textAlign: 'center',
    },
  },
  dynamicVarListItem: {
    marginBottom: 20,
  },
  dropzone: {
    maxWidth: '60%',
    margin: '20px auto auto',
    padding: 30,
    flex: 1,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#9d9d9d',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  dropzoneActive: {
    maxWidth: '60%',
    margin: '20px auto auto',
    padding: 30,
    flex: 1,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    color: '#9d9d9d',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
}))

const UploadMediaDialog = ({
  smallButton = true,
  forceOpen = false,
  disabled = false,
  children,
  setForceOpen,
  refetch,
  size,
  invisible = false,
  title = 'Upload Video / Photo',
  uploadType = null,
}) => {
  const classes = useStyles()

  const {
    actions: { enqueueSnackbar },
  } = useContext(DemoContext)
  const [open, setOpen] = useState(invisible)
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [email, setEmail] = useState('')
  const [file, setFile] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)
  const { fileExtension, filename, fileSize, contentType } = useMemo(() => {
    return file
      ? {
          fileExtension: file.fileExtension,
          filename: file.filename,
          fileSize: file.fileSize,
          contentType: file.contentType,
        }
      : {}
  }, [file])

  const {
    isLoading: presignIsLoading,
    isRefetching: presignIsRefetching,
    data: presignData,
    refetch: presignRefetch,
  } = useUploadPresign(fileExtension, filename, fileSize, contentType)
  const presignedUploadUrl = useMemo(
    () => (presignData && file ? presignData.presigned_url : null),
    [presignData, file]
  )
  console.log('presignData ', presignData)
  console.log('fileData ', file)

  useEffect(() => {
    setOpen(forceOpen)
  }, [forceOpen])

  const handleReset = useCallback(() => {
    setOpen(false)
    setSaving(false)
    setSaved(false)
    setEmail('')
    setFile(null)
    setThumbnail(null)
  }, [])

  const handleToggleOpen = useCallback(() => {
    setOpen(!open)
    if (forceOpen && open) {
      //setForceOpen('')
    }
  }, [open, forceOpen, setForceOpen])

  const handleInputChange = useCallback((event) => {
    setEmail(event.target.value)
  }, [])

  const handleUpload = useCallback(async () => {
    setSaving(true)
    const response = await fetch(
      new Request(presignedUploadUrl, {
        method: 'PUT',
        body: file.fileData,
        headers: new Headers({
          'Content-Type': 'video/*',
        }),
      })
    )
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      //onError();
      console.log('error')
      console.log(response)
      return
    } else {
      //console.log(response)
      setSaved(response)
      enqueueSnackbar('Video Received!', { variant: 'success' })
      handleReset()
    }
    // Let the caller know that the upload is done, so that it can send the URL
    // to the backend again, persisting it to the database.
    //onUploadReady();
  }, [file, presignedUploadUrl])

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useS3Upload({
      presignedUploadUrl,
      onUploadStart: (event) => console.log(event),
      onUploadReady: (event) => console.log(event),
      onError: (event) => console.log(event),
      // callbacks here
      getFilesFromEvent: (event) => myCustomFileGetter(event),
    })
  async function myCustomFileGetter(event) {
    const files = []
    const fileList = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files

    for (var i = 0; i < fileList.length; i++) {
      const file = fileList.item(i)

      Object.defineProperty(file, 'myProp', {
        value: true,
      })

      files.push(file)
    }
    console.log(files)
    return files
  }
  useEffect(() => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = async () => {
        const binaryStr = reader.result
        //console.log(binaryStr)
        //setFile(binaryStr);
        const duration = await getVideoFileDuration(file)
        setFile({
          duration: duration,
          fileData: binaryStr,
          fileExtension: file.name.split('.').pop(),
          filename: file.name,
          fileSize: file.size,
          contentType: file.type,
        })
      }
      return reader.readAsArrayBuffer(file)
    })
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result
        setThumbnail(binaryStr)
      }
      return reader.readAsDataURL(file)
    })
  }, [acceptedFiles])
  useEffect(() => {
    if (saved) {
      // save url to db - but dont really need to since we know what it is ahead of time
      if (refetch != undefined) {
        refetch()
      }
      //setForceOpen(false);
    }
  }, [saved])
  return (
    <>
      {!invisible && (
        <>
          {smallButton ? (
            <IconButton
              color="primary"
              component="label"
              onClick={handleToggleOpen}
              disabled={open || saving || disabled}
            >
              <AddAPhotoIcon />
            </IconButton>
          ) : (
            <Button
              fullWidth
              variant={'contained'}
              onClick={handleToggleOpen}
              disabled={open || saving || disabled}
              style={{ display: 'block', margin: '10px auto auto' }}
            >
              <CloudUpload style={{ marginTop: -2.5 }} />
              &nbsp;{title ?? 'Upload Photo / Video'}
            </Button>
          )}
        </>
      )}
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="upload-video-dialog-form"
        className={classes.form}
      >
        <DialogTitle id="upload-video-dialog-title">
          <Backup />
          &nbsp;{title}
        </DialogTitle>
        <DialogContent>
          {saving ? (
            <CircularProgress style={{ margin: '50px auto 50px' }} />
          ) : (
            <>
              <div
                {...getRootProps({
                  className: isDragActive
                    ? classes.dropzoneActive
                    : classes.dropzone,
                })}
              >
                {thumbnail && (
                  <Avatar
                    src={thumbnail}
                    variant={'square'}
                    style={{
                      width: '100%',
                      height: 250,
                      margin: '25px auto',
                      display: 'block',
                    }}
                  />
                )}
                <p>
                  Drag &apos;n&apos; drop any relevant media files, or click to
                  browse.
                </p>
                <input {...getInputProps()} />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleToggleOpen}
            className={classes.cancelButton}
            disabled={saving}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpload}
            className={classes.addButton}
            disabled={saving}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
const useS3Upload = ({
  presignedUploadUrl,
  onUploadStart,
  onUploadReady,
  onError,
}) => {
  return useDropzone({
    accept: ['video/*', 'image/*'],
    multiple: false,
    //disabled: typeof presignedUploadUrl !== 'string',
  })
}
export default UploadMediaDialog
