import { useQuery } from 'react-query';
import { getSession } from '../api/getSession';

const useSession = () => {
  const result = useQuery('session', () => getSession(), {
    refetchOnWindowFocus: true,
    staleTime: 500,
  });
  return result;
};

export default useSession;
