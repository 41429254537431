// InsightsTemplate.js
import React from 'react';
import { Alert, List, ListItem } from '@mui/material';
const InsightsTemplate = ({ insights }) => {
   return (
      <List>
         {insights.map((insight, index) => (
            <ListItem key={index}>
               <Alert severity={insight.severity} sx={{ width: '100%' }}>
                  {insight.message}
               </Alert>
            </ListItem>
         ))}
      </List>
   );
};

export default InsightsTemplate;
