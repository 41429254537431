// VehicleTemplate.js
import React from 'react';
import {
   Card, CardContent, CardMedia, Typography, List, ListItem, ListItemText, ListItemIcon, Divider
} from '@mui/material';
import {
   DirectionsCar as DirectionsCarIcon, CheckCircle as CheckCircleIcon, Error as ErrorIcon, Scale as ScaleIcon,
   Settings as SettingsIcon, ContactPhone as ContactPhoneIcon, Build as BuildIcon, Shield as ShieldIcon,
   LocalGasStation as LocalGasStationIcon, AvTimer as AvTimerIcon, ColorLens as ColorLensIcon,
   AirlineSeatReclineNormal, LocationOn as LocationOnIcon, AccountBalance,
   WarningAmber, Timeline as TimelineIcon, Info as InfoIcon, History as HistoryIcon
} from '@mui/icons-material';

const VehicleTemplate = ({ data }) => (
   <Card sx={{ m: 2, boxShadow: 3 }}>
      <CardMedia
         component="img"
         height="140"
         image={data.photo}
         alt={`${data.make} ${data.model}`}
      />
      <CardContent>
         <Typography gutterBottom variant="h5" component="div">
            {data.make} {data.model} - {data.year} <DirectionsCarIcon />
         </Typography>
         <Divider sx={{ my: 1 }} />
         <List dense>
            <ListItem>
               <ListItemIcon>
                  <ScaleIcon />
               </ListItemIcon>
               <ListItemText primary="Curb Weight" secondary={`${data.curbWeight} kg`} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <SettingsIcon />
               </ListItemIcon>
               <ListItemText primary="OEM Tires" secondary={data.oemTires} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <ErrorIcon color={data.operable ? 'success' : 'error'} />
               </ListItemIcon>
               <ListItemText primary="Operable" secondary={data.operable ? 'Yes' : 'No'} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <CheckCircleIcon color={data.insured ? 'primary' : 'warning'} />
               </ListItemIcon>
               <ListItemText primary="Insured" secondary={data.insured ? 'Yes' : 'No'} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <LocalGasStationIcon />
               </ListItemIcon>
               <ListItemText primary="Fuel Type" secondary={data.fuelType} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <AvTimerIcon />
               </ListItemIcon>
               <ListItemText primary="Mileage" secondary={`${data.mileage} km`} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <TimelineIcon />
               </ListItemIcon>
               <ListItemText primary="Emission Status" secondary={data.emissionStatus} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <ColorLensIcon />
               </ListItemIcon>
               <ListItemText primary="Color" secondary={data.color} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <ShieldIcon />
               </ListItemIcon>
               <ListItemText primary="Safety Features" secondary={data.safetyFeatures} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <LocationOnIcon />
               </ListItemIcon>
               <ListItemText primary="Location" secondary={data.location} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <HistoryIcon />
               </ListItemIcon>
               <ListItemText primary="Accident History" secondary={data.accidentHistory} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <BuildIcon />
               </ListItemIcon>
               <ListItemText primary="Service History" secondary={data?.serviceHistory?.map(item => `${item.date}: ${item.description}`)?.join(', ')} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <InfoIcon />
               </ListItemIcon>
               <ListItemText primary="Warranty Status" secondary={data.warrantyStatus} />
            </ListItem>
         </List>
      </CardContent>
   </Card>
);

export default VehicleTemplate;
