// PersonaBrowser.js
import React, { useState, useMemo } from 'react'
import { Chip, Stack, Typography, TextField, IconButton, Tooltip } from '@mui/material'
import personaBuckets from '../../lib/constants/persona_buckets'
import { Cancel, Sort } from '@mui/icons-material/'

export const PersonaBrowser = () => {
  const [selectedPersonas, setSelectedPersonas] = useState([])
  const [filter, setFilter] = useState('')
  const [sortAsc, setSortAsc] = useState(true)

  const toggleSort = () => {
    setSortAsc(!sortAsc)
  }
  const handleToggle = (id) => {
    if (selectedPersonas.includes(id)) {
      setSelectedPersonas(
        selectedPersonas.filter((personaId) => personaId !== id)
      )
    } else {
      setSelectedPersonas([...selectedPersonas, id])
    }
  }

  const sortedPersonas = useMemo(() => {
    return personaBuckets?.personaBuckets?.slice().sort((a, b) => {
      if (sortAsc) {
        return a.name.localeCompare(b.name)
      } else {
        return b.name.localeCompare(a.name)
      }
    })
  }, [personaBuckets, sortAsc])

  const filteredPersonas = useMemo(() => {
    const lowerFilter = filter.toLowerCase()
    return sortedPersonas.filter(
      (persona) =>
        selectedPersonas.includes(persona.id) ||
        persona.name.toLowerCase().includes(lowerFilter)
    )
  }, [sortedPersonas, selectedPersonas, filter])

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h6" style={{ flexGrow: 1, marginBottom: 10 }}>
          Persona Browser
        </Typography>
        <IconButton onClick={toggleSort}>
          <Sort
            style={{ transform: sortAsc ? 'rotate(0deg)' : 'rotate(180deg)' }}
          />
        </IconButton>
      </Stack>
      <TextField
        fullWidth
        label="Filter Personas"
        variant="outlined"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{ marginBottom: 20 }}
      />
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={1}
        sx={{ maxHeight: 220, overflowY: 'auto', marginBottom: 0 }}
      >
        {filteredPersonas.map((persona) => <Tooltip placement="left"
                key={persona.id + persona.name}
                title={
                  <React.Fragment>
                    <Typography color="inherit" style={{ fontWeight: 'bold' }}>Details</Typography> {/* Optional title */}
                    <Typography variant="body2" component="div" style={{ whiteSpace: 'pre-line' }}>
                      <strong>Interests:</strong> {persona.characteristics.interests.join(", ")}<br /><br />
                      <strong>Comm. Preferences:</strong> {persona.characteristics.communicationPreferences.join(", ")}<br /><br />
                      <strong>Age Range:</strong> {persona.characteristics.ageRange}<br /><br />
                      <strong>Purchase Patterns:</strong> {persona.characteristics.purchasePatterns}<br /><br />
                      <strong>Income Level:</strong> {persona.characteristics.incomeLevel}
                    </Typography>
                  </React.Fragment>
                }
                arrow
            >
              <Chip
                  key={persona.id + persona.name}
                  label={persona.name}
                  color={
                    selectedPersonas.includes(persona.id) ? 'primary' : 'default'
                  }
                  onClick={() => handleToggle(persona.id)}
                  onDelete={
                    selectedPersonas.includes(persona.id)
                        ? () => handleToggle(persona.id)
                        : undefined
                  }
                  deleteIcon={
                    selectedPersonas.includes(persona.id) ? <Cancel /> : null
                  }
                  style={{
                    marginBottom: 20,
                    backgroundColor: selectedPersonas.includes(persona.id)
                        ? 'primary.main'
                        : 'default',
                    color: selectedPersonas.includes(persona.id) ? 'white' : 'black',
                  }}
              />
            </Tooltip>
        )}
      </Stack>
    </>
  )
}

export default PersonaBrowser
