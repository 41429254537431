import React, {useMemo} from 'react'
import Screen from '../../components/Screen/Screen'
import {
    Box, List, Tooltip, Typography
} from '@mui/material'
import EntityBrowser from "../../components/EntityBrowser";
import IntegrationsFilter from "../../components/IntegrationsFilter";
import menuItems from "../../components/MainMenu/menuItems";
import {prettyDate, prettyDateTime, toCapitalizeFromUnderScore, truncate} from "../../lib/helpers/helpers";
import actionList from "../../lib/constants/testActions";
import EntityCard from "../../components/EntityBrowser/EntityCard";

const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const IntegrationsScreen = () => {
 // const sidebarConfig = useMemo(()=> menuItems.find(item => item.slug === "integrations")?.children,[menuItems])
    const sidebarConfig = useMemo(() => {
        const flattenNames = (items) =>
            items.flatMap(item => [item, ...(item.children ? flattenNames(item.children) : [])]);

        return flattenNames(menuItems.find(item => item.slug === "integrations")?.children || []);
    }, [menuItems]);
    const overrideColumns = [
    {
      field: 'entity_type',
      headerName: 'Entity Type',
      width: 150,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <span>{params.value}</span>
          </Tooltip>
      )
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 180,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <a href={params.value} target="_blank" rel="noopener noreferrer">{truncate(params.value, 50)}</a>
          </Tooltip>
      )
    },
    {
      field: 'content',
      headerName: 'Content',
      width: 300,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <span>{truncate(params.value, 100)}</span>
          </Tooltip>
      )
    },
    {
      field: 'sentiment',
      headerName: 'Sentiment',
      width: 120,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <strong style={{
              color: params.value === 'negative' ? 'red' : params.value === 'neutral' ? 'gray' : 'green'
            }}>
              {toCapitalizeFromUnderScore(params.value)}
            </strong>
          </Tooltip>
      )
    },
    {
      field: 'keywords',
      headerName: 'Keywords',
      width: 200,
      renderCell: (params) => (
          <Tooltip title={params.value.join(', ')}>
            <span>{truncate(params.value.join(', '), 100)}</span>
          </Tooltip>
      )
    },
    {
      field: 'interaction_count',
      headerName: 'Interactions',
      width: 130,
      renderCell: (params) => (
          <Tooltip title={`Total interactions: ${params.value}`}>
            <span>{params.value}</span>
          </Tooltip>
      )
    },
    {
      field: 'related_entities',
      headerName: 'Related Entities',
      width: 220,
      renderCell: (params) => (
          <Tooltip title={params.value.join(', ')}>
            <span>{truncate(params.value.join(', '), 100)}</span>
          </Tooltip>
      )
    },
    {
      field: 'confidentiality',
      headerName: 'Confidentiality',
      width: 140,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <span>{params.value ? 'Confidential' : 'Public'}</span>
          </Tooltip>
      )
    },
    {
      field: 'confidence_score',
      headerName: 'Confidence Score',
      width: 150,
      renderCell: (params) => (
          <Tooltip title={`${params.value.toFixed(2)}%`}>
            <span>{`${params.value.toFixed(2)}%`}</span>
          </Tooltip>
      )
    },
    {
      field: 'nsfw',
      headerName: 'NSFW',
      width: 100,
      renderCell: (params) => (
          <Tooltip title={params.value ? 'Not Safe for Work' : 'Safe'}>
                <span style={{ color: params.value ? 'red' : 'green' }}>
                    {params.value ? 'Yes' : 'No'}
                </span>
          </Tooltip>
      )
    },
    {
      field: 'scraped_at',
      headerName: 'Scraped At',
      width: 150,
      renderCell: (params) => {
        const content = params.value;
        return (
            <Tooltip title={prettyDateTime(content)} placement="top">
              <span>{prettyDate(content)}</span>
            </Tooltip>
        );
      }
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 100,
      renderCell: (params) => {
        const content = params.value;
        return (
            <Tooltip title={prettyDateTime(content)} placement="top">
              <span>{prettyDate(content)}</span>
            </Tooltip>
        );
      }
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 110,
      renderCell: (params) => {
        const content = params.value;
        return (
            <Tooltip title={prettyDateTime(content)} placement="top">
              <span>{prettyDate(content)}</span>
            </Tooltip>
        );
      }
    }
  ];
  return (
    <>
      <Screen title={'Integrations'} showSidebarNav={false} sidebarConfig={sidebarConfig}>
        <Box sx={{ transitionStyle, p: 2 }}>
            <Typography variant="h6" style={{ flexGrow: 1 }}>    Integrations</Typography>
            <IntegrationsFilter/>
            <List>
                {sidebarConfig?.map((item) => (
                    <>{item && <EntityCard layout={'list'} data={item} icon={item.icon}/>}</>
                ))}
            </List>
{/*
          <EntityBrowser internalUse={'integrations'}/>
*/}
        </Box>
      </Screen>
    </>
  )
}

export default IntegrationsScreen
