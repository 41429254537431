import axios from 'axios'
import useCurrentUser from '../hooks/useCurrentUser'
import { API_HOST } from '../constants/constants'
export const deleteOrder = async (orderID = null) => {
  const apiURL = API_HOST + '/orders/' + orderID
  const { token, userID } = useCurrentUser()

  try {
    const { data } = await axios.delete(apiURL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'x-gg-user-id': userID,
      },
    })
    return data.data
  } catch (error) {
    console.error(error)
  }
}
