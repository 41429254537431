import React, { useCallback, useContext, useMemo } from 'react'
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { makeStyles } from '@mui/styles'

import { SearchContext } from '../../lib/contexts'
import CopySettingsBox from '../CopySettingsBox'
import dayjs from 'dayjs'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const useStyles = makeStyles((theme) => ({
  menu: {
    minHeight: 500,
    marginTop: 50,
  },
  menuMobile: {
    marginTop: -150,
    height: '100%',
    maxHeight: '95vh',
  },
  box: {
    maxWidth: 500,
    minHeight: 500,
    padding: 20,
    borderRadius: 10,
  },
  boxMobile: {
    minHeight: 500,
    padding: 10,
    borderRadius: 10,
  },
  title: {
    marginBottom: 40,
  },
  titleMobile: {
    width: '90%',
    marginBottom: 0,
  },
}))
const SettingsMenu = ({ menuAnchor, toggleMenu }) => {
  const classes = useStyles()
  const theme = useTheme()
  const {
    state: {
      directLink,
      locale,
      filterExplicit,
      timeRange,
      timeRangeCustomStart,
      timeRangeCustomEnd,
      showExtendedCategories,
    },
    actions: {
      setLocale,
      setFilterExplicit,
      setTimeRange,
      setTimeRangeCustomStart,
      setTimeRangeCustomEnd,
      setShowExtendedCategories,
    },
  } = useContext(SearchContext)
  const matchSM = useMediaQuery(theme.breakpoints.only('xs'))
  const canUseBookmark = useMemo(
    () => window.sidebar || document.all || (window.opera && window.print),
    []
  )
  const bookmark = useCallback(() => {
    if (window.sidebar) {
      // Firefox
      window.sidebar.addPanel(window.document.title, directLink, '')
    } else if (window.opera && window.print) {
      // Opera
      var elem = document.createElement('a')
      elem.setAttribute('href', directLink)
      elem.setAttribute('title', window.document.title)
      elem.setAttribute('rel', 'sidebar')
      elem.click() //this.title=document.title;
    } else if (document.all) {
      // ie
      window.external.AddFavorite(directLink, window.document.title)
    }
  }, [directLink])
  const handleCustomStart = useCallback(
    (newValue) => {
      // Ensure newValue is a dayjs object and format it to an ISO string of the date part
      const formattedDate = dayjs(newValue).format('YYYY-MM-DD')
      setTimeRangeCustomStart(formattedDate)
    },
    [setTimeRangeCustomStart]
  )

  const handleCustomEnd = useCallback(
    (newValue) => {
      // Ensure newValue is a dayjs object and format it to an ISO string of the date part
      const formattedDate = dayjs(newValue).format('YYYY-MM-DD')
      setTimeRangeCustomEnd(formattedDate)
    },
    [setTimeRangeCustomEnd]
  )
  return (
    <>
      <Menu
        className={matchSM ? classes.menuMobile : classes.menu}
        id={'menu_settings'}
        key={'menu_settings'}
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={toggleMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            className={matchSM ? classes.boxMobile : classes.box}
            sx={{ boxShadow: 3 }}
          >
            <Grid container item>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.title}
                >
                  Search Settings
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography className={classes.title}>
                    Filter by Time
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    labelId="setting-time"
                    id="setting-time"
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                    fullWidth={true}
                  >
                    <MenuItem value={'any'}>Any Time</MenuItem>
                    <MenuItem value={'day'}>Past Day</MenuItem>
                    <MenuItem value={'week'}>Past Week</MenuItem>
                    <MenuItem value={'month'}>Past Month</MenuItem>
                    <MenuItem value={'year'}>Past Year</MenuItem>
                    <MenuItem value={'custom'}>Custom Range</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              {timeRange === 'custom' && (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={3}
                  style={{
                    marginTop: 0,
                    marginBottom: 20,
                    textAlign: 'center',
                  }}
                  justify={'center'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Grid
                    item
                    xs={6}
                    justify={'center'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <DatePicker
                      label="Start Date"
                      value={dayjs(timeRangeCustomStart)}
                      onChange={handleCustomStart}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth variant="outlined" />
                      )}
                      disableFuture
                      maxDate={dayjs(timeRangeCustomEnd)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    justify={'center'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <DatePicker
                      label="End Date"
                      value={dayjs(timeRangeCustomEnd)}
                      onChange={handleCustomEnd}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth variant="outlined" />
                      )}
                      disableFuture
                      minDate={dayjs(timeRangeCustomStart)}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography className={classes.title}>Language</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    labelId="setting-locale"
                    id="setting-locale"
                    value={locale}
                    onChange={(e) => setLocale(e.target.value)}
                    fullWidth={true}
                  >
                    <MenuItem value={'en'}>English</MenuItem>
                    <MenuItem>-------</MenuItem>
                    <MenuItem value={'zh'}>Chinese</MenuItem>
                    <MenuItem value={'nl'}>Dutch</MenuItem>
                    <MenuItem value={'fr'}>French</MenuItem>
                    <MenuItem value={'de'}>German</MenuItem>
                    <MenuItem value={'he'}>Hebrew</MenuItem>
                    <MenuItem value={'it'}>Italian</MenuItem>
                    <MenuItem value={'jp'}>Japanese</MenuItem>
                    <MenuItem value={'ru'}>Russian</MenuItem>
                    <MenuItem value={'es'}>Spanish</MenuItem>
                    <MenuItem value={'sv'}>Swedish</MenuItem>
                    <MenuItem>-------</MenuItem>
                    <MenuItem value={'af'}>Afrikaans</MenuItem>
                    <MenuItem value={'ar'}>Arabic</MenuItem>
                    <MenuItem value={'am'}>Amharic</MenuItem>
                    <MenuItem value={'bn'}>Bengali</MenuItem>
                    <MenuItem value={'da'}>Danish</MenuItem>
                    <MenuItem value={'fi'}>Finnish</MenuItem>
                    <MenuItem value={'el'}>Greek</MenuItem>
                    <MenuItem value={'hu'}>Hungarian</MenuItem>
                    <MenuItem value={'id'}>Indonesian</MenuItem>
                    <MenuItem value={'kn'}>Kannada</MenuItem>
                    <MenuItem value={'ko'}>Korean</MenuItem>
                    <MenuItem value={'ml'}>Malayalam</MenuItem>
                    <MenuItem value={'mt'}>Maltese</MenuItem>
                    <MenuItem value={'nb'}>Norwegian</MenuItem>
                    <MenuItem value={'pl'}>Polish</MenuItem>
                    <MenuItem value={'pt'}>Portuguese</MenuItem>
                    <MenuItem value={'ro'}>Romanian</MenuItem>
                    <MenuItem value={'sl'}>Slovenian</MenuItem>
                    <MenuItem value={'th'}>Thai</MenuItem>
                    <MenuItem value={'tr'}>Turkish</MenuItem>
                    <MenuItem value={'vi'}>Vietnamese</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography className={classes.title}>
                    Filter Explicit Content
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    labelId="setting-explicit"
                    id="setting-explicit"
                    value={filterExplicit}
                    onChange={() => setFilterExplicit(!filterExplicit)}
                    fullWidth={true}
                  >
                    <MenuItem value={true}>Safe for Work</MenuItem>
                    <MenuItem value={false}>NSFW</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <Typography
                    className={matchSM ? classes.titleMobile : classes.title}
                  >
                    Show Extended Categories
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center' }}>
                  <Switch
                    color={'primary'}
                    checked={showExtendedCategories}
                    onChange={() =>
                      setShowExtendedCategories(!showExtendedCategories)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent={'space-between'}>
                <CopySettingsBox label={true} />
                {canUseBookmark && (
                  <Grid item xs={12} alignItems={'center'}>
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      onClick={bookmark}
                    >
                      Bookmark
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </LocalizationProvider>
      </Menu>
    </>
  )
}
export default SettingsMenu
