import React, {useMemo} from 'react'
import Screen from '../../components/Screen/Screen'
import {
    Box, Tooltip,
} from '@mui/material'
import {
    DateRange, EventNote, HistoryEdu, ViewAgenda, AccessTime, Favorite,
} from "@mui/icons-material";
import {prettyDate, prettyDateTime, toCapitalizeFromUnderScore, truncate} from "../../lib/helpers/helpers";
import EntityBrowser from "../../components/EntityBrowser";
const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const EventsScreen = () => {
    const sidebarConfig = useMemo(() => [
        {
            icon: <EventNote />,
            text: 'All Events',
            slug: 'all-events',
            path: '/browse/events/all',
        },
        {
            icon: <HistoryEdu />,
            text: 'Past Events',
            slug: 'past-events',
            path: '/browse/events/past',
        },
        {
            icon: <ViewAgenda />,
            text: 'My Schedule',
            slug: 'my-schedule',
            path: '/browse/events/schedule',
        },
        {
            icon: <AccessTime />,
            text: 'Time Management',
            slug: 'time-management',
            path: '/browse/events/time-management',
        },
        {
            icon: <DateRange />,
            text: 'Calendar Views',
            slug: 'calendar-views',
            children: [
                { text: 'Daily View', path: '/browse/events/calendar/daily' },
                { text: 'Weekly View', path: '/browse/events/calendar/weekly' },
                { text: 'Monthly View', path: '/browse/events/calendar/monthly' },
                { text: 'Annual View', path: '/browse/events/calendar/annual' },
            ],
        },
        {
            text: "Favorites", Icon: Favorite, secondaryText: "0", children: [
                { text: "No Favorites added" }
            ]
        },
    ], []);
    const overrideColumns = [
        {
            field: 'Event Name',
            headerName: 'Event Name',
            width: 180,
            renderCell: (params) => (
               <Tooltip title={params.value}>
                   <span>{params.value}</span>
               </Tooltip>
            )
        },
        {
            field: 'Event Date',
            headerName: 'Event Date',
            width: 150,
            renderCell: (params) => (
               <Tooltip title={prettyDateTime(params.value)}>
                   <span>{prettyDateTime(params.value)}</span>
               </Tooltip>
            )
        },
        {
            field: 'Event Time',
            headerName: 'Event Time',
            width: 130,
            renderCell: (params) => (
               <Tooltip title={prettyDateTime(params.value)}>
                   <span>{prettyDateTime(params.value)}</span>
               </Tooltip>
            )
        },
        {
            field: 'Duration',
            headerName: 'Duration (hours)',
            width: 130,
            renderCell: (params) => (
               <Tooltip title={`${params.value} hours`}>
                   <span>{params.value} hours</span>
               </Tooltip>
            )
        },
        {
            field: 'Location',
            headerName: 'Location',
            width: 200,
            renderCell: (params) => (
               <Tooltip title={params.value}>
                   <span>{params.value}</span>
               </Tooltip>
            )
        },
        {
            field: 'Description',
            headerName: 'Description',
            width: 220,
            renderCell: (params) => (
               <Tooltip title={params.value}>
                   <span>{params.value}</span>
               </Tooltip>
            )
        },
        {
            field: 'Attendees Count',
            headerName: 'Attendees',
            width: 150,
            renderCell: (params) => (
               <Tooltip title={`${params.value} attendees`}>
                   <span>{params.value}</span>
               </Tooltip>
            )
        },
        {
            field: 'Event Type',
            headerName: 'Type',
            width: 150,
            renderCell: (params) => (
               <Tooltip title={params.value}>
                   <span>{params.value}</span>
               </Tooltip>
            )
        },
        {
            field: 'Calendar View',
            headerName: 'Calendar Link',
            width: 170,
            renderCell: (params) => (
               <Tooltip title="View in Calendar">
                   <a href={params.value} target="_blank" rel="noopener noreferrer">Open Calendar</a>
               </Tooltip>
            )
        }
    ];
    const demoData = [
        {
            id: "123e4567-e89b-12d3-a456-426614174000",
            title: 'Membership Renewal',
            start: new Date(2024, 8, 15), // September 15, 2024
            end: new Date(2024, 8, 15), // Same day event
            allDay: true,
            resource: { type: 'renewal' }
        },
        {
            id: "123e4567-e89b-12d3-a456-426614174001",
            title: 'Car Maintenance Appointment',
            start: new Date(2024, 8, 20, 9, 30), // September 20, 2024, at 9:30 AM
            end: new Date(2024, 8, 20, 11, 0), // Ends at 11:00 AM same day
            resource: { type: 'service', location: 'AAA Auto Service Center' }
        },
        {
            id: "123e4567-e89b-12d3-a456-426614174002",
            title: 'Travel Planning Seminar',
            start: new Date(2024, 8, 25, 18, 0), // September 25, 2024, at 6:00 PM
            end: new Date(2024, 8, 25, 20, 0), // Ends at 8:00 PM
            resource: { type: 'seminar', location: 'AAA Main Office' }
        },
        {
            id: "123e4567-e89b-12d3-a456-426614174003",
            title: 'Road Safety Training',
            start: new Date(2024, 8, 30, 14, 0), // September 30, 2024, at 2:00 PM
            end: new Date(2024, 8, 30, 16, 0), // Ends at 4:00 PM
            resource: { type: 'training', location: 'Community Center' }
        },
        {
            id: "123e4567-e89b-12d3-a456-426614174004",
            title: 'Annual General Meeting',
            start: new Date(2024, 9, 5, 10, 0), // October 5, 2024, at 10:00 AM
            end: new Date(2024, 9, 5, 12, 0), // Ends at 12:00 PM
            resource: { type: 'meeting', location: 'AAA Headquarters' }
        }
    ];
    
    
    return (
    <>
      <Screen title={'Event Index'} showSidebarNav={true} sidebarConfig={sidebarConfig}>
        <Box sx={{ transitionStyle, p: 0 }}>
            <EntityBrowser internalUse={'event'} allowCalendar={true} allowMap={true} demoData={demoData} overrideColumns={overrideColumns} />
        </Box>
      </Screen>
    </>
  )
}

export default EventsScreen
