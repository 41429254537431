import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getMailbox = async (organizationId, token, userID) => {
  const apiURL = API_HOST + '/mailbox';
  try {
    if (!userID || !token) {
      return false;
    }
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
        'x-sb-user-id': userID,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
