import React from 'react';
import { Box, Chip, Tooltip, IconButton } from '@mui/material';
import EntityBrowser from "../../components/EntityBrowser";
import {prettyDate, prettyDateTime, prettyNumber, truncate} from "../../lib/helpers/helpers";
import Screen from '../../components/Screen/Screen';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';

const transitionStyle = {
    transition: 'width 0.3s ease-in-out',
};

const AgentsScreen = () => {
    const overrideColumns = [
        {
            field: 'agent_name',
            headerName: 'Agent Name',
            width: 130,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 220,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span style={{ whiteSpace: 'pre-wrap', maxWidth: '200px'}}>{truncate(params.value, 50)}</span>
                </Tooltip>
            )
        },
        {
            field: 'personality',
            headerName: 'Personality',
            width: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{truncate(params.value, 50)}</span>
                </Tooltip>
            )
        },
        {
            field: 'interaction_count',
            headerName: 'Usage',
            width: 60,
            renderCell: (params) => (
                <Tooltip title={`Total interactions: ${params.value}`}>
                    <span>{prettyNumber(params.value)}</span>
                </Tooltip>
            )
        },
        {
            field: 'responds_to_email',
            headerName: 'Email',
            width: 100,
            renderCell: (params) => (
                <IconButton color={params.value ? "primary" : "default"}>
                    {params.value ? <CheckCircleOutline /> : <HighlightOff />}
                </IconButton>
            )
        },
        {
            field: 'responds_to_phone',
            headerName: 'SMS',
            width: 100,
            renderCell: (params) => (
               <IconButton color={params.value ? "primary" : "default"}>
                    {params.value ? <CheckCircleOutline /> : <HighlightOff />}
                </IconButton>
            )
        },
        {
            field: 'responds_to_live_chat',
            headerName: 'Live Chat',
            width: 120,
            renderCell: (params) => (
               <IconButton color={params.value ? "primary" : "default"}>
                    {params.value ? <CheckCircleOutline /> : <HighlightOff />}
                </IconButton>
            )
        },
        {
            field: 'attached_indexes',
            headerName: 'Attached Indexes',
            width: 220,
            renderCell: (params) => (
                <Box>
                    {params.value.map((index, idx) => (
                        <Tooltip key={idx} title={index}>
                            <Chip label={index} style={{ margin: 2 }} />
                        </Tooltip>
                    ))}
                </Box>
            )
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 100,
            renderCell: (params) => (
                <Tooltip title={prettyDateTime(params.value)} placement="top">
                    <span>{prettyDate(params.value)}</span>
                </Tooltip>
            )
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 110,
            renderCell: (params) => (
                <Tooltip title={prettyDateTime(params.value)} placement="top">
                    <span>{prettyDate(params.value)}</span>
                </Tooltip>
            )
        }
    ];
    const demoData = [
        {
            id: 1,
            agent_name: 'Aaria',
            description: 'Public Agent built for AAA',
            personality: 'Friendly and proactive',
            interaction_count: 150,
            attached_indexes: ['Index1', 'Index2'],
            responds_to_email: true,
            responds_to_phone: true,
            responds_to_live_chat: true,
            created_at: '2021-08-10T14:48:00.000Z',
            updated_at: '2023-08-01T09:20:00.000Z'
        },
        {
            id: 3,
            agent_name: 'Alpha',
            description: 'Next-gen Agent focused on Pets for Gen-Z & Gen-Alpha',
            personality: 'Supportive and empathetic',
            interaction_count: 300,
            attached_indexes: ['Index4', 'Index5', 'Index6'],
            responds_to_email: false,
            responds_to_phone: true,
            responds_to_live_chat: true,
            created_at: '2020-05-20T08:22:00.000Z',
            updated_at: '2023-08-03T12:30:00.000Z'
        },
        {
            id: 2,
            agent_name: 'Bella',
            description: 'Internal Agent for Umbrella',
            personality: 'Analytical and detailed-oriented',
            interaction_count: 245,
            attached_indexes: ['Index3'],
            responds_to_email: true,
            responds_to_phone: true,
            responds_to_live_chat: true,
            created_at: '2022-01-15T11:34:00.000Z',
            updated_at: '2023-07-21T15:45:00.000Z'
        },
        {
            id: 4,
            agent_name: 'Cova',
            description: 'Custom Agent for Covetrus focused on Pulse Vet Animal Science',
            personality: 'Analytical and detailed-oriented',
            interaction_count: 300,
            attached_indexes: ['Index4', 'Index5', 'Index6'],
            responds_to_email: true,
            responds_to_phone: false,
            responds_to_live_chat: true,
            created_at: '2020-05-20T08:22:00.000Z',
            updated_at: '2023-08-03T12:30:00.000Z'
        },
        {
            id: 5,
            agent_name: 'Dexa',
            description: 'Custom Agent for Idexx focused on EZ Vet Animal Science',
            personality: 'Analytical and detailed-oriented',
            interaction_count: 300,
            attached_indexes: ['Index4', 'Index5', 'Index6'],
            responds_to_email: true,
            responds_to_phone: false,
            responds_to_live_chat: true,
            created_at: '2020-05-20T08:22:00.000Z',
            updated_at: '2023-08-03T12:30:00.000Z'
        },
        {
            id: 55,
            agent_name: 'smsGPT',
            description: 'Experimental Public Agent via SMS',
            personality: 'Personality Matching',
            interaction_count: 234453,
            attached_indexes: ['Index4', 'Index5', 'Index6'],
            responds_to_email: false,
            responds_to_phone: true,
            responds_to_live_chat: false,
            created_at: '2020-05-20T08:22:00.000Z',
            updated_at: '2023-08-03T12:30:00.000Z'
        },
        {
            id: 63,
            agent_name: 'Umbrella Notes',
            description: 'Transcription training for Umbrella Notes service',
            personality: 'Analytical and detailed-oriented',
            interaction_count: 331241,
            attached_indexes: ['Index4', 'Index5', 'Index6'],
            responds_to_email: true,
            responds_to_phone: false,
            responds_to_live_chat: true,
            created_at: '2020-05-20T08:22:00.000Z',
            updated_at: '2023-08-03T12:30:00.000Z'
        },
        {
            id: 6,
            agent_name: 'Umbrella Vet',
            description: 'Next-gen Model testing for Umbrella Vet service',
            personality: 'Supportive and empathetic',
            interaction_count: 331241,
            attached_indexes: ['Index4', 'Index5', 'Index6'],
            responds_to_email: true,
            responds_to_phone: false,
            responds_to_live_chat: true,
            created_at: '2020-05-20T08:22:00.000Z',
            updated_at: '2023-08-03T12:30:00.000Z'
        },
        {
            id: 7,
            agent_name: 'Agent Delta',
            description: 'Next-gen Model testing A/B',
            personality: 'Dynamic and quick-witted',
            interaction_count: 400,
            attached_indexes: ['Index7'],
            responds_to_email: true,
            responds_to_phone: true,
            responds_to_live_chat: true,
            created_at: '2022-09-25T16:00:00.000Z',
            updated_at: '2023-08-02T10:00:00.000Z'
        },
        {
            id: 8,
            agent_name: 'Agent Epsilon',
            description: 'Next-gen Model testing A/B',
            personality: 'Calm and patient',
            interaction_count: 180,
            attached_indexes: ['Index8', 'Index9'],
            responds_to_email: false,
            responds_to_phone: false,
            responds_to_live_chat: false,
            created_at: '2021-12-31T13:50:00.000Z',
            updated_at: '2023-07-25T17:25:00.000Z'
        }
    ];

    return (
        <>
            <Screen title={'Language Models'}>
                <Box sx={{ transitionStyle, p: 2 }}>
                    <EntityBrowser overrideTitle={'AI Agents'} overrideColumns={overrideColumns} demoData={demoData} disableGridView={true}/>
                </Box>
            </Screen>
        </>
    )
}

export default AgentsScreen;
