import React, {useContext} from 'react';
import {
   Card,
   TablePagination, Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles/'

import SearchResultItem from "../SearchResultItem";
import {SearchContext} from "../../lib/contexts";

const useStyles = makeStyles({
   searchResult: {
      marginTop:20,
      width: '100%',
      maxHeight: 750,
      overflowY: 'auto',
      textAlign:'left'
   },
   noResults:{
      textAlign:'center',
      display:'block',
      margin:'50px auto 50px',
      '& p':{
         marginTop:-2,
         fontSize:15
      }
   },
   resultsTitle:{marginLeft:15, marginTop:20, textAlign:'center'}
});
const SearchResults = () => {
   const classes = useStyles();
   const {
      state: {searchResults, resultsLoading, debounceQuery, page, rowsPerPage, totalSearchResults },
      actions: {setPage, setRowsPerPage },
   } = useContext(SearchContext);

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   return(<>
      {
         debounceQuery !== "" && searchResults && !resultsLoading && searchResults.length > 0 ?
            <>
      <Typography variant="h5" component="h2" className={classes.resultsTitle}>
         Results for &quot;{debounceQuery}&quot;
      </Typography>
<Card className={classes.searchResult}>
         <>
            {searchResults.map((item, index)=>{

               return <SearchResultItem item={item} key={index}/>
            })}
         </>
</Card>
               <TablePagination
                  style={{margin:'auto',display:'block'}}
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalSearchResults}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </>
         :
         searchResults && searchResults.length === 0 ? <Typography variant={'h6'} className={classes.noResults}>No Results Found.<p>Try another Term or Category.</p></Typography> : ''
      }
</>
)
};

export default SearchResults;
