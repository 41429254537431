import React, {useContext, useEffect} from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { Brightness4, Brightness6 } from '@mui/icons-material';
import {ThemeContext} from "../../lib/contexts";
import {getUrlParam} from "../../lib/helpers/helpers";
const DarkModeToggle = () => {
   const { darkMode, toggleTheme } = useContext(ThemeContext);
   const desiredTheme = getUrlParam('t');
   
   useEffect(()=>{
      if (desiredTheme === 'light') {
      if (darkMode) {
         toggleTheme()
      }
      }
      if (desiredTheme === 'dark') {
      if (!darkMode) {
         toggleTheme()
      }
      }
   },[desiredTheme, darkMode, toggleTheme])
   
   return(    <Typography color="textSecondary" gutterBottom sx={{ display: 'flex', justifyContent: 'flex-end', mr: 4, mt:1 }}>
   
   <FormControlLabel labelPlacement={'start'} control={<>{darkMode ? <Brightness6/> : <Brightness4/>}&nbsp;<Switch checked={darkMode} size={'small'} onChange={toggleTheme}/></>} label={""} />
   </Typography>)
};
export default DarkModeToggle;
