import { createContext, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ContactsContext = createContext({});
export const ContactsContextProvider = ({ children, props }) => {
  const navigate = useNavigate();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [viewingContact, setViewingContact] = useState(null);
  
  const resetViewingContact = useCallback(()=>{
     setViewingContact(null);
     navigate("../contacts/", { replace: true })
  },[]);
  
  return (
    <ContactsContext.Provider
      value={{
        actions: {
           setSelectedContacts,
           setViewingContact,
           resetViewingContact,
        },
        state: {
           selectedContacts,
           viewingContact,
        }
      }}
      {...props}
    >
      {children}
    </ContactsContext.Provider>
  );
};
