import { useQuery } from 'react-query';
import { getResult } from '../api/getResult';

const useResult = (query, page, rowsPerPage, type, locale, filterExplicit, timeRange) => {
  const result = useQuery('search_'+ query+'_'+ page+'_'+ rowsPerPage+'_'+ type+'_'+ locale+'_'+ filterExplicit+'_'+ timeRange, () => getResult(query, page, rowsPerPage, type, locale, filterExplicit, timeRange), {
    refetchOnWindowFocus: false,
    staleTime: 50000,
  });
  return result;
};

export default useResult;
