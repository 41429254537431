// ActionConfigurator.js
import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, List, ListItem, ListItemText, ListItemIcon, Checkbox, FormControl, FormGroup, FormControlLabel, Select, MenuItem, Button } from '@mui/material';
import { Email, Notifications, Build, PlaylistAddCheck, Settings } from '@mui/icons-material';

const ActionConfigurator = () => {
   const [selectedTab, setSelectedTab] = useState(0);
   
   const handleChangeTab = (event, newValue) => {
      setSelectedTab(newValue);
   };
   
   const TabPanel = ({ children, value }) => {
      return selectedTab === value ? <Box sx={{ p: 2 }}>{children}</Box> : null;
   };
   
   return (
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
         <Typography variant="h6" sx={{ p: 2 }}>Automation Configuration</Typography>
         <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="automation tabs example">
            <Tab icon={<Settings />} label="Trigger" />
            <Tab icon={<PlaylistAddCheck />} label="Condition" />
            <Tab icon={<Build />} label="Action" />
         </Tabs>
         
         <TabPanel value={0}>
            <FormControl component="fieldset">
               <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Based on a trigger event (e.g., email opened, new funding)" />
                  <FormControlLabel control={<Checkbox />} label="Based on a date or schedule (e.g., every Tuesday)" />
               </FormGroup>
            </FormControl>
         </TabPanel>
         
         <TabPanel value={1}>
            <FormControl fullWidth>
               <Select value="" displayEmpty>
                  <MenuItem value="">Select condition</MenuItem>
                  <MenuItem value="sync-error">Sync Error Detected</MenuItem>
                  <MenuItem value="quota-near">Quota Nearly Reached</MenuItem>
                  <MenuItem value="invalid-data">Invalid Data Detected</MenuItem>
               </Select>
            </FormControl>
         </TabPanel>
         
         <TabPanel value={2}>
            <List>
               <ListItem>
                  <ListItemIcon>
                     <Email />
                  </ListItemIcon>
                  <ListItemText primary="Send Email Notification" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <Notifications />
                  </ListItemIcon>
                  <ListItemText primary="Send Slack Message" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <Build />
                  </ListItemIcon>
                  <ListItemText primary="Execute Script" />
               </ListItem>
               <ListItem>
                  <ListItemIcon>
                     <Settings />
                  </ListItemIcon>
                  <ListItemText primary="Update Database" />
               </ListItem>
            </List>
         </TabPanel>
      </Box>
   );
};

export default ActionConfigurator;
