import { useQuery } from 'react-query'
import { getSummary } from '../api'
import useCurrentUser from './useCurrentUser'

const useSummary = () => {
  const { token, userID } = useCurrentUser()
  const result = useQuery('summary_', () => getSummary(token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  })
  return result
}

export default useSummary
