import Screen from '../../components/Screen/Screen'
import React, {useCallback, useMemo, useState} from 'react'
import {
  Box,
  TextField,
  Chip,
  Autocomplete,
  Card,
  CardContent,
  Grid,
  Tabs,
  Tab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  FormGroup,
  Typography,
  Divider, Tooltip,
} from '@mui/material'
import { Description, LibraryBooks } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useDropzone } from 'react-dropzone'
import menuItems from "../../components/MainMenu/menuItems";
import {prettyDate, prettyDateTime, toCapitalizeFromUnderScore, truncate} from "../../lib/helpers/helpers";
const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}

const OpenIndexAdminDashboard = () => {
  const [entity, setEntity] = useState({
    type: '', // This could be "Product", "Research", "FAQ", "PatientRecord", etc.
    identifier: '', // This could be a product ID, patient number, etc.
    name: '', // The name of the entity, like "Science Diet Dog Food"
    description: '', // A brief description of the entity
    urls: [], // Any relevant URLs, such as to a product page or research article
    media: [], // Any relevant media like images, PDFs, audio files
    structuredData: {}, // A dynamic object to store any structured data following schema.org types
    relationships: [], // An array of objects defining various types of relationships
    metadata: {}, // Additional metadata that might not fit into structured data
    // ... any other fields that might be universally relevant
  })
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }


  const sidebarConfig = useMemo(()=> menuItems.find(item => item.slug === "index-management")?.children,[menuItems])
  const overrideColumns = [
    {
      field: 'entity_type',
      headerName: 'Entity Type',
      width: 150,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <span>{params.value}</span>
          </Tooltip>
      )
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 180,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <a href={params.value} target="_blank" rel="noopener noreferrer">{truncate(params.value, 50)}</a>
          </Tooltip>
      )
    },
    {
      field: 'content',
      headerName: 'Content',
      width: 300,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <span>{truncate(params.value, 100)}</span>
          </Tooltip>
      )
    },
    {
      field: 'sentiment',
      headerName: 'Sentiment',
      width: 120,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <strong style={{
              color: params.value === 'negative' ? 'red' : params.value === 'neutral' ? 'gray' : 'green'
            }}>
              {toCapitalizeFromUnderScore(params.value)}
            </strong>
          </Tooltip>
      )
    },
    {
      field: 'keywords',
      headerName: 'Keywords',
      width: 200,
      renderCell: (params) => (
          <Tooltip title={params.value.join(', ')}>
            <span>{truncate(params.value.join(', '), 100)}</span>
          </Tooltip>
      )
    },
    {
      field: 'interaction_count',
      headerName: 'Interactions',
      width: 130,
      renderCell: (params) => (
          <Tooltip title={`Total interactions: ${params.value}`}>
            <span>{params.value}</span>
          </Tooltip>
      )
    },
    {
      field: 'related_entities',
      headerName: 'Related Entities',
      width: 220,
      renderCell: (params) => (
          <Tooltip title={params.value.join(', ')}>
            <span>{truncate(params.value.join(', '), 100)}</span>
          </Tooltip>
      )
    },
    {
      field: 'confidentiality',
      headerName: 'Confidentiality',
      width: 140,
      renderCell: (params) => (
          <Tooltip title={params.value}>
            <span>{params.value ? 'Confidential' : 'Public'}</span>
          </Tooltip>
      )
    },
    {
      field: 'confidence_score',
      headerName: 'Confidence Score',
      width: 150,
      renderCell: (params) => (
          <Tooltip title={`${params.value.toFixed(2)}%`}>
            <span>{`${params.value.toFixed(2)}%`}</span>
          </Tooltip>
      )
    },
    {
      field: 'nsfw',
      headerName: 'NSFW',
      width: 100,
      renderCell: (params) => (
          <Tooltip title={params.value ? 'Not Safe for Work' : 'Safe'}>
                <span style={{ color: params.value ? 'red' : 'green' }}>
                    {params.value ? 'Yes' : 'No'}
                </span>
          </Tooltip>
      )
    },
    {
      field: 'scraped_at',
      headerName: 'Scraped At',
      width: 150,
      renderCell: (params) => {
        const content = params.value;
        return (
            <Tooltip title={prettyDateTime(content)} placement="top">
              <span>{prettyDate(content)}</span>
            </Tooltip>
        );
      }
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 100,
      renderCell: (params) => {
        const content = params.value;
        return (
            <Tooltip title={prettyDateTime(content)} placement="top">
              <span>{prettyDate(content)}</span>
            </Tooltip>
        );
      }
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 110,
      renderCell: (params) => {
        const content = params.value;
        return (
            <Tooltip title={prettyDateTime(content)} placement="top">
              <span>{prettyDate(content)}</span>
            </Tooltip>
        );
      }
    }
  ];

  return (
    <>
      <Screen title={'Index'} showSidebarNav={true} sidebarConfig={sidebarConfig}>
        <Box sx={{ p: 2 }}>
          <Card sx={transitionStyle}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={transitionStyle}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="quote type tabs"
                    variant="fullWidth"
                    sx={{ mb: 2 }}
                  >
                    <Tab label="Add Entity" icon={<Description />} />
                    <Tab label="Manage Collection" icon={<LibraryBooks />} />
                  </Tabs>

                  {tabValue === 0 && <AddEntity />}
                  {tabValue === 1 && <ManageCollection />}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Screen>
    </>
  )
}

const AddEntity = () => {
  const [entity, setEntity] = useState({
    type: '',
    identifier: '',
    name: '',
    description: '',
    urls: [],
    media: [],
    structuredData: {},
    relationships: [],
    metadata: {},
    sourceType: 'url',
    url: '',
    file: null,
    isPattern: false,
  })
  const [indexOptions, setIndexOptions] = useState([
    'Hoosier AAA Index',
    'Federation AAA Index',
    'Public AAA Index',
    'Hills Pet General Info Index',
    'Hills Pet Product Database Index',
    'Hills Pet Internal Policies Index',
    'General Pet Index',
  ])
  const [isPosting, setIsPosting] = useState(false)
  const [previewMode, setPreviewMode] = useState(true)
  const [previewData, setPreviewData] = useState({
    fileName: 'sample_audio.mp3',
    fileLength: '3:45',
    transcription: 'Hello, this is a sample transcription of the audio file.',
  })

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    console.log(`File type: ${file.type}`)
    setEntity((prev) => ({ ...prev, file }))
    // Simulate setting preview data based on the file
    setPreviewData({
      fileName: file.name,
      fileLength: 'Unknown', // Normally, you would extract this from file metadata
      transcription: 'Transcription not available.', // This would come from a transcription service
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const handleChange = (event) => {
    const { name, value } = event.target
    setEntity((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSourceTypeChange = (event) => {
    setEntity((prev) => ({
      ...prev,
      sourceType: event.target.value,
      url: '',
      file: null,
    }))
  }

  const togglePatternInput = () => {
    setEntity((prev) => ({
      ...prev,
      isPattern: !prev.isPattern,
    }))
  }

  const handleAddEntity = () => {
    if (previewMode) {
      console.log('Previewing entity:', entity)
      // Simulate preview of data processing
      setTimeout(() => {
        setPreviewMode(false)
      }, 2000)
    } else {
      console.log('Adding entity to index:', entity)
      setIsPosting(true)
      setTimeout(() => {
        setIsPosting(false)
        setPreviewMode(true) // Reset to preview mode after submission
      }, 2000)
    }
  }

  return (
      <>
        <Box sx={{ '& .MuiTextField-root': { m: 1 }, maxWidth: 500, mx: 'auto' }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Entity Source</FormLabel>
          <RadioGroup
            row
            name="sourceType"
            value={entity.sourceType}
            onChange={handleSourceTypeChange}
          >
            <FormControlLabel value="url" control={<Radio />} label="URL" />
            <FormControlLabel
              value="file"
              control={<Radio />}
              label="File Upload"
            />
          </RadioGroup>
          <FormGroup>
            {entity.sourceType === 'url' && (
              <FormControlLabel
                control={
                  <Switch
                    checked={entity.isPattern}
                    onChange={togglePatternInput}
                  />
                }
                label="Pattern URL"
              />
            )}
          </FormGroup>
        </FormControl>
        {entity.sourceType === 'url' && (
          <TextField
            label={entity.isPattern ? 'Pattern URL' : 'URL'}
            variant="outlined"
            name="url"
            fullWidth
            onChange={handleChange}
            value={entity.url}
            helperText={
              entity.isPattern
                ? 'Use patterns like *.example.com/products/[1-43]'
                : 'Enter a full URL'
            }
          />
        )}
        {entity.sourceType === 'file' && (
          <Box {...getRootProps()} padding={2} border="1px dashed gray">
            <input {...getInputProps()} />
            <p>Drag &apos;n&apos; drop some files here, or click to select files</p>
          </Box>
        )}

        {previewMode && (
          <>
            <Divider />
            <Box marginTop={2}>
              <Typography variant="h6">Preview of Audio File:</Typography>
              <Typography variant="body1">
                File Name: {previewData.fileName}
              </Typography>
              <Typography variant="body1">
                Length: {previewData.fileLength}
              </Typography>
              <Typography variant="body1">
                Transcription: {previewData.transcription}
              </Typography>
              <Autocomplete
                options={indexOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Index"
                    variant="outlined"
                  />
                )}
                fullWidth
              />
            </Box>
          </>
        )}

        <Box textAlign="center" marginTop={2}>
          <LoadingButton
            onClick={handleAddEntity}
            variant="contained"
            color="primary"
            loading={isPosting}
            disabled={isPosting}
          >
            {previewMode ? 'Add to Index' : 'Preview'}
          </LoadingButton>
        </Box>
      </Box>
    </>
  )
}

const ManageCollection = () => {
  const [entity, setEntity] = useState({
    categories: [],
    partner: null,
  })

  const indexOptions = [
    'Hills Pet General Info Index',
    'Hills Pet Product Database Index',
    'Hills Pet Internal Policies Index',
    'General Pet Index',
  ]

  const handlePartnerChange = (event, newValue) => {
    setEntity((prev) => ({
      ...prev,
      partner: newValue,
    }))
  }

  const handleIndexChange = (event, newValue) => {
    setEntity((prev) => ({
      ...prev,
      categories: newValue,
    }))
  }

  const handleDelete = (chipToDelete) => () => {
    setEntity((prev) => ({
      ...prev,
      categories: prev.categories.filter((chip) => chip !== chipToDelete),
    }))
  }

  return (
    <>
      <Box sx={{ '& .MuiTextField-root': { m: 1 }, maxWidth: 500, mx: 'auto' }}>
        <Autocomplete
          options={['Partner A', 'Partner B', 'Partner C']} // Example partners
          renderInput={(params) => (
            <TextField {...params} label="Choose Partner" variant="outlined" />
          )}
          onChange={handlePartnerChange}
        />
        {entity.partner && (
          <>
            <Autocomplete
              multiple
              options={indexOptions}
              freeSolo
              onChange={handleIndexChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={handleDelete(option)}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Indexes" variant="outlined" />
              )}
            />
          </>
        )}

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => console.log('Submitting Collection:', entity)}
          disabled={!entity.partner || entity.categories.length === 0}
        >
          Add to Collection
        </LoadingButton>
      </Box>
    </>
  )
}
export default OpenIndexAdminDashboard
