// ConversationTemplate.js
import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';

const ConversationTemplate = ({ data }) => {
   return (
      <Card sx={{ maxWidth: 345, mb: 2, boxShadow: 3 }}>
         <CardContent>
            <Typography gutterBottom variant="h6">
               {data.topic}
            </Typography>
            <List dense>
               {data.messages.map((message, index) => (
                  <ListItem key={index}>
                     <ListItemText
                        primary={`${message.sender} (${message.type})`}
                        secondary={
                           <>
                              <Typography component="span" variant="body2" color="text.primary">
                                 {message.content}
                              </Typography>
                              <Typography component="div" variant="caption" color="text.secondary">
                                 {new Date(message.timestamp).toLocaleString()}
                              </Typography>
                           </>
                        }
                     />
                  </ListItem>
               ))}
            </List>
         </CardContent>
      </Card>
   );
};

export default ConversationTemplate;
