import React, { useState } from 'react'
import {
   ListItem,
   ListItemIcon,
   ListItemText,
   Avatar,
   Menu,
   MenuItem,
   Divider,
   List, Typography, Paper, Stack,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import LogoutIcon from '@mui/icons-material/Logout'
import GearIcon from '@mui/icons-material/Settings'
import DarkModeToggle from "../DarkModeToggle";
import {Business, ReportProblem} from "@mui/icons-material";

export const AvatarMenu = ({expanded}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const user = {
    name: 'Austin Cameron',
    organization: 'Umbrella AI',
  }

  const menuOptions = [
    { icon: <GearIcon />, text: 'Settings', link: '/settings' },
    { icon: <PersonIcon />, text: 'My Profile', link: '/profile' },
    { icon: <HelpOutlineIcon />, text: 'Help Center', link: '/help' },
    { icon: <ReportProblem />, text: 'Status Page', link: '/status' },
    { icon: <LogoutIcon />, text: 'Log Out', link: '/logout' },
  ]

  return (
    <List sx={{ mt: 'auto' }}>
      <Divider />
      <ListItem button onClick={handleAvatarClick} sx={{ pt: 2 }}>
          <ListItemIcon>
              <Avatar sx={{ bgcolor: 'primary.main', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>AC</Avatar>
          </ListItemIcon>
          {expanded && <ListItemText primary={user.name} secondary={<Typography variant="body2" color="text.secondary"><Business fontSize={'10px'} style={{position:'relative', top:1}}/>&nbsp;{user.organization}</Typography>}/>}
      </ListItem>
       
       <Paper elevation={3}>
          <Menu
             anchorEl={anchorEl}
             open={Boolean(anchorEl)}
             onClose={handleMenuClose}
             anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
             }}
             transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
             }}
             PaperProps={{ sx: { width: 320, overflow: 'hidden' } }} // Ensures the Menu uses the full width of the Paper
          >
             <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{p:3}}>
                <ListItemIcon>
                   <Avatar sx={{ bgcolor: 'primary.main', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>AC</Avatar>
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle1">{user.name}</Typography>}
                              secondary={<Typography variant="body2" color="text.secondary"><Business fontSize={'10px'} style={{position:'relative', top:1}}/>&nbsp;{user.organization}</Typography>} />
                <DarkModeToggle />
             </Stack>
             {menuOptions.map((option) => (
                <MenuItem
                   key={option.text}
                   onClick={handleMenuClose}
                   component={RouterLink}
                   to={option.link}
                   sx={{ py: 1 }}
                >
                   <ListItemIcon>{option.icon}</ListItemIcon>
                   <ListItemText primary={option.text} />
                </MenuItem>
             ))}
          </Menu>
       </Paper>
    </List>
  )
}
