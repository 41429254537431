import React, { useState, useRef, useEffect } from 'react';
import { IconButton, LinearProgress } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { AudioVisualizer } from "../AudioVisualizer/AudioVisualizer";

export const SimpleAudioPlayer = ({ src }) => {
   const [isPlaying, setIsPlaying] = useState(false);
   const [progress, setProgress] = useState(0);
   const audioRef = useRef(null);
   
   useEffect(() => {
      // Initialize audio element
      audioRef.current = new Audio(src);
      const audio = audioRef.current;
      
      // Define event handler functions
      const updateProgress = () => {
         const { currentTime, duration } = audio;
         const progress = (currentTime / duration) * 100;
         setProgress(progress);
      };
      
      // Attach event listeners
      audio.addEventListener('timeupdate', updateProgress);
      
      return () => {
         // Cleanup listeners on component unmount
         audio.removeEventListener('timeupdate', updateProgress);
      };
   }, [src]); // Re-run effect if the src prop changes
   
   // Toggle play/pause
   const handlePlayPause = () => {
      if (isPlaying) {
         audioRef.current.pause();
      } else {
         audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
   };
   
   return (
      <div>
         <IconButton onClick={handlePlayPause} color="primary" size={'large'}>
            {isPlaying ? <PauseCircleOutlineIcon fontSize={'large'} /> : <PlayCircleOutlineIcon fontSize={'large'} />}
         </IconButton>
         
         {(!progress || progress) ? <AudioVisualizer audioUrl={src} progress={progress} audio={audioRef.current}/> : <LinearProgress />}
      </div>
   );
};
