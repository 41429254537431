import { getUser } from '../api'
import { useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'

const getUserFromLocalStorage = () => {
  const userObject =
    JSON.parse(localStorage.getItem('AUTH')) ||
    JSON.parse(sessionStorage.getItem('AUTH')) ||
    undefined

  return {
    token: userObject ? userObject.token : undefined,
    userID: userObject ? userObject.user_id : undefined,
    user: userObject ? userObject.user : undefined,
    plan:
      userObject && userObject.organization
        ? userObject.organization.plan
        : undefined,
    suggestedOrganization: userObject
      ? userObject.suggested_organization
      : undefined,
    organization: userObject ? userObject.organization : undefined,
    organizationID:
      userObject && userObject.user
        ? userObject.user.organization_id
        : undefined,
  }
}
const useCurrentUser = (forceRefresh = false) => {
  const localStorageUser = getUserFromLocalStorage()
  const { enqueueSnackbar } = useSnackbar()
  const result = useQuery('user', () => getUser(localStorageUser), {
    refetchOnWindowFocus: true,
    staleTime: forceRefresh
      ? 1
      : localStorageUser.plan !== undefined
        ? 60000 * 5
        : 5000,
    enabled: !!localStorageUser.token || !!localStorageUser.plan, // only perform the query if a token exists
  })
  const {
    isLoading: isLoadingCurrentUser,
    isRefetching: isRefetchingCurrentUser,
    refetch: refetchCurrentUser,
  } = useMemo(() => result, [result])

  const latestUser = useMemo(() => {
    return !isLoadingCurrentUser &&
      !isRefetchingCurrentUser &&
      result.isSuccess &&
      result.data &&
      result.data.user &&
      result.data.organization
      ? {
          user: result.data.user,
          suggested_organization: result.data.suggested_organization,
          organization: result.data.organization,
          organizationID: result.data.organization.id,
          plan:
            result.data && result.data.organization
              ? result.data.organization.plan
              : undefined,
          userID: result.data.user.id,
          token: result.data.token,
        }
      : localStorageUser
  }, [isLoadingCurrentUser, isRefetchingCurrentUser, result, localStorageUser])

  const currentUser = useMemo(() => {
    return {
      ...latestUser,
      isLoadingCurrentUser,
      isRefetchingCurrentUser,
      refetchCurrentUser,
    }
  }, [
    latestUser,
    isLoadingCurrentUser,
    isRefetchingCurrentUser,
    refetchCurrentUser,
  ])

  if (
    (result.data?.error || result.error) &&
    !window.location.pathname.includes('/login')
  ) {
    if (localStorage.getItem('AUTH')) {
      //console.log('found old user session AUTH')
      //if they were logged in
      enqueueSnackbar("Sorry, you've been logged out.", { variant: 'error' })
      localStorage.removeItem('AUTH')
      //window.location.href = `${window.location.protocol}//${window.location.host}/login`;
    }
  } else {
    if (result.data && result?.data?.user?.id && result.data.organization) {
      //console.log('setting session AUTH')
      //console.log('still logged in, new session details')
      localStorage.setItem(
        'AUTH',
        JSON.stringify({
          user: result.data.user,
          suggested_organization: result.data.suggested_organization,
          organization: result.data.organization,
          user_id: result.data.user.id,
          token: result.data.token,
        })
      )
    }
  }
  //console.log('currentUser ',currentUser)
  return currentUser
}
export default useCurrentUser
