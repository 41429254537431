import React from 'react'
import Screen from '../../components/Screen/Screen'
import ErrorMessage from './ErrorMessage'

const Error404 = () => {
  return (
    <>
        <Screen title={'Not Found'}>
            <ErrorMessage />
        </Screen>
    </>
  )
}

export default Error404
