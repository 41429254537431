import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {MapContainer, TileLayer, useMap} from 'react-leaflet';
import {Box, Grid, Typography, IconButton, Menu, MenuItem, ButtonGroup, Button, Stack, Divider} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AccessTime, Add, Business, LocationOn, Person, Send, Work} from '@mui/icons-material';
import L from "leaflet";

const SetViewToBounds = ({ allLocations }) => {
    const map = useMap();
    useEffect(() => {
        if (allLocations.length > 0) {
            const bounds = L.latLngBounds(allLocations.map(loc => [loc.latitude, loc.longitude]));
            map.fitBounds(bounds);
        }
    }, [allLocations, map]);
    return null;
};
const ContactHeader = ({ fullSize = false, sidebarMargin = false }) => {
    const { category, sub_category } = useParams();
    const [anchorElOne, setAnchorElOne] = React.useState(null);
    const [anchorElTwo, setAnchorElTwo] = React.useState(null);
    
    const openOne = Boolean(anchorElOne);
    const openTwo = Boolean(anchorElTwo);
    
    const handleClickOne = (event) => {
        setAnchorElOne(event.currentTarget);
    };
    
    const handleCloseOne = () => {
        setAnchorElOne(null);
    };
    
    const handleClickTwo = (event) => {
        setAnchorElTwo(event.currentTarget);
    };
    
    const handleCloseTwo = () => {
        setAnchorElTwo(null);
    };
    
    return (
       <Box style={{marginTop: 200}}>
           <MapContainer center={[37.0902, -95.7129]} zoom={2} style={{ height: fullSize ? '100vh' : 200, width: '100%',  position: 'absolute', top: 0, left: 0, right: 0, backgroundColor: 'rgba(255, 255, 255, 0.2)', zIndex: 900 }}>
               <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
               <SetViewToBounds allLocations={[]} /> {/* Example empty locations */}
           </MapContainer>
           <Box sx={{ marginLeft: sidebarMargin ? 31 : 5, height: 50, position: 'absolute', top: 150, left: 0, right: 0, pl: 8, pr: 5, backgroundColor: 'rgba(255, 255, 255, 0.2)', zIndex: 1000 }}>
               <Grid  container alignItems="center" spacing={2}>
                   <Grid item xs={8}>
                       <Stack direction="row" spacing={2} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                           <Box sx={{ display: 'flex', alignItems: 'center' }}>
                               <Person fontSize="small" />
                               <Typography variant="body1" sx={{ ml: 0.5 }}>John Doe</Typography>
                           </Box>
                           <Box sx={{ display: 'flex', alignItems: 'center' }}>
                               <Work fontSize="small" />
                               <Typography variant="body1" sx={{ ml: 0.5 }}>Account Manager</Typography>
                           </Box>
                           <Box sx={{ display: 'flex', alignItems: 'center' }}>
                               <Business fontSize="small" />
                               <Typography variant="body1" sx={{ ml: 0.5 }}>Umbrella AI</Typography>
                           </Box>
                           <Box sx={{ display: 'flex', alignItems: 'center' }}>
                               <LocationOn fontSize="small" />
                               <Typography variant="body1" sx={{ ml: 0.5 }}>Indianapolis, Indiana, USA</Typography>
                           </Box>
                           <Box sx={{ display: 'flex', alignItems: 'center' }}>
                               <AccessTime fontSize="small" />
                               <Typography variant="body1" sx={{ ml: 0.5 }}>Local Time: 08:45 PM</Typography>
                           </Box>
                       </Stack>
                   </Grid>
                   <Grid item xs={4} textAlign="right">
                       <IconButton onClick={handleClickOne}>
                           <MoreVertIcon />
                       </IconButton>
                       
                       <ButtonGroup variant="contained" aria-label="Add to List" style={{marginRight:20}}>
                           <Button variant="contained" color={'info'} startIcon={<Add />}>Add to List</Button>
                       </ButtonGroup>
                       <ButtonGroup variant="contained" aria-label="Send Message">
                           <Button startIcon={<Send />}>Send Message</Button>
                           <Button
                              size="small"
                              aria-controls={openTwo ? 'message-menu' : undefined}
                              aria-expanded={openTwo ? 'true' : undefined}
                              aria-haspopup="menu"
                              onClick={handleClickTwo}
                           >
                               <MoreVertIcon />
                           </Button>
                       </ButtonGroup>
                       <Menu
                          id="message-menu"
                          anchorEl={anchorElTwo}
                          open={openTwo}
                          onClose={handleCloseTwo}
                          MenuListProps={{
                              'aria-labelledby': 'split-button-button',
                          }}
                       >
                           <MenuItem onClick={handleCloseTwo}>SMS</MenuItem>
                           <MenuItem onClick={handleCloseTwo}>Email</MenuItem>
                       </Menu>
                       <Menu
                          anchorEl={anchorElOne}
                          open={openOne}
                          onClose={handleCloseOne}
                       >
                           <MenuItem onClick={handleCloseOne}>Archive</MenuItem>
                           <MenuItem onClick={handleCloseOne}>Block</MenuItem>
                           <MenuItem onClick={handleCloseOne}>Export</MenuItem>
                       </Menu>
                   </Grid>
               </Grid>
           </Box>
       </Box>
    );
};

export default ContactHeader;
