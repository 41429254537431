import React from 'react';
import {Typography, Tooltip, Paper, FormHelperText, Box} from '@mui/material';
import { Visibility, PersonAdd, Comment, Chat, QuestionAnswer, Pageview, ThumbUp, ThumbDown, Done } from '@mui/icons-material';
import {
   Timeline,
   TimelineConnector, TimelineContent,
   TimelineDot,
   TimelineItem,
   TimelineOppositeContent,
   TimelineSeparator
} from "@mui/lab";


const getIcon = (eventType, eventValue) => {
   switch (eventType) {
      case 'page_visit':
         return <Visibility />;
      case 'contact_created':
         return <PersonAdd />;
      case 'chat_message':
         return <Comment />;
      case 'ai_response':
         return <Chat />;
      case 'agent_response':
         return <QuestionAnswer />;
      case 'faq_view':
         return <Pageview />;
      case 'faq_vote':
         return eventValue === 'up' ? <ThumbUp /> : <ThumbDown />;
      default:
         return <Done />;
   }
};

const getPrettyTerm = (eventType) => {
   switch (eventType) {
      case 'ai_response':
         return 'AI Responded';
      case 'agent_response':
         return 'Agent Responded';
      case 'faq_vote':
         return 'FAQ Vote';
      default:
         return eventType.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
   }
};

// Component
const ActivityTemplate = ({ activityData }) => {
   return (
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 1200 }}>
         <Timeline align="alternate">
            {activityData.map((logItem, index) => (
               <TimelineItem key={logItem.id}>
                  <TimelineOppositeContent>
                     <Typography variant="body2" color="textSecondary">
                        {new Date(logItem.created_at).toLocaleString()}
                     </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                     <TimelineDot color={'primary'} >
                        {getIcon(logItem.event_type, logItem.event_value)}
                     </TimelineDot>
                     {index < activityData.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                     <Paper elevation={3} sx={{p:2}}>
                        <Typography variant="body1">
                           {getPrettyTerm(logItem.event_type)}
                        </Typography>
                        <Tooltip title={logItem.event_value || ''}>
                           <Typography variant="body2" color={'textSecondary'}>
                              {logItem.event_value}
                           </Typography>
                        </Tooltip>
                     </Paper>
                  </TimelineContent>
               </TimelineItem>
            ))}
         </Timeline>
         {activityData && !activityData.length && (
            <FormHelperText style={{ margin: 'auto' }}>
               None Found
            </FormHelperText>
         )}
      </Box>
   );
};

export default ActivityTemplate;
