import { useQuery } from 'react-query';
import { getSearchResults } from '../api/getSearchResults';

const useSearchResults = (query) => {
  const result = useQuery('results_'+(query ?? 'all'), () => getSearchResults(query), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useSearchResults;
