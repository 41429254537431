const testKnowledgeBaseGeneric = [
    {
        id: "art001",
        type: "Article",
        title: "Exploring the New Frontiers of Space Travel",
        summary: "An in-depth look at the advancements in space exploration technology and future missions planned.",
        authors: ["Alex Reed", "Samira Mohan"],
        publishDate: "2024-01-15",
        source: "Science Weekly",
        sourceUrl: "https://scienceweekly.example.com/new-frontiers-space-travel",
        tags: ["space", "technology", "exploration"],
        content: "With the recent launch of the Mars Rover, experts discuss the future of interplanetary travel...",
        relatedPhotos: ["pho001"],
        relatedVideos: ["vid001"],
        licenseType: "Creative Commons",
        citedBy: ["doc001"]
    },
    {
        id: "doc001",
        type: "Document",
        title: "International Trade Agreement",
        summary: "Detailed document outlining the terms of trade between two countries including tariffs and trade quotas.",
        involvedParties: ["Country A", "Country B"],
        documentDate: "2024-05-20",
        expirationDate: "2034-05-19",
        content: "This agreement is intended to foster trade by reducing tariffs on selected goods...",
        sourceUrl: "https://governmentdocuments.example.com/trade-agreement",
        licenseType: "Public Domain",
        relatedArticles: ["art001"],
        eventsMentioned: ["eve001"]
    },
    {
        id: "call001",
        type: "Phone Call",
        title: "Project Kickoff Meeting",
        summary: "Initial meeting discussing the scope and objectives of the new web development project.",
        participants: ["Project Manager: Emily Rosa", "Client: Tech Innovations Inc."],
        callDate: "2024-08-01",
        duration: "45 minutes",
        notes: "The client outlined their expectations for the project, including milestones and deliverables...",
        transcription: [
            { timestamp: "00:00:05", speaker: "Emily Rosa", text: "Thank you for joining the call today." },
            { timestamp: "00:00:10", speaker: "Client", text: "We're excited to get started on this project and have high expectations." }
        ],
        relatedPeople: ["per001"]
    },
    {
        id: "veh001",
        type: "Vehicle",
        name: "Tesla Model S",
        year: 2023,
        features: { engine: "Electric", range: "390 miles" },
        usage: "Used for daily commuting and long-distance travel.",
        owners: ["per001"]
    },
    {
        id: "per001",
        type: "Person",
        name: "Michael Johnson",
        description: "CEO of Innovate Ltd., leading initiatives in renewable energy and sustainable technologies.",
        age: 45,
        location: "Berlin, Germany",
        contactInfo: { email: "mjohnson@innovate.com", phone: "+49123456789" },
        interests: ["sustainability", "renewable energy", "technology"],
        vehicles: ["veh001"],
        phoneCalls: ["call001"],
        attendedEvents: ["eve001"],
        authoredArticles: ["art001"]
    },
    {
        id: "pla001",
        type: "Place",
        name: "Silicon Valley",
        description: "Region in Northern California that serves as a global center for high technology and innovation.",
        attributes: { area: "1,854 sq mi", population: "Approx. 3 million" },
        notableCompanies: ["Google", "Apple", "Facebook"],
        relatedEvents: ["eve001"]
    },
    {
        id: "org001",
        type: "Organization",
        name: "United Nations",
        description: "An international organization founded in 1945, committed to maintaining international peace and security.",
        location: "New York City, USA",
        members: ["193 Member States"],
        focusAreas: ["peacekeeping", "human rights", "sustainable development"],
        attendedEvents: ["eve001"]
    },
    {
        id: "eve001",
        type: "Event",
        title: "Annual Tech Conference",
        startDate: "2024-11-05",
        endDate: "2024-11-07",
        location: "Silicon Valley Convention Center",
        description: "The premier technology event of the year, featuring keynote speeches, workshops, and panels on emerging tech trends.",
        attendees: ["Tech enthusiasts", "Industry leaders", "Innovators"],
        discussedDocuments: ["doc001"],
        participants: ["per001", "org001"],
        place: "pla001"
    },
    {
        id: "pho001",
        type: "Photo",
        title: "Sunset over the Mountains",
        photographer: "Sarah Lee",
        dateTaken: "2024-07-10",
        location: "Rocky Mountains, USA",
        tags: ["sunset", "mountains", "nature"],
        sourceUrl: "https://photography.example.com/sunset-mountains",
        licenseType: "Creative Commons",
        relatedArticles: ["art001"]
    },
    {
        id: "vid001",
        type: "Video",
        title: "Documentary on Climate Change",
        creator: "John Doe",
        publishDate: "2024-04-22",
        duration: "2 hours",
        summary: "An extensive documentary exploring the impacts of climate change on different ecosystems around the world.",
        sourceUrl: "https://videohosting.example.com/climate-change-documentary",
        licenseType: "Creative Commons",
        relatedArticles: ["art001"]
    },
];
const testKnowledgeBase = [
    {
        id: "art001",
        type: "Article",
        title: "The Future of Roadside Assistance: Innovations at Hoosier AAA",
        summary: "An in-depth look at how Hoosier AAA is leveraging technology to enhance roadside assistance services.",
        authors: ["Jane Doe", "John Smith"],
        publishDate: "2024-01-15",
        source: "Hoosier AAA Magazine",
        sourceUrl: "https://hoosieraaa.example.com/future-roadside-assistance",
        tags: ["roadside assistance", "technology", "innovation"],
        content: "With advancements in mobile apps and GPS technology, Hoosier AAA is setting new standards in roadside assistance...",
        relatedPhotos: ["pho001"],
        relatedVideos: ["vid001"],
        licenseType: "Creative Commons",
        citedBy: ["doc001"]
    },
    {
        id: "doc001",
        type: "Document",
        title: "Member Services Agreement",
        summary: "Comprehensive document detailing the terms and conditions of Hoosier AAA membership services.",
        involvedParties: ["Hoosier AAA", "Members"],
        documentDate: "2024-05-20",
        expirationDate: "2025-05-19",
        content: "This agreement outlines the services provided by Hoosier AAA, including roadside assistance, travel planning, and insurance services...",
        sourceUrl: "https://hoosieraaa.example.com/member-services-agreement",
        licenseType: "Public Domain",
        relatedArticles: ["art001"],
        eventsMentioned: ["eve001"]
    },
    {
        id: "call001",
        type: "Phone Call",
        title: "Emergency Roadside Assistance Call",
        summary: "Call for assistance by a member whose vehicle broke down on the interstate.",
        participants: ["Member: John Doe", "Hoosier AAA Dispatcher"],
        callDate: "2024-08-01",
        duration: "15 minutes",
        notes: "The member's vehicle had a flat tire. A service truck was dispatched and arrived within 30 minutes...",
        transcription: [
            { timestamp: "00:00:05", speaker: "Dispatcher", text: "Thank you for calling Hoosier AAA. How can I assist you today?" },
            { timestamp: "00:00:10", speaker: "Member", text: "I'm on I-65 and my car has a flat tire." }
        ],
        relatedPeople: ["per001"]
    },
    {
        id: "veh001",
        type: "Vehicle",
        name: "2024 Ford Explorer",
        year: 2024,
        features: { engine: "V6", range: "400 miles" },
        usage: "Used for family trips and daily commuting.",
        owners: ["per001"]
    },
    {
        id: "per001",
        type: "Person",
        name: "John Doe",
        description: "Long-time Hoosier AAA member, frequent traveler, and car enthusiast.",
        age: 50,
        location: "Indianapolis, Indiana",
        contactInfo: { email: "johndoe@example.com", phone: "+13125551234" },
        interests: ["travel", "automobiles", "insurance"],
        vehicles: ["veh001"],
        phoneCalls: ["call001"],
        attendedEvents: ["eve001"],
        authoredArticles: ["art001"]
    },
    {
        id: "pla001",
        type: "Place",
        name: "Hoosier AAA Headquarters",
        description: "The main office for Hoosier AAA, providing a wide range of services to members.",
        attributes: { area: "10,000 sq ft", population: "50 employees" },
        notableCompanies: ["AAA Insurance", "AAA Travel"],
        relatedEvents: ["eve001"]
    },
    {
        id: "org001",
        type: "Organization",
        name: "Hoosier AAA",
        description: "A regional branch of the American Automobile Association, offering roadside assistance, insurance, and travel services.",
        location: "Indianapolis, Indiana",
        members: ["1.5 million members across Indiana"],
        focusAreas: ["roadside assistance", "travel services", "insurance"],
        attendedEvents: ["eve001"]
    },
    {
        id: "eve001",
        type: "Event",
        title: "Annual Member Appreciation Day",
        startDate: "2024-11-05",
        endDate: "2024-11-05",
        location: "Hoosier AAA Headquarters",
        description: "A day dedicated to celebrating and thanking Hoosier AAA members, featuring activities, giveaways, and informative sessions.",
        attendees: ["Hoosier AAA members", "Staff", "Local partners"],
        discussedDocuments: ["doc001"],
        participants: ["per001", "org001"],
        place: "pla001"
    },
    {
        id: "pho001",
        type: "Photo",
        title: "Hoosier AAA Service Truck",
        photographer: "Mary Johnson",
        dateTaken: "2024-07-10",
        location: "Indianapolis, Indiana",
        tags: ["service", "truck", "roadside assistance"],
        sourceUrl: "https://hoosieraaa.example.com/service-truck-photo",
        licenseType: "Creative Commons",
        relatedArticles: ["art001"]
    },
    {
        id: "vid001",
        type: "Video",
        title: "Hoosier AAA: A Century of Service",
        creator: "Hoosier AAA Media Team",
        publishDate: "2024-04-22",
        duration: "30 minutes",
        summary: "A documentary celebrating 100 years of Hoosier AAA's service to the community, from its founding to the present day.",
        sourceUrl: "https://hoosieraaa.example.com/century-of-service",
        licenseType: "Creative Commons",
        relatedArticles: ["art001"]
    },
    {
        id: "art002",
        type: "Article",
        title: "Hoosier AAA's Role in Promoting Road Safety in Indiana",
        summary: "An overview of Hoosier AAA's initiatives to enhance road safety across Indiana.",
        authors: ["Laura Wright", "Tom Harrison"],
        publishDate: "2024-02-10",
        source: "Hoosier AAA Monthly",
        sourceUrl: "https://hoosieraaa.example.com/road-safety-initiatives",
        tags: ["road safety", "Indiana", "traffic safety"],
        content: "Hoosier AAA has launched several campaigns aimed at reducing traffic accidents and promoting safe driving practices...",
        relatedPhotos: ["pho002", "pho003"],
        relatedVideos: ["vid002"],
        licenseType: "Creative Commons",
        citedBy: ["doc002"]
    },
    {
        id: "art003",
        type: "Article",
        title: "Benefits of AAA Travel Insurance: A Hoosier's Guide",
        summary: "A comprehensive guide to understanding and utilizing AAA travel insurance for Indiana residents.",
        authors: ["Katherine Bell", "Mark Davis"],
        publishDate: "2024-03-05",
        source: "Hoosier AAA Magazine",
        sourceUrl: "https://hoosieraaa.example.com/travel-insurance-guide",
        tags: ["travel insurance", "Indiana", "AAA benefits"],
        content: "AAA offers a range of travel insurance options tailored to the needs of Hoosiers, ensuring peace of mind while traveling...",
        relatedPhotos: ["pho004"],
        relatedVideos: ["vid003"],
        licenseType: "Creative Commons",
        citedBy: ["doc003"]
    },
    // Additional Documents
    {
        id: "doc002",
        type: "Document",
        title: "Hoosier AAA Road Safety Campaign Report",
        summary: "Detailed report on the outcomes of Hoosier AAA's road safety initiatives in Indiana.",
        involvedParties: ["Hoosier AAA", "Indiana Department of Transportation"],
        documentDate: "2024-06-15",
        expirationDate: "2025-06-14",
        content: "This report outlines the effectiveness of Hoosier AAA's road safety campaigns, including statistical analysis of accident rates...",
        sourceUrl: "https://hoosieraaa.example.com/road-safety-report",
        licenseType: "Public Domain",
        relatedArticles: ["art002"],
        eventsMentioned: ["eve002"]
    },
    {
        id: "doc003",
        type: "Document",
        title: "AAA Travel Insurance Policy Overview",
        summary: "A document providing an overview of AAA travel insurance policies available to Hoosier members.",
        involvedParties: ["Hoosier AAA", "AAA Insurance"],
        documentDate: "2024-04-10",
        expirationDate: "2025-04-09",
        content: "This document provides detailed information on the coverage and benefits of AAA travel insurance for Indiana residents...",
        sourceUrl: "https://hoosieraaa.example.com/travel-insurance-policy",
        licenseType: "Public Domain",
        relatedArticles: ["art003"],
        eventsMentioned: ["eve003"]
    },
    // Additional Phone Calls
    {
        id: "call002",
        type: "Phone Call",
        title: "Travel Insurance Inquiry",
        summary: "Call from a member inquiring about the details of AAA travel insurance coverage.",
        participants: ["Member: Sarah Johnson", "AAA Representative"],
        callDate: "2024-07-05",
        duration: "20 minutes",
        notes: "The member was interested in understanding the coverage options available for an upcoming trip to Europe...",
        transcription: [
            { timestamp: "00:00:05", speaker: "Representative", text: "Thank you for calling AAA. How can I assist you today?" },
            { timestamp: "00:00:12", speaker: "Member", text: "I'm planning a trip and want to know about your travel insurance options." }
        ],
        relatedPeople: ["per002"]
    },
    {
        id: "call003",
        type: "Phone Call",
        title: "Roadside Assistance Request",
        summary: "Call from a member whose vehicle broke down on I-70 near Indianapolis.",
        participants: ["Member: James Lee", "Hoosier AAA Dispatcher"],
        callDate: "2024-09-01",
        duration: "25 minutes",
        notes: "The member's vehicle had a dead battery. A service truck was dispatched and arrived within 45 minutes...",
        transcription: [
            { timestamp: "00:00:03", speaker: "Dispatcher", text: "This is Hoosier AAA. How can we help you today?" },
            { timestamp: "00:00:08", speaker: "Member", text: "My car won't start. I'm stranded on I-70 near the Keystone exit." }
        ],
        relatedPeople: ["per003"]
    },
    // Additional Vehicles
    {
        id: "veh002",
        type: "Vehicle",
        name: "2024 Chevrolet Tahoe",
        year: 2024,
        features: { engine: "V8", range: "350 miles" },
        usage: "Used for family trips and towing.",
        owners: ["per002"]
    },
    {
        id: "veh003",
        type: "Vehicle",
        name: "2023 Honda Accord",
        year: 2023,
        features: { engine: "Hybrid", range: "500 miles" },
        usage: "Used for daily commuting and weekend trips.",
        owners: ["per003"]
    },
    // Additional People
    {
        id: "per002",
        type: "Person",
        name: "Sarah Johnson",
        description: "Hoosier AAA member, frequent traveler, and insurance policyholder.",
        age: 34,
        location: "Fort Wayne, Indiana",
        contactInfo: { email: "sarahjohnson@example.com", phone: "+12602551234" },
        interests: ["travel", "insurance", "family"],
        vehicles: ["veh002"],
        phoneCalls: ["call002"],
        attendedEvents: ["eve002"],
        authoredArticles: []
    },
    {
        id: "per003",
        type: "Person",
        name: "James Lee",
        description: "Hoosier AAA member, car enthusiast, and frequent road tripper.",
        age: 29,
        location: "Bloomington, Indiana",
        contactInfo: { email: "jameslee@example.com", phone: "+18125551234" },
        interests: ["automobiles", "road trips", "technology"],
        vehicles: ["veh003"],
        phoneCalls: ["call003"],
        attendedEvents: ["eve003"],
        authoredArticles: []
    },
    // Additional Places
    {
        id: "pla002",
        type: "Place",
        name: "Indianapolis Motor Speedway",
        description: "Famous racetrack in Indianapolis, home to the Indy 500 and other major racing events.",
        attributes: { area: "559 acres", population: "300,000 spectators during events" },
        notableCompanies: ["IndyCar", "NASCAR"],
        relatedEvents: ["eve004"]
    },
    {
        id: "pla003",
        type: "Place",
        name: "Brown County State Park",
        description: "Popular state park in Indiana, known for its scenic beauty and outdoor activities.",
        attributes: { area: "15,776 acres", population: "Varies by season" },
        notableCompanies: ["Indiana State Parks", "AAA Travel"],
        relatedEvents: ["eve005"]
    },
    // Additional Organizations
    {
        id: "org002",
        type: "Organization",
        name: "AAA Travel",
        description: "A division of AAA that provides travel planning services, insurance, and more.",
        location: "Indianapolis, Indiana",
        members: ["Millions of members nationwide"],
        focusAreas: ["travel services", "insurance", "roadside assistance"],
        attendedEvents: ["eve003", "eve004"]
    },
    {
        id: "org003",
        type: "Organization",
        name: "Indiana Department of Transportation",
        description: "State agency responsible for transportation infrastructure in Indiana.",
        location: "Indianapolis, Indiana",
        members: ["State employees and contractors"],
        focusAreas: ["infrastructure", "road safety", "public transportation"],
        attendedEvents: ["eve006"]
    },
    // Additional Events
    {
        id: "eve002",
        type: "Event",
        title: "Road Safety Summit",
        startDate: "2024-10-15",
        endDate: "2024-10-15",
        location: "Hoosier AAA Headquarters",
        description: "A summit focusing on improving road safety across Indiana, hosted by Hoosier AAA.",
        attendees: ["Road safety experts", "Government officials", "Hoosier AAA members"],
        discussedDocuments: ["doc002"],
        participants: ["per002", "org003"],
        place: "pla001"
    },
    {
        id: "eve003",
        type: "Event",
        title: "Travel Expo 2024",
        startDate: "2024-05-20",
        endDate: "2024-05-21",
        location: "Indiana Convention Center",
        description: "An expo showcasing the latest travel services and insurance products, hosted by AAA Travel.",
        attendees: ["Travel enthusiasts", "Industry professionals", "AAA members"],
        discussedDocuments: ["doc003"],
        participants: ["per003", "org002"],
        place: "pla001"
    },
    // Additional Photos
    {
        id: "pho002",
        type: "Photo",
        title: "Indiana Road Safety Campaign",
        photographer: "Jessica Brown",
        dateTaken: "2024-02-10",
        location: "Indianapolis, Indiana",
        tags: ["road safety", "campaign", "Indiana"],
        sourceUrl: "https://photoservice.example.com/indiana-road-safety",
        licenseType: "Creative Commons",
        relatedArticles: ["art002"]
    },
    {
        id: "pho003",
        type: "Photo",
        title: "Family Road Trip",
        photographer: "Michael Green",
        dateTaken: "2024-06-15",
        location: "Brown County State Park, Indiana",
        tags: ["road trip", "family", "Indiana"],
        sourceUrl: "https://photoservice.example.com/family-road-trip",
        licenseType: "Creative Commons",
        relatedArticles: ["art003"]
    },
    // Additional Videos
    {
        id: "vid002",
        type: "Video",
        title: "Road Safety Tips from Hoosier AAA",
        creator: "Hoosier AAA Media Team",
        publishDate: "2024-03-01",
        duration: "10 minutes",
        summary: "A short video providing essential road safety tips for Indiana drivers.",
        sourceUrl: "https://videoservice.example.com/road-safety-tips",
        licenseType: "Creative Commons",
        relatedArticles: ["art002"]
    },
    {
        id: "vid003",
        type: "Video",
        title: "Travel Insurance Explained",
        creator: "AAA Insurance Team",
        publishDate: "2024-05-05",
        duration: "15 minutes",
        summary: "An informative video explaining the benefits and coverage options of AAA travel insurance.",
        sourceUrl: "https://videoservice.example.com/travel-insurance-explained",
        licenseType: "Creative Commons",
        relatedArticles: ["art003"]
    },
];


export default testKnowledgeBase;
