import React, { useState } from 'react';
import Screen from '../../components/Screen/Screen';
import { Box, Tab, Grid, Typography, List } from '@mui/material';
import EntityCard from "../../components/EntityBrowser/EntityCard";
import actionList from "../../lib/constants/testActions";
import { ActionSetup } from "../../components/ActionSetup/ActionSetup";
import { Settings, Build, Tune } from '@mui/icons-material';
import {TabContext, TabList} from "@mui/lab";
import {makeStyles} from "@mui/styles";
import ActionConfigurator from "../../components/ActionConfigurator/ActionConfigurator";

const transitionStyle = {
   transition: 'width 0.3s ease-in-out',
};

const useStyles = makeStyles(theme => ({
   tabPanel:{
      margin:'25px 0px 0px',
      padding:0,
      width: '100%'
   },
   loading:{width:'100%',margin:50, color:'inherit'},
   icon:{
      display:'inline',
      '& svg':{
         marginBottom:-5,
         marginRight:5
      }
   }
}));
const ActionsScreen = () => {
   const classes = useStyles();
   const [value, setValue] = useState(0);
   
   const handleChange = (event, newValue) => {
      setValue(newValue);
   };
   
   return (
      <Screen title={'Actions'}>
         <Box sx={{ transitionStyle, p: 2, overflowY: 'auto', maxHeight: 800 }}>
            
            <TabContext value={value}>
            <TabList
               style={{ width: '100%' }}
               value={value}
               onChange={handleChange}
               indicatorColor="primary"
               textColor="primary"
               scrollButtons="auto"
               aria-label="action & service tabs"
               centered={true}
            >
               <Tab label={
                       <span className={classes.icon}>
                    <Tune fontSize="small"/>&nbsp;Setup
                </span>}  />
               <Tab label={
                       <span className={classes.icon}>
                    <Settings fontSize="small"/>&nbsp;Services
                </span>}  />
               <Tab label={
                       <span className={classes.icon}>
                    <Build fontSize="small"/>&nbsp;Automations
                </span>}  />
            </TabList>
            </TabContext>
            {value === 0 && (
               <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                     <Grid item xs={8}>
                        <ActionSetup />
                     </Grid>
                     <Grid item xs={4}>
                        <ActionConfigurator />
                     </Grid>
                  </Grid>
               </Box>
            )}
            {value === 1 && (
               <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>Services</Typography>
                  <List sx={{ overflowY: 'auto', maxHeight: 800 }}>
                     {actionList?.services?.map((item) => (
                        <EntityCard key={item.id} layout={'list'} data={item} icon={item.icon} />
                     ))}
                  </List>
               </Box>
            )}
            {value === 2 && (
               <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>Actions</Typography>
                  <List sx={{ overflowY: 'auto', maxHeight: 800 }}>
                     {actionList?.actions?.map((item) => (
                        <EntityCard key={item.id} layout={'list'} data={item} icon={item.icon} />
                     ))}
                  </List>
               </Box>
            )}
         </Box>
      </Screen>
   );
};

export default ActionsScreen;
