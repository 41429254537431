import { useQuery } from 'react-query'
import { getUser } from '../api'

const useUser = (forceRefresh = false) => {
  const result = useQuery('user', () => getUser(), {
    refetchOnWindowFocus: true,
    staleTime: forceRefresh ? 1 : 5000,
  })
  return result
}

export default useUser
