import React, { useState } from 'react'
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  Rating,
  Tooltip,
} from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import VideocamIcon from '@mui/icons-material/Videocam'
import {
  getInitials,
  prettyDateTime,
  truncate,
} from '../../lib/helpers/helpers'
import MediaModal from '../MediaModal/MediaModal'
import RefreshButton from '../RefreshButton/RefreshButton'
import useReviews from '../../lib/hooks/useReviews'

const recentReviews = [
  {
    id: 1,
    rating: 4,
    name: 'Parker G',
    text: 'Great service, on time delivery. Highly recommend!',
    date: 'April 10, 2024',
    media: { type: 'image', src: 'https://picsum.photos/200/300' },
  },
  {
    id: 2,
    rating: 5,
    name: 'Corey N',
    text: 'The driver was very professional and the package was in perfect condition.',
    date: 'April 9, 2024',
    media: {
      type: 'video',
      src: 'https://picsum.photos/200/300',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
  },
  {
    id: 3,
    rating: 3,
    name: 'Austin C',
    text: 'Service was okay, but the delivery was a day late.',
    date: 'April 8, 2024',
  },
  {
    id: 4,
    rating: 4,
    name: 'Parker G',
    text: 'Great service, on time delivery. Highly recommend!',
    date: 'April 10, 2024',
    media: { type: 'image', src: 'https://picsum.photos/200/300' },
  },
  {
    id: 5,
    rating: 5,
    name: 'Corey N',
    text: 'The driver was very professional and the package was in perfect condition.',
    date: 'April 9, 2024',
    media: {
      type: 'video',
      src: 'https://picsum.photos/200/300',
      thumbnail: 'https://picsum.photos/seed/picsum/200/300',
    },
  },
  {
    id: 6,
    rating: 3,
    name: 'Austin C',
    text: 'Service was okay, but the delivery was a day late.',
    date: 'April 8, 2024',
  },
]

const RecentReviews = ({ mode }) => {
  const { data, isLoading, isRefetching, refetch } = useReviews()
  const [open, setOpen] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState({})

  const handleOpen = (media) => {
    setSelectedMedia(media)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Card style={{ minHeight: 380 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {mode === 'driver' && 'Your '}Recent Reviews
          <RefreshButton
            onRefresh={refetch}
            isLoading={isLoading || isRefetching}
          />
        </Typography>
        <List style={{ maxHeight: 300, overflowY: 'auto' }}>
          {data &&
            data?.map((review) => (
              <React.Fragment key={review.id}>
                <ListItem alignItems="flex-end">
                  <ListItemAvatar>
                    <Avatar variant="rounded">
                      {getInitials(review.name || '')}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Rating value={review.rating} readOnly />}
                    secondary={
                      mode === 'admin' ? (
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {review.comment}
                          </Typography>
                          <br />
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {prettyDateTime(review.date)}
                          </Typography>
                          <br />
                          Vehicle:{' '}
                          {truncate(review?.order?.vehicle_make_model, 22) ||
                            'N/A'}
                          <div>
                            Driver:{' '}
                            <b>
                              {review?.order?.driver &&
                              review?.order?.driver.length > 0
                                ? review?.order?.driver.map((driver, index) => (
                                    <React.Fragment key={index}>
                                      {driver.name || 'N/A'}
                                    </React.Fragment>
                                  ))
                                : 'N/A'}
                            </b>
                          </div>
                          <div>
                            Estimated Delivery:
                            <br />
                            {review?.order?.estimated_delivery_time !== 'N/A'
                              ? prettyDateTime(
                                  review?.order?.estimated_delivery_time
                                )
                              : 'N/A'}
                          </div>
                          <div>
                            Delivered At:
                            <br />
                            {review?.order?.actual_delivery_date_time !== 'N/A'
                              ? prettyDateTime(
                                  review?.order?.actual_delivery_date_time
                                )
                              : 'N/A'}
                          </div>
                          {
                            <Tooltip title={review?.order?.id}>
                              Order #
                              {review?.order?.id
                                ? truncate(review?.order?.id, 40)
                                : 'N/A'}
                            </Tooltip>
                          }
                        </>
                      ) : (
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {review.comment}
                          </Typography>
                          <br />
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {prettyDateTime(review.date)}
                          </Typography>
                        </>
                      )
                    }
                  />
                  {review.media && (
                    <ListItemAvatar onClick={() => handleOpen(review.media)}>
                      <Avatar
                        alt={review.media.type}
                        src={review.media.thumbnail || review.media.src}
                        variant="rounded"
                      >
                        {review.media.type === 'video' ? (
                          <VideocamIcon />
                        ) : (
                          <ImageIcon />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                  )}
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}

          {mode === 'driver' && data && data.length === 0 && (
            <Typography
              variant="caption"
              gutterBottom
              style={{ textAlign: 'center', display: 'block', marginTop: 100 }}
            >
              No reviews yet.
            </Typography>
          )}
        </List>
      </CardContent>
      <MediaModal open={open} handleClose={handleClose} media={selectedMedia} />
    </Card>
  )
}

export default RecentReviews
