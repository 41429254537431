import React, {useMemo, useState, useEffect} from 'react'
import Screen from '../../components/Screen/Screen'
import {
  Box, Tooltip,
  Grid,
} from '@mui/material'
import EntityBrowser from "../../components/EntityBrowser";
import EntityViewer from "../../components/EntityViewer/EntityViewer";
import PersonaBrowser from "../../components/PersonaBrowser/PersonaBrowser";
import ProjectList from "../../components/ProjectList/ProjectList";
import ProjectNav from "../../components/ProjectNav/ProjectNav";
import EntityGraph, {TreeView} from "../../components/EntityGraph/EntityGraph";
import AttachedEntityViewer from "../../components/AttachedEntityViewer/AttachedEntityViewer";
import { csv } from 'd3-fetch';

import {useParams} from "react-router-dom";
import {Archive, Business, Edit, Favorite, Map, Place, Public, Security} from "@mui/icons-material";
import states from "../../lib/constants/usa_states";
import ContactHeader from "../../components/ContactHeader/ContactHeader";
import {generateUUID, isUUID} from "../../lib/helpers/helpers";
import axios from "axios";
const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}

const loadData = async (type) => {
    console.log(`Loading data for ${type}`);
    switch (type) {
        default:
            try {
                const data = await csv('https://api.supportgpt.co/');
                const processedData = data.map((item, index) => ({
                    id: generateUUID() || index, // Simple indexing for ID
                    ...item
                }));
                const overrideColumns = [
                    {
                        field: 'Member Number 16 Digits',
                        headerName: 'Member Number',
                        width: 180,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'First Name',
                        headerName: 'First Name',
                        width: 120,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Last Name',
                        headerName: 'Last Name',
                        width: 120,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Individual Email',
                        headerName: 'Email',
                        width: 200,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <a href={`mailto:${params.value}`}>{params.value}</a>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Combined_Telephone',
                        headerName: 'Phone',
                        width: 150,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Standard Address',
                        headerName: 'Address',
                        width: 220,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Begin_AAA_Year',
                        headerName: 'Member Since',
                        width: 130,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Tenure',
                        headerName: 'Member Tenure',
                        width: 130,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Coverage_Level',
                        headerName: 'Coverage Level',
                        width: 150,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Date_of_Last_Purchase',
                        headerName: 'Last Purchase Date',
                        width: 170,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    }
                ];

                return {data: processedData, overrideColumns: overrideColumns};
            } catch (error) {
                console.error("Error loading CSV file:", error);
                // Fallback or error data
                return [];
            }
    }
};

const QuestionsAnswersScreen = () => {
    
    const sidebarConfig = useMemo(() => [
        {
            icon: <Public />,
            text: 'FAQs',
            slug: 'faqs',
            children: [
                {
                    icon: <Public />,
                    text: 'Public',
                    slug: 'public',
                    path: '/faqs/public',
                },
                {
                    icon: <Edit />,
                    text: 'Draft',
                    slug: 'draft',
                    path: '/faqs/draft',
                },
                {
                    icon: <Business />,
                    text: 'Internal',
                    slug: 'internal',
                    path: '/faqs/internal',
                },
                {
                    icon: <Security />,
                    text: 'Confidential',
                    slug: 'confidential',
                    path: '/faqs/confidential',
                },
                {
                    icon: <Archive />,
                    text: 'Archived',
                    slug: 'archived',
                    path: '/faqs/archived',
                },
            ],
        },
        {
            text: "Favorites", Icon: Favorite, secondaryText: "0", children: [
                { text: "No Favorites added" }
            ]
        },
    ], []);
    const { category, sub_category: pageTab } = useParams();
    let questionID = null;
    if(isUUID(category)){
        questionID = category
    }
console.log("category, personID, sub_category",category, questionID, pageTab)


    const [chosenData, setChosenData] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [overrideColumns, setOverrideColumns] = useState();
    const [organizationFaqs, setOrganizationFaqs] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    
    useEffect(() => {
        const fetchOrganizationFaqs = async () => {
            const response = await axios.get(`https://api.supportgpt.co/api/v1/organization_faqs/browse_aaa?pageSize=${pageSize}&page=${page}`);
            setOrganizationFaqs(response.data.items);
            setRowCount(response.data.total);
            setIsLoading(false)
        };
        setIsLoading(true)
        fetchOrganizationFaqs();
    }, [page, pageSize]);

    return (
    <>
      <Screen title={'Questions & Answers'} showSidebarNav={true} sidebarConfig={sidebarConfig}>
        <Box sx={{ transitionStyle, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={transitionStyle}>
                    <EntityBrowser internalUse={'questions'} demoData={organizationFaqs} overrideColumns={[
                        { field: 'id', headerName: 'ID', width: 90 },
                        { field: 'status', headerName: 'Status', width: 150 },
                        { field: 'question', headerName: 'Question', width: 150 },
                        { field: 'answer', headerName: 'Answer', width: 150 },
                        { field: 'hashtags', headerName: 'Hashtags', width: 150 },
                        { field: 'category', headerName: 'Category', width: 150 },
                        { field: 'display_order', headerName: 'Display Order', width: 150 },
                        { field: 'helpful_count', headerName: 'Helpful Votes', width: 150 },
                        { field: 'not_helpful_count', headerName: 'Not Helpful Votes', width: 150 },
                        { field: 'revision', headerName: 'Revision Count', width: 150 },
                        { field: 'parent_id', headerName: 'Parent Question', width: 150 },
                        { field: 'created_at', headerName: 'Created At', width: 150 },
                        { field: 'updated_at', headerName: 'Updated At', width: 150 },
                    ]} isLoading={isLoading}
                                   rowCount={rowCount} pageSize={pageSize} setPageSize={setPageSize} setPage={setPage}
                    />
                </Grid>
              </Grid>
        </Box>
      </Screen>
    </>
  )
}

export default QuestionsAnswersScreen
