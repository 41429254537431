import * as React from 'react';
import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

const whenItems = [
   { value: 'calabrio-call-synced', label: 'Calabrio Call Synced' },
   { value: 'salesforce-record-updated', label: 'Salesforce Record Updated' },
   { value: 'hubspot-email-synced', label: 'HubSpot Email Synced' },
   { value: 'pipedrive-deal-closed', label: 'Pipedrive Deal Closed' },
   { value: 'zoho-contact-added', label: 'Zoho Contact Added' },
   { value: 'sendboth-email-synced', label: 'SendBoth Email Synced' },
   { value: 'document-processed', label: 'Document Processed' },
   { value: 'language-translated', label: 'Language Translated' },
   { value: 'automation-task-completed', label: 'Automation Task Completed' },
   { value: 'data-validated', label: 'Data Validated' },
   { value: 'quota-refreshed', label: 'API Quota Refreshed' },
   { value: 'user-logged-in', label: 'User Logged In' },
   { value: 'email-queued', label: 'Email Queued' },
   { value: 'insight-generated', label: 'Insight Generated' },
   { value: 'vehicle-maintenance-completed', label: 'Vehicle Maintenance Completed' },
   { value: 'pet-vaccination-updated', label: 'Pet Vaccination Updated' },
   { value: 'place-visited', label: 'Place Visited' }
];


const ifItems = [
   { value: 'calabrio-sync-error', label: 'Calabrio Sync Error' },
   { value: 'salesforce-authorization-failure', label: 'Salesforce Authorization Failure' },
   { value: 'hubspot-limit-exceeded', label: 'HubSpot Rate Limit Exceeded' },
   { value: 'pipedrive-data-mismatch', label: 'Pipedrive Data Mismatch' },
   { value: 'zoho-crm-timeout', label: 'Zoho CRM Timeout' },
   { value: 'sendboth-connection-lost', label: 'SendBoth Connection Lost' },
   { value: 'document-indexing-failed', label: 'Document Indexing Failed' },
   { value: 'language-translation-error', label: 'Language Translation Error' },
   { value: 'workflow-automation-failure', label: 'Workflow Automation Failure' },
   { value: 'data-validation-error', label: 'Data Validation Error' },
   { value: 'api-quota-reached', label: 'API Quota Reached' },
   { value: 'user-activity-detected', label: 'Unusual User Activity Detected' },
   { value: 'email-sync-failed', label: 'Email Sync Failed' },
   { value: 'action-insight-generation-failed', label: 'Actionable Insight Generation Failed' }
];


const thenItems = [
   { value: 'alert-tech-support', label: 'Alert Technical Support' },
   { value: 'reauthorize-api', label: 'Reauthorize API' },
   { value: 'increase-api-limits', label: 'Increase API Limits' },
   { value: 'reindex-document', label: 'Reindex Document' },
   { value: 'resync-data', label: 'Resync Data' },
   { value: 'send-translation-error-report', label: 'Send Translation Error Report' },
   { value: 'rerun-automation-scripts', label: 'Rerun Automation Scripts' },
   { value: 'validate-data-integrity', label: 'Validate Data Integrity' },
   { value: 'reset-user-password', label: 'Reset User Password' },
   { value: 'update-crm-records', label: 'Update CRM Records' },
   { value: 'notify-account-manager', label: 'Notify Account Manager' },
   { value: 'log-error-for-analysis', label: 'Log Error for Analysis' },
   { value: 'escalate-issue-to-development', label: 'Escalate Issue to Development' },
   { value: 'retry-email-sync', label: 'Retry Email Sync' },
   { value: 'generate-new-insights', label: 'Generate New Insights' }
];


export const ActionSetup = () => {
   const [who, setWho] = React.useState('');
   const [when, setWhen] = React.useState('');
   const [ifCondition, setIfCondition] = React.useState('');
   const [thenAction, setThenAction] = React.useState('');
   const [whereAction, setWhereAction] = React.useState('');
   
   return (
      <Box sx={{ p: 4, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3 }}>
         <Typography variant="h6" gutterBottom>
            Automation Setup
         </Typography>
         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="when-label">WHO</InputLabel>
            <Select
               labelId="who-label"
               value={who}
               label="Who this happens to"
               onChange={(event) => setWho(event.target.value)}
            >
               {whenItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
               ))}
            </Select>
         </FormControl>
         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="when-label">WHEN</InputLabel>
            <Select
               labelId="when-label"
               value={when}
               label="When this happens"
               onChange={(event) => setWhen(event.target.value)}
            >
               {whenItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
               ))}
            </Select>
         </FormControl>
         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="if-label">IF</InputLabel>
            <Select
               labelId="if-label"
               value={ifCondition}
               label="If these targets match these criteria"
               onChange={(event) => setIfCondition(event.target.value)}
            >
               {ifItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
               ))}
            </Select>
         </FormControl>
         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="then-label">THEN</InputLabel>
            <Select
               labelId="then-label"
               value={thenAction}
               label="Then take these actions"
               onChange={(event) => setThenAction(event.target.value)}
            >
               {thenItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
               ))}
            </Select>
         </FormControl>
         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="then-label">WHERE</InputLabel>
            <Select
               labelId="where-label"
               value={whereAction}
               label="Where take these actions"
               onChange={(event) => setWhereAction(event.target.value)}
            >
               {thenItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
               ))}
            </Select>
         </FormControl>
         
         <Box sx={{ mb: 4 }}>
            <TextField fullWidth label="Automation Name" variant="outlined" placeholder="e.g., 'Engage recently founded companies'" sx={{ mb: 2 }} />
         </Box>
         
         <Button variant="contained">Save</Button>
      </Box>
   );
};
