import axios from 'axios'
import { API_HOST } from '../constants/constants'

export const getUploadPresign = async (
  fileExtension,
  filename,
  fileSize,
  contentType = 'photo',
  token = '',
  userID = ''
) => {
  // Validate required parameters
  if (!fileExtension || !filename || !fileSize) {
    console.error('Missing required parameters.')
    return false
  }

  // Determine the upload path based on contentType
  const uploadTypePath = contentType === 'video' ? 'video' : 'photo'

  const apiURL = `${API_HOST}/upload/${uploadTypePath}?filename=${filename}&file_extension=${fileExtension}&file_size=${fileSize}&content_type=${contentType}`

  try {
    const response = await axios.get(apiURL, {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-sb-user-id': userID,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error requesting presigned URL:', error)
    return null
  }
}
