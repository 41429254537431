import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Container, Grid, Link } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorMessage = () => {
  const navigate = useNavigate();
  return (
      <Container>
        <Grid container spacing={3} alignItems="center" justifyContent="center" style={{ padding: '40px 0' }}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h4" gutterBottom>
                <ErrorOutlineIcon /> Page not found.
              </Typography>
              <Typography variant="subtitle1">
                Please double check the link.
                <Button variant="outlined" color="primary" to="/" sx={{ml:2}} onClick={() => navigate(-1)}>
                  Back
                </Button>
              </Typography>
            </Box>
          </Grid>

        </Grid>
      </Container>
  );
};

export default ErrorMessage;
