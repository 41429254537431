import React, {useContext, useMemo} from 'react';
import {
   Avatar,
   Card,
   CardActions,
   CardContent,
   Grid,
   IconButton,
   Link,
   Typography,
   useMediaQuery,
   useTheme
} from '@mui/material'; 
import { makeStyles } from '@mui/styles';  
import {
   Favorite,
   Report,
   Share
} from '@mui/icons-material'; 
import {CLIENT_HOST} from "../../lib/constants/constants";
import {SearchContext} from "../../lib/contexts";

const useStyles = makeStyles(theme => ({
   searchResult: {
      width: '100%',
      cursor: 'pointer'
   },
   title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 12,
      color: theme.palette.primary.title
   },
   titleMobile: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 12,
      color: theme.palette.primary.title
   },
   host: {
      color: theme.palette.primary.host,
      marginBottom: 6,
   },
   desc: {
   },
   descMobile: {
      fontSize: 14,
   },
   favicon: {float:'left', width: 16, height: 16, marginRight: 4, marginTop: 4}
}));
const SearchResultItem = ({item}) => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const {
      state: { type },
   } = useContext(SearchContext);
   
   const title = useMemo(()=>type && item.page_index ? (item.name || item.headline || item.caption || item.alternativeHeadline || item.alternateName || item.title || item.page_index.title) : item.title,[type, item])

   return(
<Card className={classes.searchResult}>
   <Link href={type && item && item.page_index ? item.page_index.href : item.url} target="_blank" rel="noreferrer">
   <CardContent>
      <Grid container item justifyContent={'space-between'}>
         <Grid item xs={matchSM ? 12 : item.embedUrl ? 6 : 9}>
            <Typography variant="h5" component="h2" className={matchSM ? classes.titleMobile : classes.title}>
               {title}
            </Typography>
            <Typography className={classes.host} color="textSecondary">
               <Avatar variant={'square'} className={classes.favicon} alt={title} src={type && item && item.page_index ? item.page_index.favicon : item.favicon} />&nbsp;{item.author ? item.author + ' | ' : '' + (type && item && item.page_index ? item.page_index.host : item.host)}
            </Typography>
            <Typography className={matchSM ? classes.descMobile : classes.desc}>
               {type && item && item.page_index ? item.page_index.description : item.description}
            </Typography>
         </Grid>
         {!matchSM && <Grid item xs={item.embedUrl ? 5 : 3}>
            {item.embedUrl ? <>
               <iframe width="300" height="169" src={item.embedUrl}
                       title="Video Player" frameBorder="0"
                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                       allowFullScreen></iframe>
            </> : <>
            {(item.best_image || (item && item.page_index && item.page_index.best_image)) &&
            <Avatar variant={'square'} style={{margin: 'auto', height: 100, width: 100}} alt={title}
                    src={type && item && item.page_index ? item.page_index.best_image : item.best_image}/>}
            </>}
         </Grid>}
      </Grid>
   </CardContent>
   </Link>

   <CardActions disableSpacing style={{display:'none'}}>
      <Link href={CLIENT_HOST+'/save?item='+item.uuid} target="_blank" rel="noreferrer">
         <IconButton aria-label="Remember Result">
            <Favorite />
         </IconButton>
      </Link>
      <Link href={CLIENT_HOST+'/share?item='+item.uuid} target="_blank" rel="noreferrer">
         <IconButton aria-label="Share Result">
            <Share />
         </IconButton>
      </Link>
      <Link href={CLIENT_HOST+'/report?item='+item.uuid} target="_blank" rel="noreferrer">
         <IconButton aria-label="Share Result">
            <Report />
         </IconButton>
      </Link>
   </CardActions>
</Card>
)
};

export default SearchResultItem;
