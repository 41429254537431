import axios from 'axios'
import { API_HOST } from '../constants/constants'
export const updateOrganizationFaq = async (token, userID, faqID, options) => {
  const apiURL = API_HOST + '/organization_faqs/' + faqID

  try {
    const { data } = await axios.patch(
      apiURL,
      {
        organization_faq: options,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          'x-gg-user-id': userID,
        },
      }
    )
    return data
  } catch (error) {
    console.error(error)
  }
}
