// CurrentTransports.js
import React, { useState } from 'react'
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Chip,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import StarIcon from '@mui/icons-material/Star'
import { blue, green, orange, red } from '@mui/material/colors'
import RefreshButton from '../RefreshButton/RefreshButton'
import useOrders from '../../lib/hooks/useOrders'
import {
  prettyDateTime,
  toCapitalizeFromUnderScore,
  truncate,
} from '../../lib/helpers/helpers'
import { DeleteForever, Edit, MoreVert } from '@mui/icons-material'
import { deleteOrder } from '../../lib/api/deleteOrder'
import { LoadingButton } from '@mui/lab'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog'
import { ModifyBidDialog } from '../ModifyBidDialog/ModifyBidDialog'

// Example Drivers Data
const driversData = [
  {
    id: 1234,
    name: 'John Doe',
    carrier: 'Doe Logistics',
    rating: 4.5,
    reviews: 120,
    onTheRoad: true,
    orderAttached: 'Order #1234',
  },
  {
    id: 2345,
    name: 'Jane Smith',
    carrier: 'Smith Transport',
    rating: 4.8,
    reviews: 95,
    onTheRoad: true,
    orderAttached: 'Order #1235',
  },
]

// Function to get the icon color based on status
const getIconColor = (status) => {
  switch (status) {
    case 'In Transit':
      return green[500]
    case 'Delayed':
      return orange[500]
    case 'Delivered':
      return blue[700]
    case 'Cancelled':
      return red[500]
    default:
      return green[500]
  }
}

const CurrentTransports = () => {
  const { data, isLoading, isRefetching, refetch } = useOrders()
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false)
  const [isOpenModifyBid, setIsOpenModifyBid] = useState(false)

  const handleConfirmDelete = () => {
    setIsOpenConfirmDelete(true)
  }

  const handleClose = () => {
    setIsOpenConfirmDelete(false)
    setIsOpenModifyBid(false)
  }

  const handleCancel = () => {
    handleClose()
  }
  const handleMenuOpen = (event, id) => {
    setMenuAnchorEl(event.currentTarget)
    setSelectedItemId(id)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
    setSelectedItemId(null)
  }

  const handleModifyBid = () => {
    handleMenuClose()
    setIsOpenModifyBid(true)
  }

  const confirmCancelTransport = async () => {
    handleClose()
    console.log('Cancelling Transport for:', selectedItemId)
    setIsDeleting(true)
    await deleteOrder(selectedItemId).then((r) => {
      setIsDeleting(false)
    })
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Current Transports
          <RefreshButton
            onRefresh={refetch}
            isLoading={isLoading || isRefetching}
          />
        </Typography>
        <List style={{ maxHeight: 495, overflowY: 'auto' }} dense={true}>
          {data?.length === 0 ? (
            <ListItem>
              <ListItemText primary="No Shipments Yet" />
            </ListItem>
          ) : (
            data?.map((item, index) =>
              item?.type === 'shipment' ? (
                <ListItem key={`shipment-${item?.id}`}>
                  <ListItemIcon>
                    <LocalShippingIcon
                      sx={{
                        color: getIconColor(
                          toCapitalizeFromUnderScore(item?.status || '')
                        ),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title={item?.vehicle_make_model}>
                        {toCapitalizeFromUnderScore(item?.status) || 'N/A'} -{' '}
                        {truncate(item?.vehicle_make_model, 22) || 'N/A'}
                      </Tooltip>
                    }
                    secondary={
                      <>
                        <div>
                          Driver:{' '}
                          <b>
                            {item?.driver && item.driver.length > 0
                              ? item.driver.map((driver, index) => (
                                  <React.Fragment key={index}>
                                    {driver.name || 'N/A'}
                                    <br />
                                  </React.Fragment>
                                ))
                              : 'N/A'}
                          </b>
                        </div>
                        {item?.status === 'delivered' ? (
                          <div>
                            Delivered At:
                            <br />
                            {item.actual_delivery_date_time
                              ? prettyDateTime(item?.actual_delivery_date_time)
                              : 'N/A'}
                          </div>
                        ) : (
                          <div>
                            Estimated Delivery:
                            <br />
                            {item.estimated_delivery_time !== ''
                              ? prettyDateTime(item?.estimated_delivery_time)
                              : 'N/A'}
                          </div>
                        )}
                        {
                          <Tooltip title={item.id}>
                            Order #{item?.id ? truncate(item.id, 15) : 'N/A'}
                          </Tooltip>
                        }
                      </>
                    }
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      title={'Manage'}
                      onClick={(event) => handleMenuOpen(event, item.id)}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl) && selectedItemId === item.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={handleModifyBid}
                        disabled={isDeleting || item?.status !== 'pending'}
                      >
                        <ListItem fullWidth>
                          <ListItemIcon>
                            <Edit />
                          </ListItemIcon>
                          <ListItemText primary={'Modify Bid'} />
                        </ListItem>
                      </MenuItem>
                      <MenuItem
                        onClick={handleConfirmDelete}
                        disabled={isDeleting || item?.status !== 'pending'}
                      >
                        <ListItem fullWidth>
                          <ListItemIcon>
                            <DeleteForever />
                          </ListItemIcon>
                          <ListItemText primary={'Cancel Transport'} />
                        </ListItem>
                      </MenuItem>
                    </Menu>
                  </ListItemSecondaryAction>
                </ListItem>
              ) : (
                <ListItem
                  key={`driver-${item.driver?.id}`}
                  alignItems="flex-start"
                >
                  <ListItemAvatar>&nbsp;</ListItemAvatar>
                  <ListItemText
                    primary={<>{item.driver?.name || 'N/A'}</>}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {item.driver?.carrier || 'N/A'}
                        </Typography>
                        <br />
                        <Chip
                          icon={<StarIcon />}
                          label={`${item.driver?.average_rating} (${item.driver?.total_reviews} reviews)`}
                          size="small"
                          variant="outlined"
                        />
                      </>
                    }
                  />
                </ListItem>
              )
            )
          )}
        </List>

        <ModifyBidDialog
          isOpen={isOpenModifyBid}
          onClose={handleClose}
          onCancel={handleCancel}
        />
        <ConfirmDialog
          isOpen={isOpenConfirmDelete}
          onClose={handleClose}
          title="Confirm Cancellation"
          description="Are you sure you want to cancel this shipment?"
          onConfirm={confirmCancelTransport}
          onCancel={handleCancel}
        />
      </CardContent>
    </Card>
  )
}

export default CurrentTransports
