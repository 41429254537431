import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const postMailboxMessageSeen = async options => {
  const apiURL = API_HOST + '/mailbox/seen';
  const { token, userID } = useCurrentUser();

  try {
    const { data } = await axios.post(
      apiURL,
      {
        seen: {
          ...options,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          'x-sb-user-id': userID,
        },
      },
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};
