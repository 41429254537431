import { useQuery } from 'react-query'
import { getReviews } from '../api'
import useCurrentUser from './useCurrentUser'

const useReviews = () => {
  const { token, userID } = useCurrentUser()
  const result = useQuery('reviews_', () => getReviews(token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  })
  return result
}

export default useReviews
