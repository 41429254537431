import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Divider, Typography, Paper, Grid } from '@mui/material';
import {
   MusicNote, Tv, Movie, SportsSoccer, Announcement,
   ShoppingBag, Build, Favorite, Explore, Restaurant,
   LocalCafe, FitnessCenter, LocalHospital, Book, VideogameAsset,
   Style, Flight, Brush, Kitchen, DevicesOther,
   School, AttachMoney, EnergySavingsLeaf, Public, Pets, SelfImprovement,
   BusinessCenter, Event, DirectionsCar, FavoriteBorder
} from '@mui/icons-material';

// Mapping categories to icons for dynamic icon rendering
const categoryIcons = {
   Music: <MusicNote />,
   Television: <Tv />,
   Movies: <Movie />,
   Sports: <SportsSoccer />,
   News: <Announcement />,
   Products: <ShoppingBag />,
   Services: <Build />,
   Values: <Favorite />,
   Hobbies: <Explore />,
   Tools: <Build />,
   Places: <Flight />,
   Food: <Restaurant />,
   Beverages: <LocalCafe />,
   Health: <LocalHospital />,
   Wellness: <SelfImprovement />,
   Fitness: <FitnessCenter />,
   Literature: <Book />,
   Gaming: <VideogameAsset />,
   Fashion: <Style />,
   Art: <Brush />,
   Cooking: <Kitchen />,
   Technology: <DevicesOther />,
   Education: <School />,
   Finance: <AttachMoney />,
   Environmental: <EnergySavingsLeaf />,
   SocialMedia: <Public />,
   Pets: <Pets />,
   Spirituality: <FavoriteBorder />,
   Professional: <BusinessCenter />,
   Cultural: <Event />,
   Automobiles: <DirectionsCar />,
   Philanthropy: <Favorite />
};

const PreferencesTemplate = ({ preferences }) => {
   return (
      <Paper elevation={3} sx={{ m: 2, p: 2 }}>
         <Typography variant="h5" gutterBottom>
            Interests & Preferences
         </Typography>
         <Grid container spacing={2} sx={{mt:1, maxHeight: 800, overflowY: 'auto' }}>
            {preferences.map((category, index) => (
               <Grid item xs={12} sm={6} md={4} key={index}>
                  <Typography variant="subtitle1" gutterBottom>
                     {category.name}
                  </Typography>
                  <List dense>
                     {category.items.map((item, idx) => (
                        <ListItem key={idx}>
                           <ListItemIcon>
                              {categoryIcons[category.name] || <Favorite />}
                           </ListItemIcon>
                           <ListItemText primary={item} />
                        </ListItem>
                     ))}
                  </List>
                  {index < preferences.length - 1 && <Divider />}
               </Grid>
            ))}
         </Grid>
      </Paper>
   );
};

export default PreferencesTemplate;
