import useSession from "./useSession";
import {useEffect, useState} from "react";

const usePermission = (permission) => {
  const {data} = useSession()
   const [hasPermission, setHasPermission] = useState(false)
   useEffect(()=> {
      if (data !== undefined && permission && data.user && data.user.permissions && data.user.permissions.length){
         setHasPermission(data.user.permissions.filter(perm=>perm.type === permission)[0] !==undefined && data.user.permissions.filter(perm=>perm.type === permission)[0].value)
      }
   },[data, permission])
  
  return permission ? hasPermission : true
}
export default usePermission;
