import Screen from '../../components/Screen/Screen'
import React, { useCallback, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
} from '@mui/material'
import SearchFormBox from '../../components/SearchFormBox'
const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}

const SearchScreen = () => {

  return (
    <>
      <Screen title={'Search'}>
        <Box sx={{ p: 2 }}>
          <Card sx={transitionStyle}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={transitionStyle}>
                  <SearchFormBox/>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Screen>
    </>
  )
}

export default SearchScreen
