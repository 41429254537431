import React, { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js';
import { darken, lighten } from "@mui/material/styles";

export const AudioVisualizer = ({ audio, audioUrl, progress }) => {
   const waveformRef = useRef(null);
   const wavesurferRef = useRef(null);
   const loadIdRef = useRef(0); // To track load attempts
   
   useEffect(() => {
      if (!audioUrl || !waveformRef.current) {
         console.log('No audio URL or waveform container reference');
         return;
      }
      
      const currentLoadId = ++loadIdRef.current;
      const ws = WaveSurfer.create({
         container: waveformRef.current,
         waveColor: lighten('#092A6B', 0.5),
         progressColor: darken('#092A6B', 0.3),
         plugins: [
            Hover.create({
               lineColor: lighten('#092A6B', 0.6),
               lineWidth: 2,
               labelBackground: '#555',
               labelColor: '#fff',
               labelSize: '11px',
            }),
         ],
      });
      
      wavesurferRef.current = ws;
      
      const loadAudio = async () => {
         try {
            console.log('Attempting to load audio:', currentLoadId);
            await ws.load(audioUrl);
            if (currentLoadId === loadIdRef.current) {
               console.log('Audio loaded successfully:', currentLoadId);
            } else {
               console.log('Load aborted due to new load request:', currentLoadId);
            }
         } catch (error) {
            console.error('Failed to load audio:', error);
         }
      };
      
      loadAudio();
      
      ws.on('interaction', () => {
         console.log('Interaction with waveform');
         ws.play();
      });
      
      return () => {
         console.log('Destroying WaveSurfer instance');
         ws.destroy();
      };
   }, [audioUrl]);
   
   useEffect(() => {
      if (!audioUrl || !wavesurferRef.current || typeof progress !== 'number') {
         console.log('Missing requirements for updating progress');
         return;
      }
      
      const ws = wavesurferRef.current;
      const updateProgress = () => {
         const duration = ws.getDuration();
         const newPosition = progress * duration / 100;
         ws.seekTo(newPosition);
      };
      
      ws.on('ready', updateProgress);
      
      return () => {
         ws.un('ready', updateProgress);
      };
   }, [audioUrl, progress]);
   
   return <div id="waveform" ref={waveformRef} />;
};
