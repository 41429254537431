import { useQuery } from 'react-query'
import useCurrentUser from './useCurrentUser'
import { getMedia } from '../api'

const useMedia = (mediaID = '') => {
  const { token, userID } = useCurrentUser()
  const result = useQuery(
    'media_' + mediaID,
    () => getMedia(mediaID, token, userID),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000, ///50000,
    }
  )
  return result
}

export default useMedia
