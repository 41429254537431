import Screen from '../../components/Screen/Screen'
import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import MetricCard from "../../components/MetricCard/MetricCard";
import { SparkLineChart, LineChart } from '@mui/x-charts';

const ReportDashboardScreen = () => {
  return (
    <>
      <Screen title={'Reporting'}>
         <Box sx={{  m: 4 }}>
         
         <Typography variant="h6" gutterBottom>Reporting Dashboard</Typography>
          <Grid container item xs={12} spacing={3} alignItems="flex-start" style={{ margin: 'auto' }}>
              <Grid container item xs={12} spacing={2} justifyContent="space-between">
                  <MetricCard title={"Unique Visitors"} slug={'unique_visitors'}/>
                  <MetricCard title={"Highest Concurrent"} slug={'highest_concurrent_users'}/>
                  <MetricCard title={"Total Requests"} slug={'total_requests'}/>
                  <MetricCard title={"Total Entities"} slug={'num_of_entity'}/>
                  <MetricCard title={"Percent Cached"} slug={'percent_cached'}/>
                  <MetricCard title={"Percent Vectorized"} slug={'percent_vectorized'}/>
                  <MetricCard title={"New Leads"} slug={'new_leads'}/>
                  <MetricCard title={"Agent Interactions"} slug={'agent_interactions'}/>
                  <MetricCard title={"AI Usage"} slug={'ai_usage'}/>
                  <MetricCard title={"Compute Usage"} slug={'ai_usage'}/>
                  <MetricCard title={"SMS Usage"} slug={'sms_usage'}/>
                  <MetricCard title={"Email Usage"} slug={'email_usage'}/>
                  <MetricCard title={"Storage Usage"} slug={'storage_usage'}/>
                  <MetricCard title={"Bandwidth Usage"} slug={'bandwidth_usage'}/>
                  <MetricCard title={"Total Data Served"} slug={'total_data_served'}/>
                  <MetricCard title={"Data Cached"} slug={'data_cached'}/>
                  </Grid>
              <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
          </Grid>
             <Grid container xs={12} spacing={1} alignItems="flex-start" style={{ margin: 'auto' }}>
             
             <Grid item xs={4}>
                <Typography variant="subtitle1" gutterBottom>Load AVG Load</Typography>
                <LineChart
                   xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                   series={[{ data: [2, 5.5, 2, 8.5, 1.5, 5] }]}
                   width={500}
                   height={300}
                />
             </Grid>
             <Grid item xs={4}>
                <Typography variant="subtitle1" gutterBottom>CPU Usage</Typography>
                <LineChart
                   xAxis={[{ data: [1, 3, 5, 7, 9, 11] }]}
                   series={[{ data: [3, 6, 2.5, 7, 4, 6] }]}
                   width={500}
                   height={300}
                />
             </Grid>
             <Grid item xs={4}>
                <Typography variant="subtitle1" gutterBottom>Memory Usage</Typography>
                <LineChart
                   xAxis={[{ data: [1, 2, 3, 4, 5, 6] }]}
                   series={[{ data: [1, 3, 2, 4, 5, 3.5] }]}
                   width={500}
                   height={300}
                />
             </Grid>
             </Grid>

         </Box>
      </Screen>
    </>
  )
}
export default ReportDashboardScreen
