import React, {useMemo} from 'react'
import Screen from '../../components/Screen/Screen'
import {
    Box, Tooltip,
} from '@mui/material'
import { DataArray, Favorite, Folder, Sync, Person} from "@mui/icons-material";
import {prettyDate, prettyDateTime, toCapitalizeFromUnderScore, truncate} from "../../lib/helpers/helpers";
import menuItems from "../../components/MainMenu/menuItems";

const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const OrganizationScreen = () => {
    const sidebarConfig = useMemo(()=> menuItems.find(item => item.slug === "organization")?.children,[menuItems])
  return (
    <>
      <Screen title={'Organization'} showSidebarNav={true} sidebarConfig={sidebarConfig}>
        <Box sx={{ transitionStyle, p: 2 }}>
        </Box>
      </Screen>
    </>
  )
}

export default OrganizationScreen
