// RouteInfo.js
import React, {useMemo, useState} from 'react';
import {
    Tooltip,
    Typography,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    List,
    ListItemIcon,
    IconButton,
    Button,
    Grid, Chip
} from '@mui/material';
import {CalendarToday, DirectionsBoat, DriveEta, Info, Layers, LocalShipping, Place, AssignmentInd, ContentCopy, Numbers} from "@mui/icons-material";

import RefreshButton from "../RefreshButton/RefreshButton";
import {prettyDateTime, toCapitalizeFromUnderScore, handleCopyPaste} from "../../lib/helpers/helpers";


const EntityCard = ({  data = {}, isLoading, isRefetching, refetch, icon, showTitle = true, showPreview = true, showActions = true}) => {
    const kbItem = useMemo(() => data || {}, [data])
    const getDetailsForType = () => {}
    const getSubDetailsForType = () => {}
    const pickupAddress = useMemo(() => {
        return kbItem.locations?.find(location => location.type === 'pickup')?.address || kbItem?.pickup?.location?.address;
    }, [kbItem.locations]);

    const dropoffAddress = useMemo(() => {
        return kbItem.locations?.find(location => location.type === 'dropoff')?.address || kbItem?.dropoff?.location?.address;
    }, [kbItem.locations]);

    const getDetailsForVehicles = (kbItem) => {
        switch (kbItem?.quote_type || kbItem?.type) {
            case 'shipment':
            case 'auto':
                // First, check if kbItem.vehicle_make_model is available
                return (
                    <List dense style={{ maxHeight: '200px', overflow: 'auto' }}>
                        {kbItem.vehicles && kbItem.vehicles.map((vehicle, index) => (
                            <div key={index}>
                                <span>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</span>
                                <IconButton size="small" onClick={() => handleCopyPaste(`${vehicle.year} ${vehicle.make} ${vehicle.model}`)}>
                                    <ContentCopy fontSize="small" />
                                </IconButton><br/>

                                <span>{vehicle.vin}</span>
                                <IconButton size="small" onClick={() => handleCopyPaste(vehicle.vin)}>
                                    <ContentCopy fontSize="small" />
                                </IconButton><br/>
                            </div>
                        ))}
                    </List>
                )
                // Then check for detailed vehicle information
             /*   return kbItem?.details?.vehicle?.make && kbItem?.details?.vehicle?.model && kbItem?.details?.vehicle?.year
                    ? `${kbItem.details.vehicle.make} ${kbItem.details.vehicle.model} (${kbItem.details.vehicle.year})`
                    : "Auto";*/
            case 'material':
                return `${kbItem?.details?.materialType}, Total Weight: ${kbItem?.details?.totalWeight} lbs`;
            case 'marine':
                return `Vessel Type: ${kbItem?.details?.vesselType}, Name: ${kbItem?.details?.vesselName}`;
            default:
                return 'N/A';
        }
    };

    const statusColorMapping = {
        disabled: 'default', // Gray
        enabled: 'success', // Green
        failed: 'error' // Red
    };

    const getStatusColor = () => {
        return statusColorMapping[kbItem.status] || 'default';
    }
    return (
        <Card style={{marginTop: 30}}>
            <CardContent>
                {showTitle && <Typography variant="h6" gutterBottom>
                    {refetch && <RefreshButton onRefresh={refetch} isLoading={isLoading || isRefetching}/>}

                    {icon ? icon : <Chip label={toCapitalizeFromUnderScore("disabled" || kbItem.status)}
                                         color={getStatusColor(kbItem.status || "disabled")} size={'small'}/>}
                        <Button
                            style={{marginLeft:20}}
                            size={'small'}
                            variant="outlined"
                        >Configure</Button>
{/*
                    <Chip label={`# Order ID ${kbItem?.id}`} color={getStatusColor()} size={'small'}/>
*/}



                </Typography>}
                <Grid container item xs={12} >
                    <Grid container spacing={2}>

                        {(data.text || data.name || data.primary) && <ListItem key={data}>
                            {/*<ListItemIcon>
                                {data.icon}
                            </ListItemIcon>*/}
                            <ListItemText primary={toCapitalizeFromUnderScore(data.text || data.name || data.primary, true)} secondary={data.description || data.secondary} />
                        </ListItem>}

                        {data?.details?.reduce((acc, entity, index, array) => {
                            // Create a ListItem for the current entity
                            const listItem = (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        {entity.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={entity.primary} secondary={entity.secondary} />
                                </ListItem>
                            );

                            // Add this ListItem to a temporary collection
                            acc.temp.push(listItem);

                            // Every two items or on the last item, wrap in a Grid item
                            if (acc.temp.length === 2 || index === array.length - 1) {
                                const gridItem = (
                                    <Grid item xs={4} key={`grid-${index}`}>
                                        <List>{acc.temp}</List>
                                    </Grid>
                                );
                                // Push the grid item to the main accumulator
                                acc.gridItems.push(gridItem);
                                // Clear the temporary collection for the next group of ListItems
                                acc.temp = [];
                            }

                            return acc;
                        }, { temp: [], gridItems: [] }).gridItems}
                    </Grid>

                    {kbItem.scraped_at && <Typography variant={'caption'}>
                        Ingested: {prettyDateTime(kbItem.scraped_at)} - Created: {prettyDateTime(kbItem.created_at)} -
                        Updated: {prettyDateTime(kbItem.updated_at)}
                    </Typography>}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default EntityCard;
