import { createContext } from 'react'
import useOrganization from '../hooks/useOrganization'

export const OrganizationContext = createContext({})
export const OrganizationContextProvider = ({ children, props }) => {
  const urlSplit = window.location.host.split('.')
  const organizationObject =
    urlSplit.length > 1 ? useOrganization(undefined, urlSplit[0]) : {}
  return (
    <OrganizationContext.Provider
      value={{
        actions: {},
        state: {
          ...organizationObject,
          userRole:
            organizationObject &&
            organizationObject.current_user &&
            organizationObject.current_user.user_role
              ? organizationObject.current_user.user_role
              : '',
        },
      }}
      {...props}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
