import React from 'react';
import {
    Box, Grid, Card, CardContent, Typography, Avatar, List, ListItem, ListItemIcon, ListItemText, Divider, Stack
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {Business, Email, Insights, Phone} from '@mui/icons-material';
import ChatIcon from '@mui/icons-material/Chat';
import PlaceIcon from '@mui/icons-material/Place';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PetsIcon from '@mui/icons-material/Pets';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import CommunicationPreferences from "../../CommunicationPreferences/CommunicationPreferences";
import {prettyPhone} from "../../../lib/helpers/helpers";

const PersonTemplate = ({ data, summaryMode = false }) => {
    return (
        <Box>
            <CardContent>
                <Grid container spacing={summaryMode ? 1 : 2}>
                    <Grid item xs={6}>
                        <ListItem sx={{ p: 0 }}>
                            <ListItemIcon>
                                <Avatar sx={{ bgcolor: 'primary.main', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50, height: 50, mr:1.5 }}>JD</Avatar>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="h5">{data.name ? data.name : 'Unknown'}</Typography>} secondary={<Typography variant="body2" color="text.secondary">
                                <Business fontSize={'10px'} style={{position:'relative', top:1}}/>&nbsp;{data.company ? data.company : 'Unknown'}</Typography>}/>
                        </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="column" alignItems="end" spacing={1}>
                            <Typography color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Phone fontSize={'10px'} style={{position:'relative', top:1, marginRight: 6}} />
                                {data.phone ? prettyPhone(data.phone) : 'Unknown'}
                            </Typography>
                            <Typography color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Email fontSize={'10px'} style={{position:'relative', top:1, marginRight: 6}} />
                                {data.email ? data.email : 'Unknown'}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider sx={{ my: summaryMode ? 1 : 2 }} />
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <Insights />
                        </ListItemIcon>
                        <ListItemText primary="Recent Insights" secondary="2 Insights gained." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Phone />
                        </ListItemIcon>
                        <ListItemText primary="Recent Calls" secondary="Last call: 2 days ago" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ChatIcon />
                        </ListItemIcon>
                        <ListItemText primary="Conversations" secondary="145 chat sessions, 18 sms, 3 emails, 2 phone calls" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PlaceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Places" secondary="5 visited places" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DirectionsCarIcon />
                        </ListItemIcon>
                        <ListItemText primary="Vehicles" secondary="2 registered vehicles" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PetsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pets" secondary="1 pet: Charlie the dog" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FamilyRestroomIcon />
                        </ListItemIcon>
                        <ListItemText primary="Family" secondary="Spouse: Jane Doe, Children: 2" />
                    </ListItem>
                </List>
            </CardContent>
            {!summaryMode && <CommunicationPreferences/>}
        </Box>
    );
};

export default PersonTemplate;
