// EntityViewer.js
import React from 'react';
import { Box, Typography, Card, CardContent, Grid, Avatar } from '@mui/material';
import {Phone} from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import ChatIcon from '@mui/icons-material/Chat';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessIcon from '@mui/icons-material/Business';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import EventIcon from '@mui/icons-material/Event';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PublicIcon from '@mui/icons-material/Public';
import PersonTemplate from './templates/PersonTemplate';
import {TranscriptionViewer} from "../TranscriptionViewer/TranscriptionViewer";
import EntityTags from "../EntityTags/EntityTags";

const entityTemplates = {
    Person: PersonTemplate,
    PhoneCall: (data) => (
        <Box>
            <Avatar>
                <Phone />
            </Avatar>
            <TranscriptionViewer data={data}/>
        </Box>
    ),
    Places: (data) => (
        <Box>
            <Avatar>
                <PlaceIcon />
            </Avatar>
            <Typography>{data.location}</Typography>
        </Box>
    ),
    Conversations: (data) => (
        <Box>
            <Avatar>
                <ChatIcon />
            </Avatar>
            <Typography>{data.summary}</Typography>
        </Box>
    ),
    QuestionsAndAnswers: (data) => (
        <Box>
            <Avatar>
                <QuestionAnswerIcon />
            </Avatar>
            <Typography>Q: {data.question}</Typography>
            <Typography>A: {data.answer}</Typography>
        </Box>
    ),
    Documents: (data) => (
        <Box>
            <Avatar>
                <PictureAsPdfIcon />
            </Avatar>
            <Typography>{data.title}</Typography>
        </Box>
    ),
    Vehicles: (data) => (
        <Box>
            <Avatar>
                <LocalShippingIcon />
            </Avatar>
            <Typography>{data.model}</Typography>
        </Box>
    ),
    Organizations: (data) => (
        <Box>
            <Avatar>
                <BusinessIcon />
            </Avatar>
            <Typography>{data.name}</Typography>
        </Box>
    ),
    Media: (data) => (
        <Box>
            <Avatar>
                <PhotoLibraryIcon />
            </Avatar>
            <Typography>{data.title}</Typography>
        </Box>
    ),
    Events: (data) => (
        <Box>
            <Avatar>
                <EventIcon />
            </Avatar>
            <Typography>{data.name}</Typography>
        </Box>
    ),
    Products: (data) => (
        <Box>
            <Avatar>
                <ShoppingBasketIcon />
            </Avatar>
            <Typography>{data.name}</Typography>
        </Box>
    ),
    Websites: (data) => (
        <Box>
            <Avatar>
                <PublicIcon />
            </Avatar>
            <Typography>{data.url}</Typography>
        </Box>
    )
};
 const EntityViewer = ({ entityType, data }) => {
     console.log("entityType", entityType)
    const EntityTemplate = entityTemplates[entityType];
    return (
        <Card sx={{ maxWidth: 600, m: 2 }}>
            <CardContent>
                {EntityTemplate ? <>
                    <EntityTemplate data={data} />
                    <EntityTags entityType={entityType} testData={['tag1', 'tag2']} />
                </> : <Typography>No template available for this entity type.</Typography>}
            </CardContent>
        </Card>
    );
};

export default EntityViewer
