import React from 'react'
import Screen from '../../components/Screen/Screen'
import {
    Box, Tooltip,
} from '@mui/material'
import EntityBrowser from "../../components/EntityBrowser";
import {Cloud, DataArray, Favorite, Folder, Sync, Storage,PictureAsPdf} from "@mui/icons-material";
import {prettyDate, prettyDateTime, toCapitalizeFromUnderScore, truncate} from "../../lib/helpers/helpers";

const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const DataSourceScreen = () => {
    const sidebarConfig = [
        {
            text: "Sources", Icon: Folder, secondaryText: "8", children: [
                { text: "Fuzeqna.com", tooltip: "https://www.fuzeqna.com/aaahoosier/ext/kbsearch.aspx", Icon: Sync, secondaryText: "22" },
                { text: "Hooser.aaa.com", tooltip: "https://www.hoosier.aaa.com/sitemap.xml", Icon: Sync, secondaryText: "0" },
                { text: "Calabrio", Icon: Sync, secondaryText: "0" },
                { text: "Current Member List", Icon: PictureAsPdf, secondaryText: "0" },
                { text: "Former Member List", Icon: PictureAsPdf, secondaryText: "0" },
                { text: "Prospective Member List", Icon: PictureAsPdf, secondaryText: "0" },
                { text: "S3 Bucket: Hoosier AAA", Icon: Cloud, secondaryText: "0" },
                { text: "Database: Hoosier AAA", Icon: Storage, secondaryText: "0" },
            ]
        },
        {
            text: "Favorites", Icon: Favorite, secondaryText: "0", children: [
                { text: "No Favorites added" }
            ]
        },,
        {
            text: "Exports", Icon: DataArray, children: [
                { text: "Schedule CSV Export" },
                { text: "Schedule JSON Export" },
                { text: "Schedule Cloud Export" },
            ]
        },
    ]

    const overrideColumns = [
        {
            field: 'entity_type',
            headerName: 'Entity Type',
            width: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'source',
            headerName: 'Source',
            width: 180,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <a href={params.value} target="_blank" rel="noopener noreferrer">{truncate(params.value, 50)}</a>
                </Tooltip>
            )
        },
        {
            field: 'content',
            headerName: 'Content',
            width: 300,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{truncate(params.value, 100)}</span>
                </Tooltip>
            )
        },
        {
            field: 'sentiment',
            headerName: 'Sentiment',
            width: 120,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <strong style={{
                        color: params.value === 'negative' ? 'red' : params.value === 'neutral' ? 'gray' : 'green'
                    }}>
                        {toCapitalizeFromUnderScore(params.value)}
                    </strong>
                </Tooltip>
            )
        },
        {
            field: 'keywords',
            headerName: 'Keywords',
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.value.join(', ')}>
                    <span>{truncate(params.value.join(', '), 100)}</span>
                </Tooltip>
            )
        },
        {
            field: 'interaction_count',
            headerName: 'Interactions',
            width: 130,
            renderCell: (params) => (
                <Tooltip title={`Total interactions: ${params.value}`}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'related_entities',
            headerName: 'Related Entities',
            width: 220,
            renderCell: (params) => (
                <Tooltip title={params.value.join(', ')}>
                    <span>{truncate(params.value.join(', '), 100)}</span>
                </Tooltip>
            )
        },
        {
            field: 'confidentiality',
            headerName: 'Confidentiality',
            width: 140,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value ? 'Confidential' : 'Public'}</span>
                </Tooltip>
            )
        },
        {
            field: 'confidence_score',
            headerName: 'Confidence Score',
            width: 150,
            renderCell: (params) => (
                <Tooltip title={`${params.value.toFixed(2)}%`}>
                    <span>{`${params.value.toFixed(2)}%`}</span>
                </Tooltip>
            )
        },
        {
            field: 'nsfw',
            headerName: 'NSFW',
            width: 100,
            renderCell: (params) => (
                <Tooltip title={params.value ? 'Not Safe for Work' : 'Safe'}>
                <span style={{ color: params.value ? 'red' : 'green' }}>
                    {params.value ? 'Yes' : 'No'}
                </span>
                </Tooltip>
            )
        },
        {
            field: 'scraped_at',
            headerName: 'Scraped At',
            width: 150,
            renderCell: (params) => {
                const content = params.value;
                return (
                    <Tooltip title={prettyDateTime(content)} placement="top">
                        <span>{prettyDate(content)}</span>
                    </Tooltip>
                );
            }
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 100,
            renderCell: (params) => {
                const content = params.value;
                return (
                    <Tooltip title={prettyDateTime(content)} placement="top">
                        <span>{prettyDate(content)}</span>
                    </Tooltip>
                );
            }
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 110,
            renderCell: (params) => {
                const content = params.value;
                return (
                    <Tooltip title={prettyDateTime(content)} placement="top">
                        <span>{prettyDate(content)}</span>
                    </Tooltip>
                );
            }
        }
    ];
  return (
    <>
      <Screen title={'Data Sources'} showSidebarNav={true} sidebarConfig={sidebarConfig}>
        <Box sx={{ transitionStyle, p: 2 }}>
                  <EntityBrowser overrideColumns={overrideColumns} demoData={[]}/>
        </Box>
      </Screen>
    </>
  )
}

export default DataSourceScreen
