const personaBuckets = {
    "personaBuckets": [
    {"id": 1, "name": "Sustainable Shoppers", "characteristics": {"interests": ["recycling", "organic products", "sustainability"], "communicationPreferences": ["email newsletters", "eco-friendly apps"], "ageRange": "20-40", "purchasePatterns": ["subscription to eco-boxes"], "incomeLevel": "Moderate to high"}},
    {"id": 2, "name": "Tech Trendsetters", "characteristics": {"interests": ["new gadgets", "tech news", "innovations"], "communicationPreferences": ["social media"], "ageRange": "20-35", "purchasePatterns": ["early adopters of technology"], "incomeLevel": "High"}},
    {"id": 3, "name": "Frequent Travelers", "characteristics": {"interests": ["travel", "adventures", "cultural experiences"], "communicationPreferences": ["mobile notifications"], "ageRange": "30-60", "purchasePatterns": ["regular travels"], "incomeLevel": "High"}},
    {"id": 4, "name": "Fitness Enthusiasts", "characteristics": {"interests": ["gym", "outdoor activities", "health supplements"], "communicationPreferences": ["app notifications"], "ageRange": "18-55", "purchasePatterns": ["monthly gym memberships"], "incomeLevel": "Moderate"}},
    {"id": 5, "name": "Luxury Consumers", "characteristics": {"interests": ["luxury goods", "exclusive offers", "premium brands"], "communicationPreferences": ["personalized emails"], "ageRange": "35-65", "purchasePatterns": ["high-ticket items"], "incomeLevel": "High"}},
    {"id": 6, "name": "Eco-Friendly Shoppers", "characteristics": {"interests": ["sustainability", "recycling", "eco-friendly products"], "communicationPreferences": ["blogs"], "ageRange": "20-50", "purchasePatterns": ["frequent purchases of green products"], "incomeLevel": "Moderate to high"}},
    {"id": 7, "name": "DIY Crafters", "characteristics": {"interests": ["crafting", "DIY projects", "handmade goods"], "communicationPreferences": ["Pinterest"], "ageRange": "25-65", "purchasePatterns": ["buys craft supplies regularly"], "incomeLevel": "Low to moderate"}},
    {"id": 8, "name": "Urban Commuters", "characteristics": {"interests": ["public transportation", "urban living", "minimalism"], "communicationPreferences": ["mobile apps", "email alerts"], "ageRange": "18-40", "purchasePatterns": ["regular purchases of transit passes"], "incomeLevel": "Moderate"}},
    {"id": 9, "name": "Young Professionals", "characteristics": {"interests": ["career development", "networking events", "startups"], "communicationPreferences": ["LinkedIn", "email newsletters"], "ageRange": "23-35", "purchasePatterns": ["invests in professional attire and gadgets"], "incomeLevel": "Moderate to high"}},
    {"id": 10, "name": "Pet Owners", "characteristics": {"interests": ["pets", "animal welfare", "outdoor activities with pets"], "communicationPreferences": ["pet blogs", "social media groups"], "ageRange": "18-60", "purchasePatterns": ["regular purchases of pet food and accessories"], "incomeLevel": "Moderate"}},
    {"id": 11, "name": "Gardeners", "characteristics": {"interests": ["gardening", "sustainable living", "homegrown vegetables"], "communicationPreferences": ["gardening forums", "magazines"], "ageRange": "30-70", "purchasePatterns": ["purchases gardening tools and supplies"], "incomeLevel": "Low to moderate"}},
    {"id": 12, "name": "Art Collectors", "characteristics": {"interests": ["fine art", "auctions", "gallery openings"], "communicationPreferences": ["exclusive invites", "email updates"], "ageRange": "40-70", "purchasePatterns": ["invests in art pieces"], "incomeLevel": "High"}},
    {"id": 13, "name": "Fashionistas", "characteristics": {"interests": ["fashion", "trends", "designer wear"], "communicationPreferences": ["fashion blogs", "social media"], "ageRange": "18-40", "purchasePatterns": ["shops for new fashion trends"], "incomeLevel": "Moderate to high"}},
    {"id": 14, "name": "Outdoor Adventurers", "characteristics": {"interests": ["hiking", "camping", "rock climbing"], "communicationPreferences": ["outdoor apps", "newsletters"], "ageRange": "25-45", "purchasePatterns": ["buys outdoor gear and equipment"], "incomeLevel": "Moderate"}},
    {"id": 15, "name": "Gourmet Food Lovers", "characteristics": {"interests": ["fine dining", "gourmet recipes", "wine tasting"], "communicationPreferences": ["culinary magazines", "food festivals"], "ageRange": "30-60", "purchasePatterns": ["visits gourmet restaurants"], "incomeLevel": "High"}},
    {"id": 16, "name": "Home Chefs", "characteristics": {"interests": ["cooking", "baking", "recipe development"], "communicationPreferences": ["cooking channels", "online workshops"], "ageRange": "25-55", "purchasePatterns": ["buys kitchen gadgets and premium ingredients"], "incomeLevel": "Moderate"}},
    {"id": 17, "name": "Sci-Fi Fans", "characteristics": {"interests": ["science fiction", "cosplay", "sci-fi conventions"], "communicationPreferences": ["sci-fi forums", "fan clubs"], "ageRange": "18-50", "purchasePatterns": ["purchases sci-fi merchandise and books"], "incomeLevel": "Low to moderate"}},
    {"id": 18, "name": "Music Enthusiasts", "characteristics": {"interests": ["concerts", "vinyl collecting", "music streaming"], "communicationPreferences": ["music apps", "email newsletters"], "ageRange": "16-50", "purchasePatterns": ["buys concert tickets and music subscriptions"], "incomeLevel": "Moderate"}},
    {"id": 19, "name": "Automobile Enthusiasts", "characteristics": {"interests": ["car shows", "auto racing", "vintage cars"], "communicationPreferences": ["auto magazines", "car club meetups"], "ageRange": "20-60", "purchasePatterns": ["invests in car parts and collectibles"], "incomeLevel": "High"}},
    {"id": 20, "name": "Vintage Collectors", "characteristics": {"interests": ["vintage clothing", "antiques", "retro music"], "communicationPreferences": ["vintage shop newsletters", "auctions"], "ageRange": "30-70", "purchasePatterns": ["collects and trades vintage items"], "incomeLevel": "Moderate"}},
    {
        "id": 21,
        "name": "Gaming Enthusiasts",
        "characteristics": {
            "interests": ["online gaming", "esports", "game development"],
            "communicationPreferences": ["gaming forums", "online communities"],
            "ageRange": "16-35",
            "purchasePatterns": ["buys latest gaming consoles and accessories"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 22,
        "name": "Parenting Advisors",
        "characteristics": {
            "interests": ["parenting tips", "child education", "family activities"],
            "communicationPreferences": ["parenting blogs", "email newsletters"],
            "ageRange": "30-45",
            "purchasePatterns": ["buys educational toys and books"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 23,
        "name": "Green Thumbs",
        "characteristics": {
            "interests": ["gardening", "landscape design", "sustainable living"],
            "communicationPreferences": ["gardening apps", "workshops"],
            "ageRange": "25-65",
            "purchasePatterns": ["purchases gardening tools and organic seeds"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 24,
        "name": "Romance Readers",
        "characteristics": {
            "interests": ["romance novels", "book clubs", "literary festivals"],
            "communicationPreferences": ["bookstore newsletters", "author webinars"],
            "ageRange": "18-60",
            "purchasePatterns": ["buys books and attends book signings"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 25,
        "name": "Adventure Sports Fans",
        "characteristics": {
            "interests": ["extreme sports", "outdoor adventures", "adrenaline activities"],
            "communicationPreferences": ["sports magazines", "event newsletters"],
            "ageRange": "18-40",
            "purchasePatterns": ["purchases equipment for extreme sports"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 26,
        "name": "Culinary Explorers",
        "characteristics": {
            "interests": ["exotic cuisines", "cooking classes", "food festivals"],
            "communicationPreferences": ["culinary blogs", "tasting events"],
            "ageRange": "25-55",
            "purchasePatterns": ["attends cooking classes and buys gourmet ingredients"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 27,
        "name": "History Buffs",
        "characteristics": {
            "interests": ["historical books", "museum visits", "archaeology"],
            "communicationPreferences": ["history podcasts", "museum memberships"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys historical memorabilia and books"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 28,
        "name": "Art and Design Professionals",
        "characteristics": {
            "interests": ["modern art", "graphic design", "workshops"],
            "communicationPreferences": ["art exhibits", "professional seminars"],
            "ageRange": "20-50",
            "purchasePatterns": ["invests in art supplies and software"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 29,
        "name": "Social Media Influencers",
        "characteristics": {
            "interests": ["brand collaborations", "content creation", "social media trends"],
            "communicationPreferences": ["social media platforms", "brand emails"],
            "ageRange": "16-35",
            "purchasePatterns": ["engages in promotional campaigns"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 30,
        "name": "Business Executives",
        "characteristics": {
            "interests": ["leadership", "corporate strategy", "networking"],
            "communicationPreferences": ["LinkedIn", "executive workshops"],
            "ageRange": "35-65",
            "purchasePatterns": ["participates in high-end networking events"],
            "incomeLevel": "High"
        }
    },
{
        "id": 31,
        "name": "Classical Music Aficionados",
        "characteristics": {
            "interests": ["classical concerts", "opera", "symphonies"],
            "communicationPreferences": ["email newsletters", "venue subscriptions"],
            "ageRange": "40-80",
            "purchasePatterns": ["season tickets to performances"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 32,
        "name": "Eco-Tourists",
        "characteristics": {
            "interests": ["sustainable travel", "ecology tours", "wildlife conservation"],
            "communicationPreferences": ["specialized travel blogs", "conservation podcasts"],
            "ageRange": "25-50",
            "purchasePatterns": ["books eco-friendly trips and lodgings"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 33,
        "name": "Political Activists",
        "characteristics": {
            "interests": ["political campaigns", "civil rights", "community organizing"],
            "communicationPreferences": ["political forums", "activism newsletters"],
            "ageRange": "18-70",
            "purchasePatterns": ["donates to causes and attends rallies"],
            "incomeLevel": "Varies"
        }
    },
    {
        "id": 34,
        "name": "Anime and Manga Fans",
        "characteristics": {
            "interests": ["anime conventions", "manga collections", "cosplay"],
            "communicationPreferences": ["anime streaming platforms", "fan club emails"],
            "ageRange": "12-35",
            "purchasePatterns": ["buys merchandise and collector's items"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 35,
        "name": "Real Estate Investors",
        "characteristics": {
            "interests": ["property markets", "real estate seminars", "investment strategies"],
            "communicationPreferences": ["real estate blogs", "investment newsletters"],
            "ageRange": "30-65",
            "purchasePatterns": ["invests in property and real estate trusts"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 36,
        "name": "Coffee Connoisseurs",
        "characteristics": {
            "interests": ["artisan coffee", "brewing techniques", "coffee tastings"],
            "communicationPreferences": ["specialty coffee shop newsletters", "brewing workshops"],
            "ageRange": "18-60",
            "purchasePatterns": ["purchases specialty beans and brewing equipment"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 37,
        "name": "Thrill Seekers",
        "characteristics": {
            "interests": ["skydiving", "bungee jumping", "adventure sports"],
            "communicationPreferences": ["adventure sports forums", "extreme sports channels"],
            "ageRange": "18-40",
            "purchasePatterns": ["engages in high-adrenaline activities and equipment"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 38,
        "name": "Literary Enthusiasts",
        "characteristics": {
            "interests": ["literature", "writing workshops", "book fairs"],
            "communicationPreferences": ["literary blogs", "book club discussions"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys first editions and attends author signings"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 39,
        "name": "Indie Music Fans",
        "characteristics": {
            "interests": ["indie concerts", "vinyl collections", "music festivals"],
            "communicationPreferences": ["music streaming services", "concert newsletters"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys concert tickets and limited edition records"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 40,
        "name": "Cycling Enthusiasts",
        "characteristics": {
            "interests": ["road biking", "mountain biking", "cycling tours"],
            "communicationPreferences": ["cycling blogs", "gear shop newsletters"],
            "ageRange": "18-50",
            "purchasePatterns": ["invests in high-quality bicycles and accessories"],
            "incomeLevel": "Moderate to high"
        }
    },
{
        "id": 41,
        "name": "Digital Nomads",
        "characteristics": {
            "interests": ["remote work", "travel blogging", "coworking spaces"],
            "communicationPreferences": ["digital nomad forums", "online communities"],
            "ageRange": "22-40",
            "purchasePatterns": ["buys travel gear and tech gadgets"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 42,
        "name": "Startup Founders",
        "characteristics": {
            "interests": ["venture capital", "startup accelerators", "networking events"],
            "communicationPreferences": ["LinkedIn", "startup podcasts"],
            "ageRange": "25-45",
            "purchasePatterns": ["invests in business tools and services"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 43,
        "name": "Craft Beer Aficionados",
        "characteristics": {
            "interests": ["craft beer tasting", "brewery tours", "home brewing"],
            "communicationPreferences": ["brewery newsletters", "craft beer apps"],
            "ageRange": "21-50",
            "purchasePatterns": ["buys craft beer and brewing supplies"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 44,
        "name": "Artisan Chefs",
        "characteristics": {
            "interests": ["gourmet cooking", "culinary arts", "chef masterclasses"],
            "communicationPreferences": ["culinary blogs", "cooking channels"],
            "ageRange": "30-60",
            "purchasePatterns": ["purchases high-end kitchen equipment"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 45,
        "name": "Urban Gardeners",
        "characteristics": {
            "interests": ["urban farming", "sustainable gardening", "community gardens"],
            "communicationPreferences": ["gardening workshops", "online forums"],
            "ageRange": "18-65",
            "purchasePatterns": ["buys gardening supplies and plants"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 46,
        "name": "Home Improvement DIYers",
        "characteristics": {
            "interests": ["home renovation", "DIY projects", "interior decorating"],
            "communicationPreferences": ["DIY blogs", "home improvement shows"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys tools and home improvement materials"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 47,
        "name": "Soccer Moms",
        "characteristics": {
            "interests": ["children's soccer", "community events", "family activities"],
            "communicationPreferences": ["school newsletters", "community Facebook groups"],
            "ageRange": "30-50",
            "purchasePatterns": ["buys sports gear and family vehicles"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 48,
        "name": "Senior Tech Learners",
        "characteristics": {
            "interests": ["technology for seniors", "digital literacy", "online safety"],
            "communicationPreferences": ["senior tech courses", "email newsletters"],
            "ageRange": "65+",
            "purchasePatterns": ["enrolls in technology classes"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 49,
        "name": "Jewelry Collectors",
        "characteristics": {
            "interests": ["fine jewelry", "antique pieces", "jewelry making"],
            "communicationPreferences": ["jewelry auction sites", "maker workshops"],
            "ageRange": "35-70",
            "purchasePatterns": ["invests in high-value jewelry"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 50,
        "name": "Mystery and Thriller Enthusiasts",
        "characteristics": {
            "interests": ["mystery novels", "thriller movies", "escape rooms"],
            "communicationPreferences": ["book clubs", "cinema newsletters"],
            "ageRange": "18-65",
            "purchasePatterns": ["buys books and movie tickets"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 51,
        "name": "Minimalists",
        "characteristics": {
            "interests": ["minimalist living", "decluttering", "sustainable fashion"],
            "communicationPreferences": ["minimalism blogs", "lifestyle podcasts"],
            "ageRange": "20-40",
            "purchasePatterns": ["invests in quality over quantity"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 52,
        "name": "Wildlife Photographers",
        "characteristics": {
            "interests": ["wildlife photography", "nature conservation", "photo exhibitions"],
            "communicationPreferences": ["photography workshops", "nature conservancy newsletters"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys professional cameras and lenses"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 53,
        "name": "Quilters",
        "characteristics": {
            "interests": ["quilting", "fabric stores", "craft fairs"],
            "communicationPreferences": ["quilting circles", "craft store newsletters"],
            "ageRange": "40-80",
            "purchasePatterns": ["buys fabrics and quilting supplies"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 54,
        "name": "Horror Film Buffs",
        "characteristics": {
            "interests": ["horror films", "film festivals", "horror conventions"],
            "communicationPreferences": ["film review sites", "convention announcements"],
            "ageRange": "18-50",
            "purchasePatterns": ["attends movie screenings and buys memorabilia"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 55,
        "name": "Local Food Advocates",
        "characteristics": {
            "interests": ["farmers' markets", "organic food", "local cuisine"],
            "communicationPreferences": ["local food blogs", "community newsletters"],
            "ageRange": "25-65",
            "purchasePatterns": ["shops at local markets and participates in food co-ops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 56,
        "name": "Urban Artists",
        "characteristics": {
            "interests": ["street art", "urban murals", "art installations"],
            "communicationPreferences": ["art community forums", "gallery newsletters"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys spray paints and art supplies"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 57,
        "name": "Antique Lovers",
        "characteristics": {
            "interests": ["antique collecting", "estate sales", "restoration"],
            "communicationPreferences": ["antique shop emails", "auction house catalogs"],
            "ageRange": "30-70",
            "purchasePatterns": ["purchases rare antiques and vintage items"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 58,
        "name": "Board Game Aficionados",
        "characteristics": {
            "interests": ["tabletop games", "game nights", "board game conventions"],
            "communicationPreferences": ["gaming blogs", "local game shop events"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys board games and accessories"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 59,
        "name": "Self-Improvement Seekers",
        "characteristics": {
            "interests": ["personal development", "self-help books", "motivational seminars"],
            "communicationPreferences": ["self-help podcasts", "motivational speaker channels"],
            "ageRange": "25-60",
            "purchasePatterns": ["enrolls in online courses and workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 60,
        "name": "Pilates Enthusiasts",
        "characteristics": {
            "interests": ["Pilates classes", "fitness retreats", "health and wellness"],
            "communicationPreferences": ["fitness studio newsletters", "wellness apps"],
            "ageRange": "20-50",
            "purchasePatterns": ["attends Pilates sessions and buys fitness apparel"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 61,
        "name": "Sommeliers and Wine Lovers",
        "characteristics": {
            "interests": ["wine tasting", "vineyard tours", "wine collecting"],
            "communicationPreferences": ["wine club emails", "vineyard event notifications"],
            "ageRange": "30-65",
            "purchasePatterns": ["buys premium wines and attends exclusive tastings"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 62,
        "name": "Spiritual Seekers",
        "characteristics": {
            "interests": ["meditation retreats", "yoga classes", "spiritual literature"],
            "communicationPreferences": ["spiritual blogs", "retreat center newsletters"],
            "ageRange": "25-65",
            "purchasePatterns": ["attends workshops and purchases related books and items"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 63,
        "name": "Local Historians",
        "characteristics": {
            "interests": ["local history", "museum volunteering", "historical reenactments"],
            "communicationPreferences": ["historical society newsletters", "local museum emails"],
            "ageRange": "35-75",
            "purchasePatterns": ["donates to historical societies and buys historical books"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 64,
        "name": "Documentary Devotees",
        "characteristics": {
            "interests": ["documentary films", "film festivals", "educational series"],
            "communicationPreferences": ["documentary streaming service notifications", "film festival updates"],
            "ageRange": "20-60",
            "purchasePatterns": ["subscribes to documentary channels and attends screenings"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 65,
        "name": "Aspiring Chefs",
        "characteristics": {
            "interests": ["culinary arts", "cooking competitions", "recipe development"],
            "communicationPreferences": ["cooking show subscriptions", "culinary school newsletters"],
            "ageRange": "18-50",
            "purchasePatterns": ["enrolls in culinary courses and buys professional cookware"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 66,
        "name": "Veteran Support Advocates",
        "characteristics": {
            "interests": ["veteran affairs", "support groups", "community service"],
            "communicationPreferences": ["veteran association emails", "community service bulletins"],
            "ageRange": "30-70",
            "purchasePatterns": ["donates to veteran causes and participates in community events"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 67,
        "name": "Sustainable Builders",
        "characteristics": {
            "interests": ["green building", "sustainable materials", "eco-friendly architecture"],
            "communicationPreferences": ["sustainability webinars", "green building magazines"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in eco-friendly building projects and materials"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 68,
        "name": "Retro Culture Enthusiasts",
        "characteristics": {
            "interests": ["vintage fashion", "retro music", "classic cars"],
            "communicationPreferences": ["retro culture blogs", "vintage fair announcements"],
            "ageRange": "20-60",
            "purchasePatterns": ["collects retro items and attends themed events"],
            "incomeLevel": "Low to high"
        }
    },
    {
        "id": 69,
        "name": "Competitive Runners",
        "characteristics": {
            "interests": ["marathons", "triathlons", "running gear"],
            "communicationPreferences": ["running club emails", "race registration platforms"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys running apparel and participates in races"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 70,
        "name": "DIY Home Decorators",
        "characteristics": {
            "interests": ["home decor DIY", "interior design projects", "upcycling"],
            "communicationPreferences": ["DIY home decor channels", "Pinterest boards"],
            "ageRange": "25-50",
            "purchasePatterns": ["purchases home improvement supplies and decor items"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 71,
        "name": "Philanthropists",
        "characteristics": {
            "interests": ["charity events", "fundraising", "non-profit support"],
            "communicationPreferences": ["charity newsletters", "non-profit webinars"],
            "ageRange": "35-75",
            "purchasePatterns": ["donates regularly to charities and attends gala events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 72,
        "name": "Nature Explorers",
        "characteristics": {
            "interests": ["hiking", "wildlife photography", "camping"],
            "communicationPreferences": ["outdoor adventure blogs", "nature conservancy newsletters"],
            "ageRange": "18-55",
            "purchasePatterns": ["buys camping and hiking gear"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 73,
        "name": "Indie Film Lovers",
        "characteristics": {
            "interests": ["independent cinema", "film director Q&As", "film crowdfunding"],
            "communicationPreferences": ["indie film festival emails", "cinema club newsletters"],
            "ageRange": "20-50",
            "purchasePatterns": ["supports indie film projects and attends screenings"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 74,
        "name": "Healthcare Professionals",
        "characteristics": {
            "interests": ["medical advancements", "healthcare seminars", "professional networking"],
            "communicationPreferences": ["medical journals", "healthcare industry conferences"],
            "ageRange": "25-65",
            "purchasePatterns": ["attends conferences and subscribes to professional journals"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 75,
        "name": "Creative Writers",
        "characteristics": {
            "interests": ["writing workshops", "poetry readings", "literary magazines"],
            "communicationPreferences": ["writer's workshops", "literary festival updates"],
            "ageRange": "18-65",
            "purchasePatterns": ["enrolls in writing courses and buys literature"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 76,
        "name": "Urban Sketchers",
        "characteristics": {
            "interests": ["cityscapes drawing", "sketching workshops", "art exhibitions"],
            "communicationPreferences": ["art community newsletters", "local workshop updates"],
            "ageRange": "18-60",
            "purchasePatterns": ["buys sketchbooks and drawing materials"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 77,
        "name": "Backyard Gardeners",
        "characteristics": {
            "interests": ["home gardening", "permaculture", "organic vegetables"],
            "communicationPreferences": ["gardening blogs", "local nursery newsletters"],
            "ageRange": "25-70",
            "purchasePatterns": ["purchases seeds, plants, and gardening tools"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 78,
        "name": "Aquarium Enthusiasts",
        "characteristics": {
            "interests": ["freshwater fish", "aquascaping", "reef tanks"],
            "communicationPreferences": ["aquarium forums", "pet store promotions"],
            "ageRange": "18-65",
            "purchasePatterns": ["buys aquarium supplies and decorative items"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 79,
        "name": "Bird Watchers",
        "characteristics": {
            "interests": ["ornithology", "birdwatching tours", "conservation efforts"],
            "communicationPreferences": ["birding clubs", "conservation newsletters"],
            "ageRange": "30-80",
            "purchasePatterns": ["buys binoculars, guidebooks, and bird feeders"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 80,
        "name": "Urban Beekeepers",
        "characteristics": {
            "interests": ["beekeeping", "honey production", "sustainable practices"],
            "communicationPreferences": ["beekeeping associations", "sustainability blogs"],
            "ageRange": "25-60",
            "purchasePatterns": ["invests in beekeeping equipment and hives"],
            "incomeLevel": "Low to high"
        }
    },
    {
        "id": 81,
        "name": "Rare Book Collectors",
        "characteristics": {
            "interests": ["antiquarian books", "first editions", "book auctions"],
            "communicationPreferences": ["rare book club emails", "auction house catalogs"],
            "ageRange": "35-75",
            "purchasePatterns": ["acquires rare books from auctions and dealers"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 82,
        "name": "Tea Connoisseurs",
        "characteristics": {
            "interests": ["tea tasting", "ceremonial teas", "global tea cultures"],
            "communicationPreferences": ["tea shop updates", "tea festival invitations"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys specialty teas and tea brewing equipment"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 83,
        "name": "Amateur Astronomers",
        "characteristics": {
            "interests": ["stargazing", "telescope technology", "astronomy clubs"],
            "communicationPreferences": ["astronomy forums", "observatory newsletters"],
            "ageRange": "18-65",
            "purchasePatterns": ["purchases telescopes and attends star-watching events"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 84,
        "name": "Volunteer Workers",
        "characteristics": {
            "interests": ["community service", "charitable work", "non-profit organizations"],
            "communicationPreferences": ["non-profit newsletters", "community center bulletins"],
            "ageRange": "18-70",
            "purchasePatterns": ["donates time and resources to community projects"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 85,
        "name": "Modern Dancers",
        "characteristics": {
            "interests": ["dance performances", "choreography", "dance workshops"],
            "communicationPreferences": ["dance academy emails", "performance announcements"],
            "ageRange": "18-50",
            "purchasePatterns": ["enrolls in dance classes and buys dancewear"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 86,
        "name": "Cybersecurity Experts",
        "characteristics": {
            "interests": ["data security", "hacking", "cybersecurity conferences"],
            "communicationPreferences": ["cybersecurity blogs", "industry conference updates"],
            "ageRange": "25-60",
            "purchasePatterns": ["subscribes to security software and attends conferences"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 87,
        "name": "Public Speakers",
        "characteristics": {
            "interests": ["public speaking", "motivational talks", "communication skills"],
            "communicationPreferences": ["speaker bureaus", "workshop invitations"],
            "ageRange": "30-65",
            "purchasePatterns": ["books speaking engagements and buys presentation materials"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 88,
        "name": "Sci-Fi Model Builders",
        "characteristics": {
            "interests": ["model kits", "sci-fi conventions", "collectible figures"],
            "communicationPreferences": ["modeling forums", "convention updates"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys models and attends themed conventions"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 89,
        "name": "Puppetry Artists",
        "characteristics": {
            "interests": ["puppet shows", "puppet making", "theatrical performances"],
            "communicationPreferences": ["arts theater newsletters", "craft workshop updates"],
            "ageRange": "20-60",
            "purchasePatterns": ["invests in puppetry materials and attends shows"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 90,
        "name": "Stand-Up Comedians",
        "characteristics": {
            "interests": ["comedy clubs", "improv shows", "comedy writing"],
            "communicationPreferences": ["comedy network updates", "showcase announcements"],
            "ageRange": "21-50",
            "purchasePatterns": ["performs at clubs and subscribes to comedy streaming services"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 91,
        "name": "Mixology Enthusiasts",
        "characteristics": {
            "interests": ["craft cocktails", "bartending", "mixology courses"],
            "communicationPreferences": ["mixology blogs", "bar supply stores newsletters"],
            "ageRange": "21-50",
            "purchasePatterns": ["attends mixology workshops and buys bartending equipment"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 92,
        "name": "Genealogists",
        "characteristics": {
            "interests": ["family history", "ancestry research", "genealogy conferences"],
            "communicationPreferences": ["genealogy websites updates", "family history workshop invites"],
            "ageRange": "30-75",
            "purchasePatterns": ["subscribes to genealogy databases and attends conferences"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 93,
        "name": "Hot Air Balloon Enthusiasts",
        "characteristics": {
            "interests": ["hot air ballooning", "aerial tours", "balloon festivals"],
            "communicationPreferences": ["ballooning club newsletters", "festival announcements"],
            "ageRange": "30-65",
            "purchasePatterns": ["participates in balloon rides and attends related events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 94,
        "name": "Lapidary Hobbyists",
        "characteristics": {
            "interests": ["gem cutting", "jewelry making", "mineral collecting"],
            "communicationPreferences": ["lapidary clubs", "gem and mineral show updates"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys gemstones and lapidary equipment"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 95,
        "name": "Kite Flyers",
        "characteristics": {
            "interests": ["kite making", "kite flying competitions", "wind festivals"],
            "communicationPreferences": ["kite club emails", "outdoor festival newsletters"],
            "ageRange": "10-60",
            "purchasePatterns": ["buys kites and related gear"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 96,
        "name": "Escape Room Enthusiasts",
        "characteristics": {
            "interests": ["puzzle solving", "team-based challenges", "escape room games"],
            "communicationPreferences": ["escape room blogs", "activity group updates"],
            "ageRange": "18-50",
            "purchasePatterns": ["visits escape rooms and participates in local events"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 97,
        "name": "Art Therapy Participants",
        "characteristics": {
            "interests": ["therapeutic arts", "creative workshops", "mental wellness"],
            "communicationPreferences": ["health and wellness centers", "therapy session alerts"],
            "ageRange": "18-70",
            "purchasePatterns": ["enrolls in art therapy sessions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 98,
        "name": "Archaeology Buffs",
        "characteristics": {
            "interests": ["historical digs", "archaeology tours", "museum exhibits"],
            "communicationPreferences": ["archaeological society newsletters", "educational tour offers"],
            "ageRange": "20-70",
            "purchasePatterns": ["attends digs and subscribes to archaeology magazines"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 99,
        "name": "Fantasy Sports Managers",
        "characteristics": {
            "interests": ["fantasy football", "fantasy baseball", "league competitions"],
            "communicationPreferences": ["sports forums", "fantasy sports app notifications"],
            "ageRange": "18-50",
            "purchasePatterns": ["participates in fantasy leagues and buys related software"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 100,
        "name": "Sneaker Collectors",
        "characteristics": {
            "interests": ["limited edition sneakers", "sneaker conventions", "shoe customization"],
            "communicationPreferences": ["sneaker blogs", "convention updates"],
            "ageRange": "16-40",
            "purchasePatterns": ["buys rare sneakers and attends sneaker drops"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 101,
        "name": "Virtual Reality Gamers",
        "characteristics": {
            "interests": ["VR gaming", "augmented reality", "tech gadgets"],
            "communicationPreferences": ["tech forums", "VR newsletters"],
            "ageRange": "10-35",
            "purchasePatterns": ["buys latest VR headsets and games"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 102,
        "name": "Elder Tech Adopters",
        "characteristics": {
            "interests": ["technology for seniors", "assistive devices", "digital literacy"],
            "communicationPreferences": ["senior tech workshops", "email updates"],
            "ageRange": "65+",
            "purchasePatterns": ["invests in technology designed for elderly users"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 103,
        "name": "Sustainable Fashion Advocates",
        "characteristics": {
            "interests": ["ethical clothing", "sustainable fashion", "vintage apparel"],
            "communicationPreferences": ["sustainable fashion blogs", "eco-friendly shop updates"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys from ethical brands and second-hand stores"],
            "incomeLevel": "Low to high"
        }
    },
    {
        "id": 104,
        "name": "Multilingual Learners",
        "characteristics": {
            "interests": ["language learning", "cultural exchange", "travel"],
            "communicationPreferences": ["language apps", "cultural newsletters"],
            "ageRange": "10-70",
            "purchasePatterns": ["subscribes to language learning platforms and books"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 105,
        "name": "Urban Homesteaders",
        "characteristics": {
            "interests": ["urban farming", "DIY food preservation", "self-sufficiency"],
            "communicationPreferences": ["urban homesteading blogs", "workshop invitations"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys gardening supplies and canning equipment"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 106,
        "name": "Autonomous Vehicle Enthusiasts",
        "characteristics": {
            "interests": ["self-driving cars", "automotive technology", "future of transport"],
            "communicationPreferences": ["auto tech newsletters", "technology expos"],
            "ageRange": "20-60",
            "purchasePatterns": ["follows and invests in autonomous vehicle technology"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 107,
        "name": "Global Nomads",
        "characteristics": {
            "interests": ["world travel", "cultural immersion", "backpacking"],
            "communicationPreferences": ["travel blogs", "global event newsletters"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys travel gear and experiences"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 108,
        "name": "Hobbyist Drone Pilots",
        "characteristics": {
            "interests": ["drone flying", "aerial photography", "drone races"],
            "communicationPreferences": ["drone enthusiast forums", "aerial photography workshops"],
            "ageRange": "15-60",
            "purchasePatterns": ["invests in drones and related accessories"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 109,
        "name": "Tiny House Builders",
        "characteristics": {
            "interests": ["tiny living", "minimalist lifestyle", "sustainable homes"],
            "communicationPreferences": ["tiny house blogs", "sustainable living workshops"],
            "ageRange": "20-50",
            "purchasePatterns": ["buys tiny house plans and building materials"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 110,
        "name": "Conscious Parents",
        "characteristics": {
            "interests": ["mindful parenting", "educational toys", "child development"],
            "communicationPreferences": ["parenting blogs", "educational seminars"],
            "ageRange": "25-45",
            "purchasePatterns": ["invests in quality children’s education and toys"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 111,
        "name": "Upcycling Enthusiasts",
        "characteristics": {
            "interests": ["recycling", "DIY projects", "sustainable crafts"],
            "communicationPreferences": ["DIY forums", "craft store updates"],
            "ageRange": "18-65",
            "purchasePatterns": ["buys upcycling materials and tools"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 112,
        "name": "Smart Home Integrators",
        "characteristics": {
            "interests": ["home automation", "IoT devices", "energy efficiency"],
            "communicationPreferences": ["technology blogs", "smart home expos"],
            "ageRange": "25-60",
            "purchasePatterns": ["installs smart home systems and devices"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 113,
        "name": "Recreational Fishers",
        "characteristics": {
            "interests": ["fishing", "boating", "outdoor conservation"],
            "communicationPreferences": ["fishing magazines", "conservation newsletters"],
            "ageRange": "20-70",
            "purchasePatterns": ["buys fishing gear and boat equipment"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 114,
        "name": "Independent Film Producers",
        "characteristics": {
            "interests": ["film production", "film festivals", "crowdfunding"],
            "communicationPreferences": ["film industry newsletters", "production workshops"],
            "ageRange": "20-50",
            "purchasePatterns": ["invests in film projects and attends industry events"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 115,
        "name": "Herbal Medicine Advocates",
        "characteristics": {
            "interests": ["natural remedies", "herbal courses", "wellness workshops"],
            "communicationPreferences": ["health blogs", "herbalist newsletters"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys herbal products and attends related courses"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 116,
        "name": "Podcast Producers",
        "characteristics": {
            "interests": ["podcasting", "audio editing", "content creation"],
            "communicationPreferences": ["podcast hosting platforms", "audio technology webinars"],
            "ageRange": "18-55",
            "purchasePatterns": ["buys podcasting microphones and subscriptions to hosting services"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 117,
        "name": "Space Exploration Supporters",
        "characteristics": {
            "interests": ["space missions", "astronomy", "planetary science"],
            "communicationPreferences": ["space agency newsletters", "astronomy club meetings"],
            "ageRange": "18-65",
            "purchasePatterns": ["donates to space exploration causes and buys telescopes"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 118,
        "name": "Comic Book Collectors",
        "characteristics": {
            "interests": ["comic books", "graphic novels", "superhero movies"],
            "communicationPreferences": ["comic shop emails", "comic con announcements"],
            "ageRange": "12-50",
            "purchasePatterns": ["collects rare comics and attends conventions"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 119,
        "name": "High-Intensity Trainers",
        "characteristics": {
            "interests": ["HIIT workouts", "fitness challenges", "personal training"],
            "communicationPreferences": ["fitness app notifications", "gym newsletters"],
            "ageRange": "18-45",
            "purchasePatterns": ["enrolls in boot camps and buys high-performance gear"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 120,
        "name": "Alternative Medicine Practitioners",
        "characteristics": {
            "interests": ["acupuncture", "holistic health", "naturopathy"],
            "communicationPreferences": ["wellness center emails", "holistic health expos"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys alternative medicine supplies and attends seminars"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 121,
        "name": "Organic Chefs",
        "characteristics": {
            "interests": ["organic cooking", "farm-to-table dining", "culinary arts"],
            "communicationPreferences": ["organic food markets", "culinary workshops"],
            "ageRange": "25-55",
            "purchasePatterns": ["shops at organic markets and participates in cooking classes"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 122,
        "name": "Youth Sports Coaches",
        "characteristics": {
            "interests": ["coaching", "youth athletics", "sports development"],
            "communicationPreferences": ["sports coaching clinics", "team management software updates"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys coaching gear and subscribes to training resources"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 123,
        "name": "Sustainable Living Advocates",
        "characteristics": {
            "interests": ["sustainability", "eco-friendly products", "green living"],
            "communicationPreferences": ["sustainability forums", "eco-lifestyle blogs"],
            "ageRange": "18-65",
            "purchasePatterns": ["invests in sustainable products and green technology"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 125,
        "name": "Disaster Preparedness Advocates",
        "characteristics": {
            "interests": ["emergency preparedness", "survival training", "first aid"],
            "communicationPreferences": ["preparedness workshops", "survival gear store emails"],
            "ageRange": "25-65",
            "purchasePatterns": ["stocks up on emergency supplies and attends survival courses"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 126,
        "name": "Urban Planners",
        "characteristics": {
            "interests": ["city development", "public transport systems", "urban sustainability"],
            "communicationPreferences": ["urban planning webinars", "city council updates"],
            "ageRange": "25-65",
            "purchasePatterns": ["subscribes to planning journals and participates in community planning"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 127,
        "name": "Classic Car Restorers",
        "characteristics": {
            "interests": ["car restoration", "vintage vehicles", "automotive history"],
            "communicationPreferences": ["car restoration channels", "vintage auto shows"],
            "ageRange": "30-70",
            "purchasePatterns": ["restores classic cars and attends auto shows"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 128,
        "name": "Green Energy Supporters",
        "characteristics": {
            "interests": ["renewable energy", "solar power", "sustainable technologies"],
            "communicationPreferences": ["green tech expos", "sustainability podcasts"],
            "ageRange": "18-65",
            "purchasePatterns": ["invests in solar panels and energy-efficient appliances"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 129,
        "name": "Experimental Musicians",
        "characteristics": {
            "interests": ["experimental music", "sound art", "music technology"],
            "communicationPreferences": ["music tech workshops", "electronic music festivals"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys synthesizers and electronic instruments"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 130,
        "name": "Heritage Preservationists",
        "characteristics": {
            "interests": ["historical preservation", "cultural heritage", "architectural conservation"],
            "communicationPreferences": ["preservation societies", "heritage site newsletters"],
            "ageRange": "30-75",
            "purchasePatterns": ["donates to preservation funds and visits heritage sites"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 131,
        "name": "Urban Farmers",
        "characteristics": {
            "interests": ["urban agriculture", "rooftop gardening", "community farming"],
            "communicationPreferences": ["urban farming blogs", "agriculture co-op meetings"],
            "ageRange": "18-60",
            "purchasePatterns": ["buys gardening supplies and attends urban farming events"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 132,
        "name": "Professional Bloggers",
        "characteristics": {
            "interests": ["content creation", "digital marketing", "online monetization"],
            "communicationPreferences": ["blogging conferences", "SEO tool updates"],
            "ageRange": "18-50",
            "purchasePatterns": ["subscribes to web hosting services and content creation tools"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 133,
        "name": "Biohackers",
        "characteristics": {
            "interests": ["life extension", "nutrigenomics", "DIY biology"],
            "communicationPreferences": ["biohacking forums", "health tech meetups"],
            "ageRange": "20-50",
            "purchasePatterns": ["invests in supplements and personal genomics kits"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 134,
        "name": "Memory Athletes",
        "characteristics": {
            "interests": ["memory competitions", "mental calculation", "speed reading"],
            "communicationPreferences": ["memory training apps", "cognitive enhancement workshops"],
            "ageRange": "18-40",
            "purchasePatterns": ["enrolls in memory courses and buys cognitive training software"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 135,
        "name": "Virtual Event Planners",
        "characteristics": {
            "interests": ["online conferences", "webinar hosting", "digital networking events"],
            "communicationPreferences": ["event planning software updates", "virtual event training webinars"],
            "ageRange": "25-55",
            "purchasePatterns": ["subscribes to virtual event platforms and engages in marketing tools"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 136,
        "name": "Sustainable Travel Planners",
        "characteristics": {
            "interests": ["eco-tourism", "sustainable destinations", "green travel practices"],
            "communicationPreferences": ["travel sustainability blogs", "eco-friendly travel agency newsletters"],
            "ageRange": "20-45",
            "purchasePatterns": ["books eco-friendly lodgings and transportation"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 137,
        "name": "Mobile App Developers",
        "characteristics": {
            "interests": ["mobile technology", "app development", "user interface design"],
            "communicationPreferences": ["tech forums", "developer conferences"],
            "ageRange": "18-50",
            "purchasePatterns": ["invests in development tools and software"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 138,
        "name": "Student Loan Advisors",
        "characteristics": {
            "interests": ["financial planning", "student debt management", "educational funding"],
            "communicationPreferences": ["financial literacy workshops", "student loan service updates"],
            "ageRange": "25-40",
            "purchasePatterns": ["utilizes financial advising services and tools"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 139,
        "name": "Elderly Care Specialists",
        "characteristics": {
            "interests": ["senior care", "geriatric health", "assisted living facilities"],
            "communicationPreferences": ["healthcare provider newsletters", "caregiving seminars"],
            "ageRange": "30-60",
            "purchasePatterns": ["buys healthcare supplies and services for elderly care"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 140,
        "name": "Dystopian Fiction Writers",
        "characteristics": {
            "interests": ["dystopian novels", "science fiction writing", "literary workshops"],
            "communicationPreferences": ["writing community forums", "book club discussions"],
            "ageRange": "18-65",
            "purchasePatterns": ["attends writing retreats and publishes novels"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 141,
        "name": "Robotics Enthusiasts",
        "characteristics": {
            "interests": ["robotics competitions", "AI development", "mechatronics"],
            "communicationPreferences": ["robotics club emails", "technology expos"],
            "ageRange": "15-50",
            "purchasePatterns": ["buys robotics kits and components"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 142,
        "name": "Cultural Heritage Tour Guides",
        "characteristics": {
            "interests": ["historical tours", "cultural education", "local history"],
            "communicationPreferences": ["tourism association updates", "cultural heritage blogs"],
            "ageRange": "25-65",
            "purchasePatterns": ["invests in historical and cultural learning materials"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 143,
        "name": "Martial Arts Trainers",
        "characteristics": {
            "interests": ["martial arts", "self-defense courses", "combat sports"],
            "communicationPreferences": ["martial arts school newsletters", "training equipment catalogs"],
            "ageRange": "18-55",
            "purchasePatterns": ["purchases martial arts gear and training videos"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 144,
        "name": "Freelance Graphic Designers",
        "characteristics": {
            "interests": ["graphic design", "freelance projects", "creative software"],
            "communicationPreferences": ["design blogs", "software tutorials"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys licenses for design software and hardware"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 145,
        "name": "Youth Empowerment Coordinators",
        "characteristics": {
            "interests": ["youth programs", "educational workshops", "community service"],
            "communicationPreferences": ["non-profit youth organization newsletters", "community event updates"],
            "ageRange": "25-55",
            "purchasePatterns": ["organizes events and purchases materials for programs"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 146,
        "name": "Wearable Technology Innovators",
        "characteristics": {
            "interests": ["wearable devices", "health monitoring", "smart apparel"],
            "communicationPreferences": ["tech innovation blogs", "wearable tech expos"],
            "ageRange": "20-50",
            "purchasePatterns": ["invests in the latest wearable technology"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 147,
        "name": "Conservation Biologists",
        "characteristics": {
            "interests": ["wildlife conservation", "environmental studies", "field research"],
            "communicationPreferences": ["conservation newsletters", "scientific journals"],
            "ageRange": "25-65",
            "purchasePatterns": ["subscribes to research publications and attends conferences"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 148,
        "name": "Home Brewers",
        "characteristics": {
            "interests": ["homebrewing beer", "craft brewing", "brewing technology"],
            "communicationPreferences": ["brewing forums", "home brew supply stores newsletters"],
            "ageRange": "21-55",
            "purchasePatterns": ["buys home brewing kits and ingredients"],
            "incomeLevel": "Low to high"
        }
    },
    {
        "id": 149,
        "name": "Adventure Travel Bloggers",
        "characteristics": {
            "interests": ["adventure travel", "blogging", "extreme sports"],
            "communicationPreferences": ["travel influencer updates", "adventure gear promotions"],
            "ageRange": "20-40",
            "purchasePatterns": ["travels frequently and reviews adventure gear"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 150,
        "name": "Climatology Researchers",
        "characteristics": {
            "interests": ["climate science", "environmental research", "sustainable practices"],
            "communicationPreferences": ["scientific research publications", "climate change conferences"],
            "ageRange": "25-65",
            "purchasePatterns": ["invests in research equipment and attends scientific gatherings"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 151,
        "name": "Urban Ecologists",
        "characteristics": {
            "interests": ["urban biodiversity", "ecological sustainability", "green infrastructure"],
            "communicationPreferences": ["environmental workshops", "urban ecology newsletters"],
            "ageRange": "25-65",
            "purchasePatterns": ["participates in local ecological projects and attends seminars"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 152,
        "name": "Quantum Computing Researchers",
        "characteristics": {
            "interests": ["quantum mechanics", "computational physics", "algorithm development"],
            "communicationPreferences": ["scientific journals", "technology symposiums"],
            "ageRange": "25-50",
            "purchasePatterns": ["subscribes to research databases and attends international conferences"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 153,
        "name": "Non-Fiction Authors",
        "characteristics": {
            "interests": ["writing", "publishing", "speaking engagements"],
            "communicationPreferences": ["writer's guilds", "literary festival updates"],
            "ageRange": "30-70",
            "purchasePatterns": ["publishes books and attends networking events"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 154,
        "name": "Exotic Plant Collectors",
        "characteristics": {
            "interests": ["rare plants", "botanical gardens", "horticulture"],
            "communicationPreferences": ["gardening clubs", "botanical garden memberships"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys exotic plants and invests in greenhouse technology"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 155,
        "name": "Professional Videographers",
        "characteristics": {
            "interests": ["video production", "documentary filmmaking", "cinematic techniques"],
            "communicationPreferences": ["film equipment stores", "video editing software updates"],
            "ageRange": "20-50",
            "purchasePatterns": ["invests in high-end cameras and attends film workshops"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 156,
        "name": "Zoology Experts",
        "characteristics": {
            "interests": ["wildlife conservation", "animal behavior", "zoological research"],
            "communicationPreferences": ["zoological society newsletters", "wildlife conservation workshops"],
            "ageRange": "25-65",
            "purchasePatterns": ["subscribes to zoology journals and participates in field research"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 157,
        "name": "Medieval Historians",
        "characteristics": {
            "interests": ["medieval history", "archaeological digs", "historical reenactments"],
            "communicationPreferences": ["history conferences", "medieval reenactment group emails"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys historical weapons replicas and attends medieval fairs"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 158,
        "name": "AI Ethics Advocates",
        "characteristics": {
            "interests": ["artificial intelligence", "ethical AI use", "technology policy"],
            "communicationPreferences": ["technology ethics forums", "AI conferences"],
            "ageRange": "25-65",
            "purchasePatterns": ["engages in policy discussions and attends related symposiums"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 159,
        "name": "Indie Game Developers",
        "characteristics": {
            "interests": ["game design", "indie gaming communities", "game development tools"],
            "communicationPreferences": ["game development forums", "indie game festivals"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys game development software and attends indie game expos"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 160,
        "name": "Home Automation Hobbyists",
        "characteristics": {
            "interests": ["smart home devices", "IoT innovations", "DIY home automation projects"],
            "communicationPreferences": ["home tech blogs", "smart home device release updates"],
            "ageRange": "20-50",
            "purchasePatterns": ["builds and customizes smart home systems"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 161,
        "name": "Fitness Technology Innovators",
        "characteristics": {
            "interests": ["wearable fitness trackers", "health apps", "sports performance analytics"],
            "communicationPreferences": ["fitness technology forums", "health gadget expos"],
            "ageRange": "20-45",
            "purchasePatterns": ["tests and develops fitness technology products"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 162,
        "name": "Classic Literature Scholars",
        "characteristics": {
            "interests": ["classic novels", "literary analysis", "scholarly publications"],
            "communicationPreferences": ["academic journals", "literature symposiums"],
            "ageRange": "30-70",
            "purchasePatterns": ["purchases classic literature and attends academic conferences"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 163,
        "name": "Online Community Managers",
        "characteristics": {
            "interests": ["social media", "community engagement", "digital marketing"],
            "communicationPreferences": ["digital marketing webinars", "social media strategy updates"],
            "ageRange": "20-50",
            "purchasePatterns": ["utilizes community management tools and services"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 164,
        "name": "Private Equity Analysts",
        "characteristics": {
            "interests": ["investment strategies", "market analysis", "financial modeling"],
            "communicationPreferences": ["finance newsletters", "investment webinars"],
            "ageRange": "25-50",
            "purchasePatterns": ["invests in market analysis tools and financial publications"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 165,
        "name": "Culinary Vloggers",
        "characteristics": {
            "interests": ["cooking shows", "recipe development", "food photography"],
            "communicationPreferences": ["culinary content creator groups", "vlogging equipment updates"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys cooking supplies and video production equipment"],
            "incomeLevel": "Moderate"
        }
    },
            {
                "id": 166,
                "name": "Urban Wildlife Photographers",
                "characteristics": {
                    "interests": ["urban wildlife", "nature photography", "ecological conservation"],
                    "communicationPreferences": ["photography workshops", "wildlife conservation newsletters"],
                    "ageRange": "25-65",
                    "purchasePatterns": ["buys high-end cameras and attends photography expeditions"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 167,
                "name": "Historical Fiction Authors",
                "characteristics": {
                    "interests": ["historical narratives", "book writing", "period research"],
                    "communicationPreferences": ["writer’s communities", "historical research webinars"],
                    "ageRange": "30-70",
                    "purchasePatterns": ["purchases historical books and attends writing retreats"],
                    "incomeLevel": "Low to moderate"
                }
            },
            {
                "id": 168,
                "name": "Pet Nutrition Experts",
                "characteristics": {
                    "interests": ["animal health", "pet diets", "veterinary science"],
                    "communicationPreferences": ["pet health blogs", "veterinary seminars"],
                    "ageRange": "25-60",
                    "purchasePatterns": ["buys specialty pet foods and attends industry conferences"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 169,
                "name": "Vintage Music Collectors",
                "characteristics": {
                    "interests": ["vinyl records", "antique musical instruments", "music history"],
                    "communicationPreferences": ["record store mailings", "music collector shows"],
                    "ageRange": "30-70",
                    "purchasePatterns": ["collects rare vinyls and antique instruments"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 170,
                "name": "Aquatic Sports Instructors",
                "characteristics": {
                    "interests": ["swimming", "diving", "water polo"],
                    "communicationPreferences": ["sports training blogs", "aquatic centers newsletters"],
                    "ageRange": "20-50",
                    "purchasePatterns": ["buys aquatic sports gear and conducts training sessions"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 171,
                "name": "Mythology and Folklore Scholars",
                "characteristics": {
                    "interests": ["myth studies", "folklore research", "cultural narratives"],
                    "communicationPreferences": ["academic journals", "cultural symposiums"],
                    "ageRange": "25-65",
                    "purchasePatterns": ["buys mythology books and attends related conferences"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 172,
                "name": "Space Model Collectors",
                "characteristics": {
                    "interests": ["model rockets", "space memorabilia", "astronautics"],
                    "communicationPreferences": ["modeling forums", "space exploration updates"],
                    "ageRange": "20-60",
                    "purchasePatterns": ["collects space-related models and attends launches"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 173,
                "name": "Gluten-Free Lifestyle Advocates",
                "characteristics": {
                    "interests": ["gluten-free cooking", "celiac disease awareness", "health blogging"],
                    "communicationPreferences": ["health food blogs", "gluten-free product updates"],
                    "ageRange": "18-65",
                    "purchasePatterns": ["buys gluten-free products and participates in health forums"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 174,
                "name": "Antarctic Researchers",
                "characteristics": {
                    "interests": ["polar science", "climate studies", "expedition planning"],
                    "communicationPreferences": ["scientific expedition updates", "climate research newsletters"],
                    "ageRange": "25-60",
                    "purchasePatterns": ["invests in cold-weather gear and research equipment"],
                    "incomeLevel": "High"
                }
            },
            {
                "id": 175,
                "name": "Disability Rights Advocates",
                "characteristics": {
                    "interests": ["accessibility", "inclusive design", "policy reform"],
                    "communicationPreferences": ["disability advocacy networks", "policy update newsletters"],
                    "ageRange": "20-70",
                    "purchasePatterns": ["participates in advocacy groups and attends legislative sessions"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 176,
                "name": "Children’s Book Illustrators",
                "characteristics": {
                    "interests": ["children's literature", "book illustration", "publishing"],
                    "communicationPreferences": ["art workshops", "children's book publisher emails"],
                    "ageRange": "25-60",
                    "purchasePatterns": ["buys art supplies and attends book fairs"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 177,
                "name": "Urban Agriculture Innovators",
                "characteristics": {
                    "interests": ["vertical farming", "hydroponics", "sustainable food production"],
                    "communicationPreferences": ["agriculture technology blogs", "urban farming workshops"],
                    "ageRange": "20-50",
                    "purchasePatterns": ["invests in urban farming systems and attends related expos"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 178,
                "name": "Fan Fiction Writers",
                "characteristics": {
                    "interests": ["fan fiction communities", "online writing", "pop culture analysis"],
                    "communicationPreferences": ["writing forums", "fan convention updates"],
                    "ageRange": "15-50",
                    "purchasePatterns": ["engages in online communities and buys fan merchandise"],
                    "incomeLevel": "Low to moderate"
                }
            },
            {
                "id": 179,
                "name": "Futurists",
                "characteristics": {
                    "interests": ["future trends", "technological advancements", "predictive analytics"],
                    "communicationPreferences": ["futurism blogs", "innovation conferences"],
                    "ageRange": "25-65",
                    "purchasePatterns": ["subscribes to technology forecasts and attends futurist gatherings"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 180,
                "name": "Nostalgia Marketers",
                "characteristics": {
                    "interests": ["retro branding", "vintage advertising", "market nostalgia"],
                    "communicationPreferences": ["marketing newsletters", "branding workshops"],
                    "ageRange": "30-60",
                    "purchasePatterns": ["employs nostalgic elements in marketing campaigns"],
                    "incomeLevel": "High"
                }
            },
            {
                "id": 181,
                "name": "Sleep Science Experts",
                "characteristics": {
                    "interests": ["sleep research", "neuroscience", "sleep technology"],
                    "communicationPreferences": ["sleep science journals", "health tech expos"],
                    "ageRange": "30-65",
                    "purchasePatterns": ["invests in sleep monitoring devices and attends related conferences"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 182,
                "name": "Cat Behaviorists",
                "characteristics": {
                    "interests": ["feline behavior", "cat training", "animal psychology"],
                    "communicationPreferences": ["pet behaviorist workshops", "animal welfare newsletters"],
                    "ageRange": "25-65",
                    "purchasePatterns": ["buys cat training tools and subscribes to pet psychology journals"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 183,
                "name": "Ecotourism Operators",
                "characteristics": {
                    "interests": ["ecotourism", "sustainable travel", "conservation efforts"],
                    "communicationPreferences": ["ecotourism associations", "sustainability conferences"],
                    "ageRange": "30-55",
                    "purchasePatterns": ["operates ecotourism ventures and participates in environmental conservation"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 184,
                "name": "Blockchain Developers",
                "characteristics": {
                    "interests": ["blockchain technology", "cryptocurrency", "decentralized applications"],
                    "communicationPreferences": ["tech development blogs", "blockchain summits"],
                    "ageRange": "20-50",
                    "purchasePatterns": ["develops blockchain applications and attends industry meetups"],
                    "incomeLevel": "High"
                }
            },
            {
                "id": 185,
                "name": "Vintage Electronics Collectors",
                "characteristics": {
                    "interests": ["vintage radios", "antique televisions", "old-school computing"],
                    "communicationPreferences": ["collector forums", "electronics fairs"],
                    "ageRange": "30-70",
                    "purchasePatterns": ["collects and restores vintage electronics"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 186,
                "name": "Social Justice Educators",
                "characteristics": {
                    "interests": ["social justice", "community education", "activism"],
                    "communicationPreferences": ["educational nonprofits", "social justice workshops"],
                    "ageRange": "25-65",
                    "purchasePatterns": ["organizes and leads workshops, buys educational materials"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 187,
                "name": "Passive Income Investors",
                "characteristics": {
                    "interests": ["real estate investment", "stock market", "income-generating assets"],
                    "communicationPreferences": ["investment blogs", "financial freedom seminars"],
                    "ageRange": "30-65",
                    "purchasePatterns": ["invests in stocks, bonds, real estate, and other assets"],
                    "incomeLevel": "High"
                }
            },
            {
                "id": 188,
                "name": "Reptile Breeders",
                "characteristics": {
                    "interests": ["reptile breeding", "herpetology", "reptile care"],
                    "communicationPreferences": ["reptile enthusiast forums", "herpetological society newsletters"],
                    "ageRange": "18-60",
                    "purchasePatterns": ["buys and cares for various species of reptiles"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 189,
                "name": "Postmodern Artists",
                "characteristics": {
                    "interests": ["postmodern art", "gallery exhibitions", "art theory"],
                    "communicationPreferences": ["art school newsletters", "gallery opening invitations"],
                    "ageRange": "25-70",
                    "purchasePatterns": ["creates and exhibits art, buys art supplies"],
                    "incomeLevel": "Moderate to high"
                }
            },
            {
                "id": 190,
                "name": "Performance Poets",
                "characteristics": {
                    "interests": ["spoken word", "poetry slams", "literary festivals"],
                    "communicationPreferences": ["poetry communities", "festival announcements"],
                    "ageRange": "18-50",
                    "purchasePatterns": ["participates in slams and buys poetry books"],
                    "incomeLevel": "Low to moderate"
                }
            },
            {
                "id": 191,
                "name": "Deep Sea Researchers",
                "characteristics": {
                    "interests": ["oceanography", "marine biology", "underwater exploration"],
                    "communicationPreferences": ["scientific journals", "marine research symposiums"],
                    "ageRange": "25-60",
                    "purchasePatterns": ["invests in underwater equipment and research tools"],
                    "incomeLevel": "High"
                }
            },
            {
                "id": 192,
                "name": "Choral Directors",
                "characteristics": {
                    "interests": ["choral music", "vocal performance", "music education"],
                    "communicationPreferences": ["music education associations", "choral workshop updates"],
                    "ageRange": "25-65",
                    "purchasePatterns": ["buys music scores and organizes performances"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 193,
                "name": "Amateur Geologists",
                "characteristics": {
                    "interests": ["rock collecting", "geological surveys", "earth sciences"],
                    "communicationPreferences": ["geology club newsletters", "field trip announcements"],
                    "ageRange": "20-70",
                    "purchasePatterns": ["buys geology tools and participates in excursions"],
                    "incomeLevel": "Low to moderate"
                }
            },
            {
                "id": 194,
                "name": "Live Stream Gamers",
                "characteristics": {
                    "interests": ["video gaming", "streaming on Twitch", "esports"],
                    "communicationPreferences": ["gaming community updates", "streaming technology blogs"],
                    "ageRange": "16-40",
                    "purchasePatterns": ["invests in gaming equipment and streaming software"],
                    "incomeLevel": "Moderate"
                }
            },
            {
                "id": 195,
                "name": "Food Truck Entrepreneurs",
                "characteristics": {
                    "interests": ["street food", "culinary entrepreneurship", "mobile catering"],
                    "communicationPreferences": ["culinary business seminars", "food industry newsletters"],
                    "ageRange": "25-50",
                    "purchasePatterns": ["invests in a food truck and related equipment"],
                    "incomeLevel": "Moderate to high"
                }
            },

    {
        "id": 196,
        "name": "Aquascapers",
        "characteristics": {
            "interests": ["aquascaping", "aquarium design", "freshwater ecosystems"],
            "communicationPreferences": ["aquarium hobbyist newsletters", "aquascaping competitions"],
            "ageRange": "20-55",
            "purchasePatterns": ["buys aquascaping supplies and competes in design contests"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 197,
        "name": "Cultural Anthropologists",
        "characteristics": {
            "interests": ["cultural studies", "ethnography", "field research"],
            "communicationPreferences": ["academic journals", "anthropology conferences"],
            "ageRange": "25-65",
            "purchasePatterns": ["engages in fieldwork and subscribes to research publications"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 198,
        "name": "Minimalist Travelers",
        "characteristics": {
            "interests": ["backpacking", "minimalist living", "sustainable travel"],
            "communicationPreferences": ["travel blogs", "minimalism communities"],
            "ageRange": "18-45",
            "purchasePatterns": ["buys travel gear and attends travel workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 199,
        "name": "Cryptocurrency Traders",
        "characteristics": {
            "interests": ["cryptocurrency markets", "blockchain technology", "investment strategies"],
            "communicationPreferences": ["crypto exchange alerts", "investment forums"],
            "ageRange": "20-50",
            "purchasePatterns": ["trades cryptocurrencies and invests in blockchain projects"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 200,
        "name": "Arctic Explorers",
        "characteristics": {
            "interests": ["polar expeditions", "extreme environments", "climate research"],
            "communicationPreferences": ["exploration newsletters", "scientific expedition updates"],
            "ageRange": "25-65",
            "purchasePatterns": ["invests in expedition gear and attends research conferences"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 201,
        "name": "Vintage Car Enthusiasts",
        "characteristics": {
            "interests": ["classic cars", "car restoration", "automotive history"],
            "communicationPreferences": ["car shows", "classic car magazines"],
            "ageRange": "30-70",
            "purchasePatterns": ["invests in car restoration parts and attends auto shows"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 202,
        "name": "Mindfulness Practitioners",
        "characteristics": {
            "interests": ["meditation", "yoga", "wellness retreats"],
            "communicationPreferences": ["wellness blogs", "meditation app updates"],
            "ageRange": "18-65",
            "purchasePatterns": ["attends retreats and subscribes to wellness services"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 203,
        "name": "E-Sports Competitors",
        "characteristics": {
            "interests": ["competitive gaming", "tournaments", "gaming communities"],
            "communicationPreferences": ["e-sports forums", "game streaming platforms"],
            "ageRange": "16-35",
            "purchasePatterns": ["invests in gaming hardware and participates in competitions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 204,
        "name": "Art Installers",
        "characteristics": {
            "interests": ["art installation", "exhibition setup", "art transport"],
            "communicationPreferences": ["gallery newsletters", "art handling workshops"],
            "ageRange": "25-55",
            "purchasePatterns": ["buys tools and materials for art installations"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 205,
        "name": "Urban Cyclists",
        "characteristics": {
            "interests": ["city biking", "bike commuting", "cycling gear"],
            "communicationPreferences": ["cycling blogs", "urban cycling events"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys bikes and accessories for urban commuting"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 206,
        "name": "Landscape Photographers",
        "characteristics": {
            "interests": ["nature photography", "travel", "scenic locations"],
            "communicationPreferences": ["photography magazines", "photo exhibition updates"],
            "ageRange": "25-65",
            "purchasePatterns": ["invests in camera equipment and travel gear"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 207,
        "name": "Furniture Makers",
        "characteristics": {
            "interests": ["woodworking", "custom furniture", "craftsmanship"],
            "communicationPreferences": ["woodworking forums", "craft fairs"],
            "ageRange": "30-65",
            "purchasePatterns": ["buys woodworking tools and materials"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 208,
        "name": "Professional Chefs",
        "characteristics": {
            "interests": ["gourmet cooking", "culinary arts", "restaurant management"],
            "communicationPreferences": ["chef associations", "culinary workshops"],
            "ageRange": "25-60",
            "purchasePatterns": ["invests in kitchen equipment and attends culinary events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 209,
        "name": "Festival Organizers",
        "characteristics": {
            "interests": ["event planning", "music festivals", "cultural events"],
            "communicationPreferences": ["event planning newsletters", "industry conferences"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in event production services and materials"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 210,
        "name": "Renaissance Fair Participants",
        "characteristics": {
            "interests": ["historical reenactments", "costuming", "medieval culture"],
            "communicationPreferences": ["Renaissance fair groups", "historical societies"],
            "ageRange": "18-65",
            "purchasePatterns": ["buys period costumes and attends fairs"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 211,
        "name": "Motorsport Enthusiasts",
        "characteristics": {
            "interests": ["auto racing", "motorbike racing", "racing technology"],
            "communicationPreferences": ["motorsport magazines", "racing event newsletters"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys racing gear and attends races"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 212,
        "name": "Beer Brewers",
        "characteristics": {
            "interests": ["homebrewing", "craft beer", "beer tasting"],
            "communicationPreferences": ["brewing forums", "beer festivals"],
            "ageRange": "21-55",
            "purchasePatterns": ["buys brewing equipment and attends tasting events"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 213,
        "name": "Permaculture Designers",
        "characteristics": {
            "interests": ["sustainable agriculture", "ecosystem design", "organic farming"],
            "communicationPreferences": ["permaculture blogs", "sustainability workshops"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys permaculture books and tools, attends design courses"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 214,
        "name": "VR Content Creators",
        "characteristics": {
            "interests": ["virtual reality", "content creation", "immersive experiences"],
            "communicationPreferences": ["VR developer forums", "tech industry newsletters"],
            "ageRange": "18-45",
            "purchasePatterns": ["buys VR development software and equipment"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 215,
        "name": "Marine Conservationists",
        "characteristics": {
            "interests": ["ocean conservation", "marine biology", "sustainable fishing"],
            "communicationPreferences": ["conservation newsletters", "marine research publications"],
            "ageRange": "25-65",
            "purchasePatterns": ["supports marine conservation organizations and buys eco-friendly products"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 216,
        "name": "Dog Trainers",
        "characteristics": {
            "interests": ["canine behavior", "obedience training", "pet care"],
            "communicationPreferences": ["dog training blogs", "pet industry events"],
            "ageRange": "20-55",
            "purchasePatterns": ["buys training tools and attends workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 217,
        "name": "Gemologists",
        "characteristics": {
            "interests": ["gemstone grading", "jewelry design", "mineralogy"],
            "communicationPreferences": ["gemology forums", "jewelry trade publications"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys gemstones and attends gem trade shows"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 218,
        "name": "Skateboard Designers",
        "characteristics": {
            "interests": ["skateboarding", "product design", "urban culture"],
            "communicationPreferences": ["skateboarding magazines", "design workshops"],
            "ageRange": "15-35",
            "purchasePatterns": ["buys design tools and customizes skateboards"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 219,
        "name": "Film Preservationists",
        "characteristics": {
            "interests": ["cinema history", "film archiving", "restoration"],
            "communicationPreferences": ["film preservation forums", "archive newsletters"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys archival materials and attends preservation conferences"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 220,
        "name": "Rare Coin Collectors",
        "characteristics": {
            "interests": ["numismatics", "antique coins", "historical currency"],
            "communicationPreferences": ["coin collecting forums", "auction house catalogs"],
            "ageRange": "35-75",
            "purchasePatterns": ["invests in rare coins and attends auctions"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 221,
        "name": "Chess Grandmasters",
        "characteristics": {
            "interests": ["chess strategy", "competitive chess", "tournaments"],
            "communicationPreferences": ["chess magazines", "tournament announcements"],
            "ageRange": "15-70",
            "purchasePatterns": ["buys chess sets and attends international competitions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 222,
        "name": "Drone Racing Enthusiasts",
        "characteristics": {
            "interests": ["drone racing", "FPV technology", "aerodynamics"],
            "communicationPreferences": ["drone forums", "racing event updates"],
            "ageRange": "18-45",
            "purchasePatterns": ["buys racing drones and participates in events"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 223,
        "name": "Photobook Creators",
        "characteristics": {
            "interests": ["photojournalism", "memory preservation", "book design"],
            "communicationPreferences": ["photography blogs", "publishing workshops"],
            "ageRange": "25-65",
            "purchasePatterns": ["creates photobooks and attends design courses"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 224,
        "name": "Climate Change Activists",
        "characteristics": {
            "interests": ["environmental activism", "sustainability", "policy advocacy"],
            "communicationPreferences": ["activist newsletters", "sustainability blogs"],
            "ageRange": "18-55",
            "purchasePatterns": ["supports environmental causes and buys eco-friendly products"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 225,
        "name": "Wine Connoisseurs",
        "characteristics": {
            "interests": ["wine tasting", "vineyard tours", "wine collecting"],
            "communicationPreferences": ["wine club emails", "vineyard event notifications"],
            "ageRange": "30-65",
            "purchasePatterns": ["buys premium wines and attends exclusive tastings"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 226,
        "name": "Antique Weaponry Collectors",
        "characteristics": {
            "interests": ["antique firearms", "historical weaponry", "military history"],
            "communicationPreferences": ["collector newsletters", "historical societies"],
            "ageRange": "35-70",
            "purchasePatterns": ["invests in antique weapons and attends auctions"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 227,
        "name": "Fiction Podcast Creators",
        "characteristics": {
            "interests": ["audio drama", "scriptwriting", "sound design"],
            "communicationPreferences": ["podcast forums", "audio production workshops"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys recording equipment and distributes podcasts"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 228,
        "name": "Elder Care Advocates",
        "characteristics": {
            "interests": ["geriatric care", "elder rights", "healthcare policy"],
            "communicationPreferences": ["healthcare newsletters", "policy briefings"],
            "ageRange": "30-70",
            "purchasePatterns": ["supports elder care initiatives and advocates for policy changes"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 229,
        "name": "Sailing Enthusiasts",
        "characteristics": {
            "interests": ["sailing", "yachting", "ocean navigation"],
            "communicationPreferences": ["boating magazines", "sailing club newsletters"],
            "ageRange": "25-70",
            "purchasePatterns": ["invests in boats and sailing gear"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 230,
        "name": "Collectible Toy Traders",
        "characteristics": {
            "interests": ["action figures", "vintage toys", "collectible trading"],
            "communicationPreferences": ["collector forums", "toy conventions"],
            "ageRange": "20-50",
            "purchasePatterns": ["buys and trades collectible toys"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 231,
        "name": "Precision Agriculture Innovators",
        "characteristics": {
            "interests": ["smart farming", "agriculture technology", "crop management"],
            "communicationPreferences": ["agriculture tech blogs", "farming expos"],
            "ageRange": "25-60",
            "purchasePatterns": ["invests in precision farming tools and attends industry events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 232,
        "name": "Art Critics",
        "characteristics": {
            "interests": ["art analysis", "gallery reviews", "art history"],
            "communicationPreferences": ["art journals", "gallery newsletters"],
            "ageRange": "30-70",
            "purchasePatterns": ["attends art exhibitions and publishes reviews"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 233,
        "name": "Family Genealogists",
        "characteristics": {
            "interests": ["family history", "ancestry research", "genealogy"],
            "communicationPreferences": ["genealogy websites", "family history workshops"],
            "ageRange": "40-75",
            "purchasePatterns": ["subscribes to genealogy databases and attends conferences"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 234,
        "name": "Sustainable Architects",
        "characteristics": {
            "interests": ["green building", "sustainable design", "renewable energy"],
            "communicationPreferences": ["architecture magazines", "green building expos"],
            "ageRange": "30-60",
            "purchasePatterns": ["uses sustainable materials and attends design workshops"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 235,
        "name": "Tactical Gear Collectors",
        "characteristics": {
            "interests": ["military surplus", "outdoor survival", "tactical equipment"],
            "communicationPreferences": ["military history forums", "gear review blogs"],
            "ageRange": "25-55",
            "purchasePatterns": ["buys tactical gear and participates in survival training"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 236,
        "name": "Pet Fashion Designers",
        "characteristics": {
            "interests": ["pet clothing", "fashion design", "pet care"],
            "communicationPreferences": ["fashion industry newsletters", "pet industry events"],
            "ageRange": "20-45",
            "purchasePatterns": ["designs and sells pet apparel"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 237,
        "name": "Youth Mentorship Coordinators",
        "characteristics": {
            "interests": ["youth development", "mentorship programs", "education"],
            "communicationPreferences": ["nonprofit newsletters", "community outreach"],
            "ageRange": "25-55",
            "purchasePatterns": ["organizes mentorship programs and attends educational seminars"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 238,
        "name": "Indie Music Producers",
        "characteristics": {
            "interests": ["indie music", "record production", "music festivals"],
            "communicationPreferences": ["music industry blogs", "record label newsletters"],
            "ageRange": "20-50",
            "purchasePatterns": ["produces music and attends indie music events"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 239,
        "name": "Adventure Filmmakers",
        "characteristics": {
            "interests": ["extreme sports", "documentary filmmaking", "outdoor exploration"],
            "communicationPreferences": ["filmmaking forums", "adventure sports magazines"],
            "ageRange": "25-50",
            "purchasePatterns": ["buys filming gear and participates in expeditions"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 240,
        "name": "Public Health Advocates",
        "characteristics": {
            "interests": ["healthcare policy", "community health", "disease prevention"],
            "communicationPreferences": ["health policy newsletters", "public health conferences"],
            "ageRange": "30-65",
            "purchasePatterns": ["supports public health initiatives and attends conferences"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 241,
        "name": "Steampunk Enthusiasts",
        "characteristics": {
            "interests": ["steampunk fashion", "DIY crafting", "alternative history"],
            "communicationPreferences": ["steampunk forums", "genre conventions"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys and creates steampunk costumes and accessories"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 242,
        "name": "Electric Vehicle Advocates",
        "characteristics": {
            "interests": ["electric cars", "renewable energy", "sustainable transportation"],
            "communicationPreferences": ["automotive magazines", "sustainability expos"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys electric vehicles and invests in green tech"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 243,
        "name": "Robo Advisors",
        "characteristics": {
            "interests": ["fintech", "automated investment", "financial planning"],
            "communicationPreferences": ["finance blogs", "technology news"],
            "ageRange": "30-55",
            "purchasePatterns": ["uses robo-advisor platforms and invests in fintech"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 244,
        "name": "Sound Engineers",
        "characteristics": {
            "interests": ["audio production", "live sound", "music technology"],
            "communicationPreferences": ["audio engineering magazines", "industry workshops"],
            "ageRange": "20-55",
            "purchasePatterns": ["buys audio equipment and works on live events"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 245,
        "name": "Urban Designers",
        "characteristics": {
            "interests": ["city planning", "architecture", "public spaces"],
            "communicationPreferences": ["urban planning journals", "design symposiums"],
            "ageRange": "25-60",
            "purchasePatterns": ["engages in urban design projects and attends conferences"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 246,
        "name": "Fantasy Football Managers",
        "characteristics": {
            "interests": ["fantasy sports", "NFL", "statistical analysis"],
            "communicationPreferences": ["sports websites", "fantasy football leagues"],
            "ageRange": "18-50",
            "purchasePatterns": ["participates in fantasy leagues and buys related software"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 247,
        "name": "Renewable Energy Investors",
        "characteristics": {
            "interests": ["sustainable energy", "solar power", "green investment"],
            "communicationPreferences": ["energy industry reports", "investment newsletters"],
            "ageRange": "30-65",
            "purchasePatterns": ["invests in renewable energy projects and technology"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 248,
        "name": "Parkour Athletes",
        "characteristics": {
            "interests": ["freerunning", "urban sports", "physical fitness"],
            "communicationPreferences": ["parkour forums", "training videos"],
            "ageRange": "15-35",
            "purchasePatterns": ["buys athletic gear and attends parkour workshops"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 249,
        "name": "Classic Film Buffs",
        "characteristics": {
            "interests": ["classic cinema", "film history", "movie screenings"],
            "communicationPreferences": ["film clubs", "cinema events"],
            "ageRange": "30-70",
            "purchasePatterns": ["collects classic films and attends screenings"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 250,
        "name": "Historical Artifact Restorers",
        "characteristics": {
            "interests": ["artifact restoration", "museum work", "historical preservation"],
            "communicationPreferences": ["restoration workshops", "museum newsletters"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys restoration materials and attends preservation seminars"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 251,
        "name": "Public Relations Specialists",
        "characteristics": {
            "interests": ["brand management", "crisis communication", "media relations"],
            "communicationPreferences": ["PR industry reports", "communication strategy newsletters"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in media monitoring tools and attends PR conferences"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 252,
        "name": "Organic Farmers",
        "characteristics": {
            "interests": ["sustainable farming", "organic produce", "farm-to-table"],
            "communicationPreferences": ["agriculture forums", "organic farming associations"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys organic seeds and farming equipment"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 253,
        "name": "Cognitive Behavioral Therapists",
        "characteristics": {
            "interests": ["psychotherapy", "mental health", "CBT techniques"],
            "communicationPreferences": ["psychology journals", "therapist networks"],
            "ageRange": "30-60",
            "purchasePatterns": ["subscribes to professional publications and attends workshops"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 254,
        "name": "Urban Foragers",
        "characteristics": {
            "interests": ["wild food foraging", "sustainable living", "urban gardening"],
            "communicationPreferences": ["foraging groups", "sustainability blogs"],
            "ageRange": "18-55",
            "purchasePatterns": ["buys foraging tools and attends workshops"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 255,
        "name": "Space Tourism Enthusiasts",
        "characteristics": {
            "interests": ["space travel", "aerospace technology", "future tourism"],
            "communicationPreferences": ["space exploration forums", "aerospace industry news"],
            "ageRange": "25-60",
            "purchasePatterns": ["invests in space tourism companies and follows industry developments"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 256,
        "name": "Bicycle Mechanics",
        "characteristics": {
            "interests": ["bike repair", "cycling", "mechanical engineering"],
            "communicationPreferences": ["bike maintenance forums", "cycling magazines"],
            "ageRange": "20-50",
            "purchasePatterns": ["buys tools and bike parts for maintenance"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 257,
        "name": "Retirement Planners",
        "characteristics": {
            "interests": ["financial planning", "investment management", "pension strategies"],
            "communicationPreferences": ["finance newsletters", "retirement planning seminars"],
            "ageRange": "35-65",
            "purchasePatterns": ["subscribes to financial planning tools and attends workshops"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 258,
        "name": "Wildlife Documentary Filmmakers",
        "characteristics": {
            "interests": ["nature filming", "wildlife conservation", "documentary production"],
            "communicationPreferences": ["filmmaking magazines", "conservation newsletters"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in camera equipment and participates in conservation projects"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 259,
        "name": "Philanthropists",
        "characteristics": {
            "interests": ["charitable giving", "nonprofit organizations", "social causes"],
            "communicationPreferences": ["philanthropy networks", "charity event updates"],
            "ageRange": "40-75",
            "purchasePatterns": ["supports various causes and attends fundraising events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 260,
        "name": "Sports Analysts",
        "characteristics": {
            "interests": ["sports statistics", "game analysis", "broadcast journalism"],
            "communicationPreferences": ["sports networks", "analytics tools updates"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys analytical software and follows sports news"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 261,
        "name": "Ceramic Artists",
        "characteristics": {
            "interests": ["pottery", "sculpting", "art exhibitions"],
            "communicationPreferences": ["art workshops", "ceramics magazines"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys clay and sculpting tools and participates in art fairs"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 262,
        "name": "Startup Founders",
        "characteristics": {
            "interests": ["entrepreneurship", "innovation", "venture capital"],
            "communicationPreferences": ["startup blogs", "entrepreneurial networks"],
            "ageRange": "25-45",
            "purchasePatterns": ["invests in startup tools and attends networking events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 263,
        "name": "Professional Bloggers",
        "characteristics": {
            "interests": ["content creation", "digital marketing", "SEO"],
            "communicationPreferences": ["blogging platforms", "content strategy updates"],
            "ageRange": "18-45",
            "purchasePatterns": ["subscribes to content management systems and marketing tools"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 264,
        "name": "Surfing Instructors",
        "characteristics": {
            "interests": ["surfing", "water sports", "coaching"],
            "communicationPreferences": ["surf schools", "water sports magazines"],
            "ageRange": "20-50",
            "purchasePatterns": ["buys surfboards and teaching gear, attends water sports events"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 265,
        "name": "Comic Strip Artists",
        "characteristics": {
            "interests": ["cartooning", "graphic novels", "illustration"],
            "communicationPreferences": ["art forums", "comic conventions"],
            "ageRange": "18-55",
            "purchasePatterns": ["buys art supplies and comic books, participates in conventions"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 266,
        "name": "Expatriates",
        "characteristics": {
            "interests": ["global living", "cultural adaptation", "international communities"],
            "communicationPreferences": ["expat networks", "global news platforms"],
            "ageRange": "25-65",
            "purchasePatterns": ["subscribes to relocation services and international banking"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 267,
        "name": "AI Trainers",
        "characteristics": {
            "interests": ["machine learning", "artificial intelligence", "data annotation"],
            "communicationPreferences": ["AI research publications", "tech industry updates"],
            "ageRange": "25-50",
            "purchasePatterns": ["buys AI development tools and attends industry events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 268,
        "name": "Classical Musicians",
        "characteristics": {
            "interests": ["classical music", "orchestra performance", "musical composition"],
            "communicationPreferences": ["music schools", "concert hall updates"],
            "ageRange": "25-70",
            "purchasePatterns": ["buys musical instruments and sheet music, attends performances"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 269,
        "name": "Streetwear Designers",
        "characteristics": {
            "interests": ["fashion design", "urban culture", "brand creation"],
            "communicationPreferences": ["fashion blogs", "streetwear magazines"],
            "ageRange": "18-40",
            "purchasePatterns": ["invests in design software and participates in fashion shows"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 270,
        "name": "Language Teachers",
        "characteristics": {
            "interests": ["language education", "cultural exchange", "teaching methodologies"],
            "communicationPreferences": ["educational resources", "teaching conferences"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys teaching materials and attends educational workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 271,
        "name": "Leatherworkers",
        "characteristics": {
            "interests": ["leather crafting", "handmade goods", "artisan skills"],
            "communicationPreferences": ["craftsmanship forums", "artisan market updates"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys leatherworking tools and materials, attends craft fairs"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 272,
        "name": "Equestrian Enthusiasts",
        "characteristics": {
            "interests": ["horse riding", "show jumping", "animal care"],
            "communicationPreferences": ["equestrian magazines", "riding clubs"],
            "ageRange": "18-65",
            "purchasePatterns": ["buys riding gear and horse care products, participates in competitions"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 273,
        "name": "Photovoltaic Engineers",
        "characteristics": {
            "interests": ["solar energy", "renewable power", "engineering"],
            "communicationPreferences": ["energy industry updates", "engineering forums"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in solar panels and engineering tools, attends conferences"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 274,
        "name": "Documentary Photographers",
        "characteristics": {
            "interests": ["photojournalism", "cultural documentation", "visual storytelling"],
            "communicationPreferences": ["photography workshops", "journalism publications"],
            "ageRange": "25-55",
            "purchasePatterns": ["buys cameras and photography gear, participates in exhibitions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 275,
        "name": "Soundtrack Composers",
        "characteristics": {
            "interests": ["film scoring", "music production", "orchestration"],
            "communicationPreferences": ["music industry updates", "composer networks"],
            "ageRange": "25-60",
            "purchasePatterns": ["invests in audio production software and studio equipment"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 276,
        "name": "Meditation Instructors",
        "characteristics": {
            "interests": ["mindfulness", "spirituality", "wellness"],
            "communicationPreferences": ["meditation retreats", "wellness newsletters"],
            "ageRange": "30-65",
            "purchasePatterns": ["attends and leads wellness retreats, buys meditation tools"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 277,
        "name": "Cryptography Experts",
        "characteristics": {
            "interests": ["encryption", "data security", "mathematical theories"],
            "communicationPreferences": ["cybersecurity journals", "cryptography conferences"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in cryptographic tools and attends tech industry events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 278,
        "name": "Hiking Guides",
        "characteristics": {
            "interests": ["outdoor adventure", "nature", "fitness"],
            "communicationPreferences": ["outdoor gear reviews", "hiking trail updates"],
            "ageRange": "20-50",
            "purchasePatterns": ["buys outdoor gear and attends guiding workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 279,
        "name": "Behavioral Economists",
        "characteristics": {
            "interests": ["psychology", "economic theory", "decision-making processes"],
            "communicationPreferences": ["economic journals", "behavioral research conferences"],
            "ageRange": "30-65",
            "purchasePatterns": ["invests in research tools and subscribes to academic publications"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 280,
        "name": "Outdoor Survivalists",
        "characteristics": {
            "interests": ["survival skills", "wilderness training", "self-reliance"],
            "communicationPreferences": ["survivalist forums", "outdoor gear newsletters"],
            "ageRange": "20-55",
            "purchasePatterns": ["buys survival gear and attends training sessions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 281,
        "name": "Vegan Chefs",
        "characteristics": {
            "interests": ["plant-based cooking", "nutrition", "ethical eating"],
            "communicationPreferences": ["vegan food blogs", "culinary workshops"],
            "ageRange": "20-50",
            "purchasePatterns": ["buys vegan ingredients and kitchen equipment, attends cooking classes"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 282,
        "name": "Travel Photographers",
        "characteristics": {
            "interests": ["travel", "photography", "cultural exploration"],
            "communicationPreferences": ["travel magazines", "photography exhibitions"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in camera gear and travel arrangements, participates in exhibitions"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 283,
        "name": "Homeopathic Practitioners",
        "characteristics": {
            "interests": ["alternative medicine", "holistic health", "natural remedies"],
            "communicationPreferences": ["health and wellness magazines", "alternative medicine forums"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys natural remedies and attends holistic health workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 284,
        "name": "Outdoor Gear Designers",
        "characteristics": {
            "interests": ["product design", "outdoor activities", "innovation"],
            "communicationPreferences": ["outdoor industry reports", "design conferences"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in design software and prototyping tools"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 285,
        "name": "Winegrowers",
        "characteristics": {
            "interests": ["viticulture", "winemaking", "agriculture"],
            "communicationPreferences": ["wine industry publications", "viticulture seminars"],
            "ageRange": "30-65",
            "purchasePatterns": ["buys vineyard equipment and attends winemaking workshops"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 286,
        "name": "Paleoanthropologists",
        "characteristics": {
            "interests": ["human evolution", "archaeology", "ancient history"],
            "communicationPreferences": ["scientific journals", "archaeological conferences"],
            "ageRange": "30-65",
            "purchasePatterns": ["invests in research tools and attends archaeological digs"],
            "incomeLevel": "Moderate to high"
        }
    },
        {
            "id": 287,
            "name": "College Sports Enthusiast",
            "characteristics": {
                "interests": ["college athletics", "sports rivalries", "game day traditions"],
                "communicationPreferences": ["sports blogs", "college sports newsletters", "social media groups"],
                "ageRange": "18-40",
                "purchasePatterns": ["buys team merchandise, game tickets, and subscribes to sports channels"],
                "incomeLevel": "Moderate"
            }
        },
        {
        "id": 288,
        "name": "Conservation Biologists",
        "characteristics": {
            "interests": ["biodiversity", "ecosystem management", "environmental science"],
            "communicationPreferences": ["scientific publications", "conservation conferences"],
            "ageRange": "30-60",
            "purchasePatterns": ["subscribes to scientific journals and participates in fieldwork"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 289,
        "name": "Art Therapists",
        "characteristics": {
            "interests": ["art therapy", "mental health", "creative expression"],
            "communicationPreferences": ["therapy journals", "art therapy workshops"],
            "ageRange": "25-55",
            "purchasePatterns": ["buys art supplies and attends therapy training sessions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 290,
        "name": "Craft Beer Brewers",
        "characteristics": {
            "interests": ["brewing", "craft beer", "culinary arts"],
            "communicationPreferences": ["beer enthusiast forums", "brewing magazines"],
            "ageRange": "25-55",
            "purchasePatterns": ["buys brewing equipment and attends beer festivals"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 291,
        "name": "Arborists",
        "characteristics": {
            "interests": ["tree care", "landscaping", "forestry"],
            "communicationPreferences": ["landscaping forums", "forestry magazines"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys tree care equipment and attends landscaping workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 292,
        "name": "Herbalists",
        "characteristics": {
            "interests": ["botanical medicine", "holistic health", "gardening"],
            "communicationPreferences": ["herbalism forums", "natural health publications"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys herbs and natural remedies, attends herbalist training"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 293,
        "name": "Pet Groomers",
        "characteristics": {
            "interests": ["animal care", "pet grooming", "small business"],
            "communicationPreferences": ["pet industry magazines", "grooming workshops"],
            "ageRange": "20-55",
            "purchasePatterns": ["buys grooming tools and attends pet care expos"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 294,
        "name": "Urban Planners",
        "characteristics": {
            "interests": ["city development", "public policy", "infrastructure design"],
            "communicationPreferences": ["urban planning journals", "government publications"],
            "ageRange": "30-60",
            "purchasePatterns": ["invests in planning tools and attends policy workshops"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 295,
        "name": "Food Stylists",
        "characteristics": {
            "interests": ["culinary arts", "photography", "creative design"],
            "communicationPreferences": ["food styling magazines", "culinary workshops"],
            "ageRange": "25-50",
            "purchasePatterns": ["buys food styling tools and attends culinary expos"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 296,
        "name": "Digital Nomads",
        "characteristics": {
            "interests": ["remote work", "travel", "freelancing"],
            "communicationPreferences": ["travel blogs", "digital nomad networks"],
            "ageRange": "20-45",
            "purchasePatterns": ["invests in tech gear and subscribes to travel services"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 297,
        "name": "Textile Designers",
        "characteristics": {
            "interests": ["fashion design", "fabric development", "creative arts"],
            "communicationPreferences": ["design magazines", "fashion industry newsletters"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in textile tools and attends design workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 298,
        "name": "Ornithologists",
        "characteristics": {
            "interests": ["birdwatching", "avian research", "wildlife conservation"],
            "communicationPreferences": ["wildlife journals", "ornithology groups"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys birdwatching gear and subscribes to scientific publications"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 299,
        "name": "Voiceover Artists",
        "characteristics": {
            "interests": ["audio production", "acting", "advertising"],
            "communicationPreferences": ["voice acting forums", "audio production newsletters"],
            "ageRange": "20-55",
            "purchasePatterns": ["invests in audio equipment and attends acting workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 300,
        "name": "Eco-Architects",
        "characteristics": {
            "interests": ["sustainable design", "green building", "environmental impact"],
            "communicationPreferences": ["architecture journals", "sustainability workshops"],
            "ageRange": "30-65",
            "purchasePatterns": ["invests in eco-friendly materials and attends design conferences"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 301,
        "name": "Stay-at-Home Dads",
        "characteristics": {
            "interests": ["parenting", "home management", "child development"],
            "communicationPreferences": ["parenting blogs", "dad groups"],
            "ageRange": "30-50",
            "purchasePatterns": ["buys household products and educational toys"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 302,
        "name": "Cybersecurity Analysts",
        "characteristics": {
            "interests": ["network security", "data protection", "ethical hacking"],
            "communicationPreferences": ["cybersecurity forums", "industry reports"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in security software and attends industry conferences"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 303,
        "name": "Minimalist Decorators",
        "characteristics": {
            "interests": ["interior design", "minimalism", "space organization"],
            "communicationPreferences": ["design blogs", "home decor magazines"],
            "ageRange": "25-55",
            "purchasePatterns": ["buys minimalist furniture and decor items"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 304,
        "name": "Pet Sitters",
        "characteristics": {
            "interests": ["animal care", "pet services", "small business"],
            "communicationPreferences": ["pet care forums", "local community groups"],
            "ageRange": "20-50",
            "purchasePatterns": ["buys pet supplies and grooming tools"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 305,
        "name": "Retro Gamers",
        "characteristics": {
            "interests": ["vintage video games", "gaming culture", "collectibles"],
            "communicationPreferences": ["gaming forums", "retro gaming events"],
            "ageRange": "25-55",
            "purchasePatterns": ["buys vintage consoles and games, attends gaming conventions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 306,
        "name": "Sustainability Consultants",
        "characteristics": {
            "interests": ["sustainable practices", "green technology", "environmental impact"],
            "communicationPreferences": ["industry newsletters", "sustainability conferences"],
            "ageRange": "30-65",
            "purchasePatterns": ["invests in eco-friendly products and services, advises companies"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 307,
        "name": "Professional Chess Players",
        "characteristics": {
            "interests": ["chess strategy", "competitive play", "game theory"],
            "communicationPreferences": ["chess forums", "tournament updates"],
            "ageRange": "15-70",
            "purchasePatterns": ["buys chess sets and study materials, participates in tournaments"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 308,
        "name": "Personal Finance Bloggers",
        "characteristics": {
            "interests": ["financial independence", "budgeting", "investing"],
            "communicationPreferences": ["finance blogs", "investment newsletters"],
            "ageRange": "25-55",
            "purchasePatterns": ["subscribes to financial tools and investment platforms"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 309,
        "name": "Organic Skincare Enthusiasts",
        "characteristics": {
            "interests": ["natural beauty", "skincare products", "wellness"],
            "communicationPreferences": ["beauty blogs", "wellness magazines"],
            "ageRange": "20-45",
            "purchasePatterns": ["buys organic skincare products and follows beauty trends"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 310,
        "name": "Academic Researchers",
        "characteristics": {
            "interests": ["scientific research", "publishing", "higher education"],
            "communicationPreferences": ["academic journals", "research conferences"],
            "ageRange": "30-65",
            "purchasePatterns": ["invests in research tools and academic subscriptions"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 311,
        "name": "Eco-Warriors",
        "characteristics": {
            "interests": ["environmental activism", "sustainability", "climate action"],
            "communicationPreferences": ["environmental news", "activist networks"],
            "ageRange": "18-45",
            "purchasePatterns": ["supports eco-friendly products and participates in protests"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 312,
        "name": "Aquaponics Farmers",
        "characteristics": {
            "interests": ["sustainable farming", "aquaculture", "hydroponics"],
            "communicationPreferences": ["agriculture forums", "sustainability workshops"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys farming equipment and attends agricultural expos"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 313,
        "name": "Game Designers",
        "characteristics": {
            "interests": ["game development", "storytelling", "interactive media"],
            "communicationPreferences": ["game design blogs", "industry conferences"],
            "ageRange": "20-50",
            "purchasePatterns": ["buys design software and development tools, participates in game jams"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 314,
        "name": "Mountain Bikers",
        "characteristics": {
            "interests": ["outdoor sports", "adventure travel", "cycling"],
            "communicationPreferences": ["biking forums", "sporting goods stores"],
            "ageRange": "20-55",
            "purchasePatterns": ["buys bikes and gear, attends biking events"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 315,
        "name": "Scuba Divers",
        "characteristics": {
            "interests": ["marine life", "underwater exploration", "ocean conservation"],
            "communicationPreferences": ["diving magazines", "marine conservation groups"],
            "ageRange": "20-60",
            "purchasePatterns": ["buys diving gear and books trips to diving locations"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 316,
        "name": "Fitness Influencers",
        "characteristics": {
            "interests": ["health and wellness", "social media", "fitness coaching"],
            "communicationPreferences": ["social media platforms", "fitness brand partnerships"],
            "ageRange": "18-35",
            "purchasePatterns": ["buys fitness gear and supplements, promotes brands online"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 317,
        "name": "Film Critics",
        "characteristics": {
            "interests": ["cinema", "film analysis", "media criticism"],
            "communicationPreferences": ["film magazines", "critic networks"],
            "ageRange": "25-70",
            "purchasePatterns": ["attends film festivals and subscribes to movie databases"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 318,
        "name": "Vegan Athletes",
        "characteristics": {
            "interests": ["plant-based nutrition", "sports performance", "ethical living"],
            "communicationPreferences": ["vegan fitness blogs", "athlete networks"],
            "ageRange": "20-40",
            "purchasePatterns": ["buys vegan supplements and athletic gear, participates in competitions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 319,
        "name": "Sound Designers",
        "characteristics": {
            "interests": ["audio production", "sound effects", "film and game design"],
            "communicationPreferences": ["audio engineering forums", "industry newsletters"],
            "ageRange": "25-50",
            "purchasePatterns": ["invests in audio equipment and software, works on creative projects"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 320,
        "name": "Startup Mentors",
        "characteristics": {
            "interests": ["entrepreneurship", "business coaching", "innovation"],
            "communicationPreferences": ["startup networks", "business news"],
            "ageRange": "35-65",
            "purchasePatterns": ["invests in business tools and attends entrepreneurial events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 321,
        "name": "Art Auctioneers",
        "characteristics": {
            "interests": ["fine art", "auctions", "collectibles"],
            "communicationPreferences": ["art market reports", "auction house newsletters"],
            "ageRange": "30-70",
            "purchasePatterns": ["participates in auctions and buys art-related literature"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 322,
        "name": "Wildlife Rehabilitators",
        "characteristics": {
            "interests": ["animal care", "conservation", "wildlife rescue"],
            "communicationPreferences": ["animal welfare newsletters", "conservation networks"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys medical supplies and participates in wildlife rescue operations"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 323,
        "name": "Blockchain Entrepreneurs",
        "characteristics": {
            "interests": ["cryptocurrency", "blockchain technology", "fintech"],
            "communicationPreferences": ["tech industry news", "blockchain forums"],
            "ageRange": "25-50",
            "purchasePatterns": ["invests in crypto and blockchain startups, attends industry events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 324,
        "name": "Sculptors",
        "characteristics": {
            "interests": ["visual arts", "3D design", "exhibitions"],
            "communicationPreferences": ["art magazines", "sculpture forums"],
            "ageRange": "25-65",
            "purchasePatterns": ["buys sculpting tools and materials, participates in art shows"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 325,
        "name": "Patent Lawyers",
        "characteristics": {
            "interests": ["intellectual property", "legal practice", "technology law"],
            "communicationPreferences": ["legal journals", "industry reports"],
            "ageRange": "30-60",
            "purchasePatterns": ["subscribes to legal databases and attends legal conferences"],
            "incomeLevel": "High"
        }
    },
        {
            "id": 326,
            "name": "Aerospace Historians",
            "characteristics": {
                "interests": ["aviation history", "space exploration", "technological advancements"],
                "communicationPreferences": ["historical journals", "aerospace conferences"],
                "ageRange": "30-70",
                "purchasePatterns": ["buys historical books and archival materials, attends space museums"],
                "incomeLevel": "Moderate to high"
            }
        },
        {
        "id": 327,
        "name": "Space Engineers",
        "characteristics": {
            "interests": ["aerospace", "robotics", "space exploration"],
            "communicationPreferences": ["engineering magazines", "space industry updates"],
            "ageRange": "25-55",
            "purchasePatterns": ["invests in technical tools and participates in aerospace projects"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 328,
        "name": "Classical Dancers",
        "characteristics": {
            "interests": ["ballet", "modern dance", "choreography"],
            "communicationPreferences": ["dance schools", "performance networks"],
            "ageRange": "18-45",
            "purchasePatterns": ["buys dancewear and attends dance workshops"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 329,
        "name": "Renaissance Fair Enthusiasts",
        "characteristics": {
            "interests": ["historical reenactments", "costuming", "medieval culture"],
            "communicationPreferences": ["historical societies", "fair groups"],
            "ageRange": "18-65",
            "purchasePatterns": ["buys period costumes and attends Renaissance fairs"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 330,
        "name": "Robotics Educators",
        "characteristics": {
            "interests": ["robotics", "STEM education", "programming"],
            "communicationPreferences": ["education journals", "robotics forums"],
            "ageRange": "25-60",
            "purchasePatterns": ["buys educational kits and participates in robotics competitions"],
            "incomeLevel": "Moderate"
        }
    },
        {
            "id": 331,
            "name": "Toy Collectors",
            "characteristics": {
                "interests": ["vintage toys", "action figures", "collectibles"],
                "communicationPreferences": ["collector forums", "toy conventions"],
                "ageRange": "25-60",
                "purchasePatterns": ["buys rare toys and attends collector fairs"],
                "incomeLevel": "Moderate"
            }
        },
        {
            "id": 332,
            "name": "Young Aspiring Coders",
            "characteristics": {
                "interests": ["coding", "video games", "robotics"],
                "communicationPreferences": ["coding apps for kids", "educational YouTube channels"],
                "ageRange": "8-14",
                "purchasePatterns": ["buys coding kits and attends coding camps"],
                "incomeLevel": "Dependent on parents"
            }
        },
        {
            "id": 333,
            "name": "Online Course Creators",
            "characteristics": {
                "interests": ["education", "digital content", "online teaching"],
                "communicationPreferences": ["edtech blogs", "content creator forums"],
                "ageRange": "25-45",
                "purchasePatterns": ["buys video equipment and course creation software"],
                "incomeLevel": "Moderate to high"
            }
        },
        {
            "id": 334,
            "name": "Meme Traders",
            "characteristics": {
                "interests": ["internet culture", "digital art", "memes"],
                "communicationPreferences": ["social media platforms", "meme communities"],
                "ageRange": "18-35",
                "purchasePatterns": ["buys meme-related NFTs and follows digital trends"],
                "incomeLevel": "Low to moderate"
            }
        },
        {
            "id": 335,
            "name": "Street Photographers",
            "characteristics": {
                "interests": ["urban exploration", "documentary photography", "street life"],
                "communicationPreferences": ["photography forums", "exhibition announcements"],
                "ageRange": "20-45",
                "purchasePatterns": ["buys camera gear and attends photo walks"],
                "incomeLevel": "Moderate"
            }
        },
         {
            "id": 336,
            "name": "Social Media Managers",
            "characteristics": {
                "interests": ["digital marketing", "brand management", "content creation"],
                "communicationPreferences": ["marketing blogs", "social media groups"],
                "ageRange": "25-40",
                "purchasePatterns": ["buys social media management tools and follows marketing trends"],
                "incomeLevel": "Moderate"
            }
        },
        {
            "id": 337,
            "name": "Remote Learning Enthusiasts",
            "characteristics": {
                "interests": ["online education", "e-learning platforms", "self-improvement"],
                "communicationPreferences": ["educational blogs", "online course platforms"],
                "ageRange": "25-55",
                "purchasePatterns": ["buys online courses and educational tools"],
                "incomeLevel": "Moderate"
            }
        },
         {
            "id": 338,
            "name": "Bicycle Commuters",
            "characteristics": {
                "interests": ["cycling", "urban commuting", "sustainable transportation"],
                "communicationPreferences": ["cycling blogs", "bike advocacy groups"],
                "ageRange": "20-50",
                "purchasePatterns": ["buys bikes and gear, follows urban planning trends"],
                "incomeLevel": "Moderate"
            }
        },
    {
        "id": 339,
        "name": "Basketball Enthusiast",
        "characteristics": {
            "interests": ["March Madness", "basketball analytics", "player stats"],
            "communicationPreferences": ["basketball blogs", "sports networks", "sports apps"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys sports memorabilia, game tickets, and streaming services"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 340,
        "name": "Student Athlete",
        "characteristics": {
            "interests": ["athletic scholarships", "team sports", "fitness"],
            "communicationPreferences": ["team meetings", "training apps", "social media"],
            "ageRange": "17-24",
            "purchasePatterns": ["buys training gear, supplements, and sportswear"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 341,
        "name": "Alumni Sports Booster",
        "characteristics": {
            "interests": ["supporting teams", "alumni events", "donating to athletics"],
            "communicationPreferences": ["alumni newsletters", "sports club updates", "social media"],
            "ageRange": "30-65",
            "purchasePatterns": ["donates to athletics, buys season tickets, and attends alumni events"],
            "incomeLevel": "High"
        }
    },
    {
        "id": 342,
        "name": "Football Tailgater",
        "characteristics": {
            "interests": ["game day parties", "team spirit", "barbecue"],
            "communicationPreferences": ["fan groups", "sports channels", "social media"],
            "ageRange": "21-50",
            "purchasePatterns": ["buys grilling equipment, team gear, and tailgating supplies"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 343,
        "name": "Intramural Athlete",
        "characteristics": {
            "interests": ["recreational sports", "fitness", "team building"],
            "communicationPreferences": ["campus events", "social media", "group chats"],
            "ageRange": "18-30",
            "purchasePatterns": ["buys sports equipment, gym memberships, and team uniforms"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 344,
        "name": "Soccer Supporter",
        "characteristics": {
            "interests": ["soccer leagues", "student-athletes", "match day rituals"],
            "communicationPreferences": ["sports networks", "fan pages", "team newsletters"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys soccer jerseys, match tickets, and sports streaming services"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 345,
        "name": "E-Sports Player",
        "characteristics": {
            "interests": ["competitive gaming", "streaming", "e-sports tournaments"],
            "communicationPreferences": ["gaming platforms", "social media", "team chat apps"],
            "ageRange": "18-30",
            "purchasePatterns": ["buys gaming peripherals, team jerseys, and tournament entries"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 346,
        "name": "Baseball Aficionado",
        "characteristics": {
            "interests": ["world series", "team statistics", "player development"],
            "communicationPreferences": ["sports blogs", "team radio", "sports apps"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys baseball gear, season tickets, and team merchandise"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 347,
        "name": "Gymnastics Enthusiast",
        "characteristics": {
            "interests": ["gymnastics competitions", "athlete training", "sport aesthetics"],
            "communicationPreferences": ["sports networks", "team websites", "social media"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys event tickets, team apparel, and sports magazines"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 348,
        "name": "Sports Journalist",
        "characteristics": {
            "interests": ["sports reporting", "game analysis", "interviews"],
            "communicationPreferences": ["campus newspapers", "sports blogs", "social media"],
            "ageRange": "18-24",
            "purchasePatterns": ["buys recording equipment, sports apparel, and journalism tools"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 349,
        "name": "Track and Field Athlete",
        "characteristics": {
            "interests": ["athletic training", "competitions", "running"],
            "communicationPreferences": ["training apps", "social media", "team meetings"],
            "ageRange": "18-25",
            "purchasePatterns": ["buys running shoes, athletic gear, and supplements"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 350,
        "name": "Cheerleading Squad Member",
        "characteristics": {
            "interests": ["team spirit", "cheer routines", "fitness"],
            "communicationPreferences": ["team chats", "social media", "sports events"],
            "ageRange": "15-25",
            "purchasePatterns": ["buys cheer uniforms, training equipment, and team merchandise"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 351,
        "name": "Sports Photographer",
        "characteristics": {
            "interests": ["sports photography", "action shots", "event coverage"],
            "communicationPreferences": ["photography forums", "social media", "sports websites"],
            "ageRange": "18-30",
            "purchasePatterns": ["buys camera equipment, editing software, and event passes"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 352,
        "name": "Hockey Fan",
        "characteristics": {
            "interests": ["hockey", "team rivalries", "game day traditions"],
            "communicationPreferences": ["sports networks", "team newsletters", "fan forums"],
            "ageRange": "18-45",
            "purchasePatterns": ["buys team jerseys, tickets, and game day gear"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 353,
        "name": "Sports Club President",
        "characteristics": {
            "interests": ["leadership", "sports management", "team building"],
            "communicationPreferences": ["club meetings", "email newsletters", "social media"],
            "ageRange": "18-24",
            "purchasePatterns": ["buys club uniforms, event supplies, and promotional materials"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 354,
        "name": "Rugby Enthusiast",
        "characteristics": {
            "interests": ["rugby matches", "team camaraderie", "physical fitness"],
            "communicationPreferences": ["sports apps", "team newsletters", "social media"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys team gear, match tickets, and sports equipment"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 355,
        "name": "Sports Event Organizer",
        "characteristics": {
            "interests": ["event planning", "sports management", "team coordination"],
            "communicationPreferences": ["email", "event apps", "social media"],
            "ageRange": "18-25",
            "purchasePatterns": ["buys event supplies, promotional materials, and team gear"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 356,
        "name": "Tennis Enthusiast",
        "characteristics": {
            "interests": ["tennis", "tournaments", "player rankings"],
            "communicationPreferences": ["sports websites", "team updates", "tennis forums"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys tennis gear, event tickets, and team merchandise"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 357,
        "name": "Wrestling Fan",
        "characteristics": {
            "interests": ["wrestling matches", "team rivalries", "athlete training"],
            "communicationPreferences": ["sports apps", "team newsletters", "social media"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys team gear, event tickets, and sports memorabilia"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 358,
        "name": "Student Body Leader",
        "characteristics": {
            "interests": ["leadership", "student government", "event planning"],
            "communicationPreferences": ["email", "campus newsletters", "social media"],
            "ageRange": "18-24",
            "purchasePatterns": ["buys promotional materials, event supplies, and team gear"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 359,
        "name": "Rowing Enthusiast",
        "characteristics": {
            "interests": ["crew teams", "regattas", "team training"],
            "communicationPreferences": ["team updates", "sports blogs", "social media"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys rowing gear, event tickets, and team merchandise"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 360,
        "name": "Sports Recruiter",
        "characteristics": {
            "interests": ["athlete scouting", "recruitment", "sports"],
            "communicationPreferences": ["email", "sports apps", "recruiting networks"],
            "ageRange": "25-50",
            "purchasePatterns": ["buys travel gear, recruiting software, and sports apparel"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 361,
        "name": "Sports Historian",
        "characteristics": {
            "interests": ["sports history", "team rivalries", "athlete biographies"],
            "communicationPreferences": ["sports books", "history blogs", "documentaries"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys sports memorabilia, books, and subscriptions to sports channels"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 362,
        "name": "Volleyball Supporter",
        "characteristics": {
            "interests": ["volleyball", "team dynamics", "tournament play"],
            "communicationPreferences": ["sports apps", "team newsletters", "social media"],
            "ageRange": "18-45",
            "purchasePatterns": ["buys team jerseys, event tickets, and volleyball gear"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 363,
        "name": "Sports Psychologist",
        "characteristics": {
            "interests": ["mental health in sports", "athlete performance", "team dynamics"],
            "communicationPreferences": ["psychology journals", "sports magazines", "networking events"],
            "ageRange": "30-60",
            "purchasePatterns": ["buys books, journals, and training materials related to sports psychology"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 364,
        "name": "Lacrosse Fan",
        "characteristics": {
            "interests": ["lacrosse matches", "team rivalries", "athlete development"],
            "communicationPreferences": ["sports blogs", "team newsletters", "social media"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys lacrosse gear, event tickets, and team merchandise"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 365,
        "name": "Sports Marketing Professional",
        "characteristics": {
            "interests": ["sports branding", "event promotion", "fan engagement"],
            "communicationPreferences": ["marketing blogs", "sports industry news", "social media"],
            "ageRange": "25-50",
            "purchasePatterns": ["buys marketing tools, promotional materials, and fan gear"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 366,
        "name": "Water Polo Enthusiast",
        "characteristics": {
            "interests": ["water polo matches", "team training", "sports tactics"],
            "communicationPreferences": ["sports networks", "team updates", "fan forums"],
            "ageRange": "18-40",
            "purchasePatterns": ["buys team gear, event tickets, and sports equipment"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 367,
        "name": "Sports Statistician",
        "characteristics": {
            "interests": ["data analysis", "team performance", "player stats"],
            "communicationPreferences": ["sports data apps", "statistical journals", "social media"],
            "ageRange": "25-45",
            "purchasePatterns": ["buys data analysis software, sports subscriptions, and team apparel"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 368,
        "name": "Alumni Association Member",
        "characteristics": {
            "interests": ["supporting athletics", "alumni events", "team reunions"],
            "communicationPreferences": ["alumni newsletters", "team updates", "social media"],
            "ageRange": "35-70",
            "purchasePatterns": ["donates to athletics, buys event tickets, and participates in alumni activities"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 369,
        "name": "Sailing Enthusiast",
        "characteristics": {
            "interests": ["sailing regattas", "team tactics", "water sports"],
            "communicationPreferences": ["sports magazines", "team newsletters", "fan forums"],
            "ageRange": "18-50",
            "purchasePatterns": ["buys sailing gear, event tickets, and team apparel"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 370,
        "name": "Sports Nutritionist",
        "characteristics": {
            "interests": ["sports nutrition", "athlete diet plans", "performance enhancement"],
            "communicationPreferences": ["nutrition journals", "sports magazines", "networking events"],
            "ageRange": "30-55",
            "purchasePatterns": ["buys nutrition tools, supplements, and educational materials"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 371,
        "name": "Ultimate Frisbee Player",
        "characteristics": {
            "interests": ["team sports", "competitive play", "outdoor activities"],
            "communicationPreferences": ["team meetings", "social media", "sports apps"],
            "ageRange": "18-30",
            "purchasePatterns": ["buys sports gear, team uniforms, and event tickets"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 372,
        "name": "Golf Enthusiast",
        "characteristics": {
            "interests": ["golf tournaments", "player development", "course strategy"],
            "communicationPreferences": ["golf magazines", "team updates", "sports networks"],
            "ageRange": "25-55",
            "purchasePatterns": ["buys golf gear, event tickets, and team merchandise"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 373,
        "name": "Sports Broadcaster",
        "characteristics": {
            "interests": ["sports journalism", "broadcasting", "game analysis"],
            "communicationPreferences": ["sports networks", "broadcasting forums", "social media"],
            "ageRange": "25-50",
            "purchasePatterns": ["buys recording equipment, sports subscriptions, and professional gear"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 374,
        "name": "Sports Memorabilia Collector",
        "characteristics": {
            "interests": ["sports history", "collectibles", "team nostalgia"],
            "communicationPreferences": ["collector forums", "sports magazines", "auction sites"],
            "ageRange": "30-70",
            "purchasePatterns": ["buys memorabilia, attends auctions, and subscribes to sports channels"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 375,
        "name": "Boxing Enthusiast",
        "characteristics": {
            "interests": ["boxing matches", "athlete training", "team rivalries"],
            "communicationPreferences": ["sports apps", "team newsletters", "fan forums"],
            "ageRange": "18-45",
            "purchasePatterns": ["buys team gear, event tickets, and sports equipment"],
            "incomeLevel": "Moderate"
        }
    },
        {
            "id": 376,
            "name": "Home Schooling Parent",
            "characteristics": {
                "interests": ["education", "child development", "alternative schooling"],
                "communicationPreferences": ["parenting blogs", "education forums", "social media groups"],
                "ageRange": "30-50",
                "purchasePatterns": ["buys educational materials, books, and online courses"],
                "incomeLevel": "Moderate"
            }
        },
    {
        "id": 377,
        "name": "The Greatest Generation",
        "characteristics": {
            "interests": ["traditional values", "community service", "war-time history"],
            "communicationPreferences": ["newspapers", "radio", "community meetings"],
            "ageRange": "95+",
            "purchasePatterns": ["buys war bonds, legacy real estate, and conservative investments"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 378,
        "name": "The Silent Generation",
        "characteristics": {
            "interests": ["family values", "retirement planning", "traditional hobbies"],
            "communicationPreferences": ["TV news", "newspapers", "community bulletins"],
            "ageRange": "77-95",
            "purchasePatterns": ["invests in healthcare, retirement communities, and family gifts"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 379,
        "name": "Baby Boomers",
        "characteristics": {
            "interests": ["travel", "golf", "investment planning"],
            "communicationPreferences": ["newspapers", "financial news", "social clubs"],
            "ageRange": "58-77",
            "purchasePatterns": ["buys travel packages, investment products, and leisure activities"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 380,
        "name": "Generation X",
        "characteristics": {
            "interests": ["career advancement", "technology", "family"],
            "communicationPreferences": ["email", "social media", "news websites"],
            "ageRange": "43-58",
            "purchasePatterns": ["buys technology, real estate, and family-oriented products"],
            "incomeLevel": "Moderate to high"
        }
    },
    {
        "id": 381,
        "name": "Millennials",
        "characteristics": {
            "interests": ["technology", "travel", "entrepreneurship"],
            "communicationPreferences": ["social media", "blogs", "podcasts"],
            "ageRange": "27-43",
            "purchasePatterns": ["buys tech gadgets, travel experiences, and online subscriptions"],
            "incomeLevel": "Moderate"
        }
    },
    {
        "id": 382,
        "name": "Generation Z",
        "characteristics": {
            "interests": ["social media", "technology", "entrepreneurship"],
            "communicationPreferences": ["social media", "YouTube", "mobile apps"],
            "ageRange": "12-27",
            "purchasePatterns": ["buys tech gadgets, fashion, and entertainment subscriptions"],
            "incomeLevel": "Low to moderate"
        }
    },
    {
        "id": 383,
        "name": "Generation Alpha",
        "characteristics": {
            "interests": ["interactive learning", "technology", "gaming"],
            "communicationPreferences": ["educational apps", "YouTube Kids", "smart devices"],
            "ageRange": "Under 12",
            "purchasePatterns": ["buys educational toys, games, and tech gadgets"],
            "incomeLevel": "Dependent on parents"
        }
    }
]
}
const namesArray = personaBuckets.personaBuckets.map(bucket => bucket.name);
console.log("namesArray: ", namesArray)

export default personaBuckets
