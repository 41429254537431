// LatestDriverCheckins.js
import React, { useState } from 'react'
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Typography,
  Chip,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import { blue, green, orange, red } from '@mui/material/colors'
import RefreshButton from '../RefreshButton/RefreshButton'
import {
  prettyDateTime,
  toCapitalizeFromUnderScore,
  truncate,
} from '../../lib/helpers/helpers'
import { HowToReg, MoreVert } from '@mui/icons-material'
import useDriverCheckins from '../../lib/hooks/useDriverCheckins'

// Function to get the icon color based on status
const getIconColor = (status) => {
  switch (status) {
    case 'In Transit':
      return green[500]
    case 'Delayed':
      return orange[500]
    case 'Delivered':
      return blue[700]
    case 'Cancelled':
      return red[500]
    default:
      return green[500]
  }
}

const LatestDriverCheckins = () => {
  const { data, isLoading, isRefetching, refetch } = useDriverCheckins()
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const handleMenuOpen = (event, id) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Driver Check-Ins
          <RefreshButton
            onRefresh={refetch}
            isLoading={isLoading || isRefetching}
          />
        </Typography>
        <List style={{ maxHeight: 495, overflowY: 'auto' }} dense={true}>
          {data?.length === 0 ? (
            <ListItem>
              <ListItemText primary="No Check-Ins Yet" />
            </ListItem>
          ) : (
            data?.map((item, index) =>
              item?.order?.type === 'shipment' ? (
                <ListItem key={`shipment-${item?.id}`}>
                  <ListItemIcon>
                    <HowToReg
                      sx={{
                        color: getIconColor(
                          toCapitalizeFromUnderScore(item?.status)
                        ),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Tooltip title={item?.vehicle_make_model}>
                        {toCapitalizeFromUnderScore(item?.status) || 'N/A'} -{' '}
                        {truncate(item?.order?.vehicle_make_model, 22) || 'N/A'}
                      </Tooltip>
                    }
                    secondary={
                      <>
                        <div>
                          {item?.notes && (
                            <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                              {`"${item.notes}"`}
                              <br />
                            </div>
                          )}
                          Driver:{' '}
                          <b>
                            {item?.order?.driver &&
                            item.order?.driver.length > 0
                              ? item.order?.driver.map((driver, index) => (
                                  <React.Fragment key={index}>
                                    {driver.name || 'N/A'}
                                    <br />
                                  </React.Fragment>
                                ))
                              : 'N/A'}
                          </b>
                        </div>
                        <div>
                          {item.created_at !== ''
                            ? prettyDateTime(item?.created_at)
                            : 'N/A'}
                        </div>
                        {
                          <Tooltip title={item.id}>
                            Order #{item?.id ? truncate(item.id, 15) : 'N/A'}
                          </Tooltip>
                        }
                      </>
                    }
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      title={'Manage'}
                      onClick={(event) => handleMenuOpen(event, item.id)}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem disabled={true}>
                        <ListItem fullWidth>
                          <ListItemIcon>
                            <HowToReg />
                          </ListItemIcon>
                          <ListItemText primary={'View Details'} />
                        </ListItem>
                      </MenuItem>
                    </Menu>
                  </ListItemSecondaryAction>
                </ListItem>
              ) : (
                <ListItem
                  key={`driver-${item.driver?.id}`}
                  alignItems="flex-start"
                >
                  <ListItemAvatar>&nbsp;</ListItemAvatar>
                  <ListItemText
                    primary={<>{item.driver?.name || 'N/A'}</>}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {item.driver?.carrier || 'N/A'}
                        </Typography>
                        <br />
                        <Chip
                          icon={<StarIcon />}
                          label={`${item.driver?.average_rating} (${item.driver?.total_reviews} reviews)`}
                          size="small"
                          variant="outlined"
                        />
                      </>
                    }
                  />
                </ListItem>
              )
            )
          )}
        </List>
      </CardContent>
    </Card>
  )
}

export default LatestDriverCheckins
