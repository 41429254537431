// Screen.tsx
import { ReactChild, useEffect } from 'react'
import {Box, Breadcrumbs, Link, Typography} from '@mui/material'
import cn from 'classnames'
import makeStyles from '@mui/styles/makeStyles'
import Header from '../Header'
import Sidebar from "../../components/Sidebar/Sidebar";
import DarkModeToggle from "../DarkModeToggle";

export interface Props {
  title?: string
  permission?: string
  showHeader?: boolean
  showFooter?: boolean
  showSidebarNav?: boolean
  showDarkModeToggle?: boolean
  sidebarConfig?: any
  showBreadcrumbs?: any //[{name: string, path: string}];
  showTopNav?: boolean
  showAPISidebarNav?: boolean
  elevated?: boolean
  showPaddingTop?: boolean
  noBackground?: boolean
  className?: string
  children?: ReactChild
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      color: 'inherit',
    },
    '& a:visited': {
      color: 'inherit',
    },
    '& a:hover': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
  crumbs: {
    marginTop: -50,
  },
  screenPaddingOn: {
    padding: 16,
    '@media print': {
      padding: 0,
    },
  },
  screenPaddingOff: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  elevatedBox: {
    // backgroundColor: '#ff0044',
    // paddingTop: theme.spacing(5),
  },
  childrenBox: {
    minHeight: '100vh', // @ts-ignore
    //    backgroundImage: `linear-gradient(167deg, ${theme.palette.backgrounds.background} 0%, ${theme.palette.backgrounds.login} 100%)`
    // backgroundImage: `linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)`
    // background: `linear-gradient(-180deg, #BCC5CE 0%, #929EAD 98%), radial-gradient(at top left, rgba(255,255,255,0.30) 0%, rgba(0,0,0,0.30) 100%); background-blend-mode: screen`
    // backgroundColor: theme.palette.backgrounds.background,
  },
  childrenBoxDark: {
    minHeight: '100vh', // @ts-ignore
    //  backgroundColor: theme.palette.backgrounds.background,
    // backgroundImage: `linear-gradient(60deg, #29323c 0%, #485563 100%)`
    //background: `linear-gradient(190deg, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%); background-blend-mode: multiply`,
    // backgroundImage: `linear-gradient(60deg, #29323c 0%, #485563 100%)`,
  },
  childrenBoxNoBackground: {
    marginLeft: '0vh',
    minHeight: '100vh', // @ts-ignore
    minWidth: '-webkit-fill-available', // @ts-ignore
    background: 'transparent',
  },
  screenWithSidebar: {
    height: '100vh',
   // marginLeft: 230,
  },
  screenWithAPISidebar: {
    height: '100vh',
    marginLeft: 250,
    '@media print': {
      marginLeft: 0,
    },
  },
  no_permission: {
    padding: 100,
    margin: 'auto',
    marginTop: 200,
    textAlign: 'center',
  },
}))

const Screen = ({
  title,
  permission = undefined,
  showHeader = false,
  showFooter = false,
  showSidebarNav = false,
  showBreadcrumbs = false,
  showDarkModeToggle = false,
  showAPISidebarNav,
  showTopNav,
  children,
  className,
  elevated = true,
  showPaddingTop = true,
  noBackground,
                  sidebarConfig = null,
}: Props) => {
  const classes = useStyles()
  //const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
      <Box
          style={{
            display: 'flex',    // Flex display to align sidebar and content side by side
            height: '100vh',    // Full viewport height
            overflow: 'hidden', // Hide overflow
          }}
      >

      {showHeader && <Header title={title + ' | Umbrella Index'} />}
      {showSidebarNav && <Sidebar title={title} sidebarConfig={sidebarConfig} />}
      {/*{showTopNav && <TopBarNavigation />}*/}
        <Box
            style={{
              flexGrow: 1,       // Content box takes remaining space
              overflowY: 'auto', // Allows vertical scrolling
              paddingBottom: 150 // Extra padding at the bottom
            }}
        >
          {showDarkModeToggle && <DarkModeToggle/>}
        {showBreadcrumbs && (
            <div className={classes.crumbs}>
              <Breadcrumbs aria-label="breadcrumb">
                {showBreadcrumbs.map((breadcrumb: any, index: any) => {
                  const isLast = index === showBreadcrumbs.length - 1;
                  return isLast ? (
                      <Typography color="textPrimary" key={index}>{breadcrumb.label}</Typography>
                  ) : (
                      <Link key={index} href={breadcrumb.href}>
                        {breadcrumb.label}
                      </Link>
                  );
                })}
              </Breadcrumbs>
            </div>
        )}

        {children}
      </Box>
      {/*{showFooter && <Footer />}*/}
    </Box>
  )
}

export default Screen
