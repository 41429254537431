import React, {useCallback, useContext, useState} from 'react';
import {
   Box,
   IconButton,
   InputAdornment,
   TextField,
   Tooltip,
   Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {FileCopy, Info} from '@mui/icons-material';
import {SearchContext} from "../../lib/contexts";
const useStyles = makeStyles({
   copybox: {
      margin:'auto',
      marginTop:20,
   },
   title: {
      fontSize: 14,
   },
   infoIcon:{
      float:'right',
      fontSize: 17,
      marginBottom: -3
   },
   textarea:{
      margin:'10px auto auto auto'
   }
});

const CopySettingsBox = ({label}) => {
   const classes = useStyles();
   const [copied, setCopied] = useState(false);
   const { state: { directLink } } = useContext(SearchContext);
   const copyTextToClipboard = useCallback(async () => {
      if ('clipboard' in navigator) {
         return await navigator.clipboard.writeText(directLink);
      } else {
         return document.execCommand('copy', true, directLink);
      }
   },[directLink]);

   const handleCopy = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard()
         .then(() => {
            // If successful, update the isCopied state value
            setCopied(true);
            setTimeout(() => {
               setCopied(false);
            }, 1500);
         })
         .catch((err) => {
            console.log(err);
         });
   }
   return(
         <Box className={classes.copybox}>
               {!label && <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Copy / Share&nbsp;<Tooltip
                  title={'A direct link to results with filters & theme pre-applied.'}><Info
                  className={classes.infoIcon}/></Tooltip>
               </Typography>}
               {directLink && <TextField
                  label={label ? 'Direct link to results with all settings applied.' : ''}
                  color={'primary'}
                  id="copy_paste"
                  multiline
                  minRows={4}
                  fullWidth={true}
                  defaultValue={directLink}
                  variant="outlined"
                  className={classes.textarea}
                  InputProps={{
                     endAdornment: <InputAdornment position="start"><Tooltip
                        title={copied ? 'Copied!' : 'Add to Clipboard'}><IconButton
                        onClick={handleCopy}><FileCopy/></IconButton></Tooltip></InputAdornment>,
                  }}
                  helperText="Perfect for replacing your Home Page or saving to Favorites."
               />}
         </Box>
)
};
export default CopySettingsBox;
