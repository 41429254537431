import React from 'react'
import Screen from '../../components/Screen/Screen'
import {
  Box,
  Grid,
} from '@mui/material'
import EntityBrowser from "../../components/EntityBrowser";

const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const EntityBrowserScreen = () => {
  return (
    <>
      <Screen title={'Search'}>
        <Box sx={{ transitionStyle, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={transitionStyle}>
                  <EntityBrowser/>
                </Grid>
              </Grid>
        </Box>
      </Screen>
    </>
  )
}

export default EntityBrowserScreen
