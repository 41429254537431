import React, { useCallback } from 'react'
import { Card, CardContent, LinearProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import useMetric from '../../lib/hooks/useMetric'
import useCurrentUser from '../../lib/hooks/useCurrentUser'

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    width: 214,
    height: 100,
    marginRight: 25,
    marginBottom: 25,
    '& h3': {
      fontSize: 28,
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    '& h5': {
      fontSize: 13,
      textAlign: 'center',
      display: 'block',
      fontWeight: 'bold',
      color: '#000', //theme.palette.primary.main
      // color: theme.palette.common.grey['500']
    },
  },
}))
const MetricCard = ({ slug, title, value = 0 }) => {
  const classes = useStyles()
  const { token, userID } = useCurrentUser()
  const { isLoading, isRefetching, data, refetch } = slug
    ? []//useMetric(slug, token, userID)
    : false
  const { title: dataTitle, metric: dataValue } = data ?? ''
  const refreshMetric = useCallback(() => refetch(), [])

  return (
    <Card className={classes.card} onClick={refreshMetric}>
      <CardContent>
        {isLoading || isRefetching ? (
          <LinearProgress style={{ marginTop: 33 }} />
        ) : (
          <>
            <Typography variant={'h3'}>{dataValue ?? value}</Typography>
            <Typography variant={'h5'}>{title}</Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default MetricCard
