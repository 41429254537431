import React from 'react'
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { Warning, CheckCircle, Error, Info } from '@mui/icons-material'
import { green, yellow, red, blue } from '@mui/material/colors'
import RefreshButton from '../RefreshButton/RefreshButton'
import useAlerts from '../../lib/hooks/useAlerts'

const statusIcons = {
  success: <CheckCircle sx={{ color: green[500] }} />,
  warning: <Warning sx={{ color: yellow[800] }} />,
  error: <Error sx={{ color: red[500] }} />,
  info: <Info sx={{ color: blue[500] }} />,
}

const DashboardAlerts = () => {
  const { data, isLoading, isRefetching, refetch } = useAlerts()
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Alerts & Notifications
          <RefreshButton
            onRefresh={refetch}
            isLoading={isLoading || isRefetching}
          />
        </Typography>
        <List style={{ maxHeight: 270, overflowY: 'auto' }} dense={true}>
          {data?.map((alert) => (
            <ListItem key={alert.id}>
              <ListItemIcon>{statusIcons[alert.status]}</ListItemIcon>
              <ListItemText
                primary={alert.message}
                secondary={'#' + alert.id}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

export default DashboardAlerts
