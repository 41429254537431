import React, {useCallback, useState, useMemo, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import {
  Box,
  List, ListItem, ListItemIcon, Avatar, ListItemText,
  TextField, Typography, Divider, ListItemAvatar,
} from '@mui/material';
import {
  Search,
  TurnedIn,
  Person,
  Assignment,
  Email,
  HelpRounded
} from '@mui/icons-material';
import {makeStyles, withStyles} from '@mui/styles';
import useSearchResults from '../../lib/hooks/useSearchResults';
import { ProgressBar } from '../ProgressBar';
import {UserContext} from "../../lib/contexts";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    '& input':{
      borderColor: theme.palette.primary.main,
    },
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(3),
    marginLeft:0,
    '& svg':{
      marginBottom:-6
    }
  },
  addPatientListSmall:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:180,
    height:44,
    margin:'20px auto 0px',
    fontSize:13,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& *':{
      color: theme.palette.primary.contrastText,
      display:'inline',
    }
  },
  addPatientList:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width:214,
    height:50,
    marginTop:-25,
    marginRight:25,
    display:'block',
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  shareSwitch:{
    color: theme.palette.primary.main,
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  listItem:{
    cursor:'pointer'
  },
  listAvatar:{
    fontSize:12,
  },
  listAvatarIcon:{
    marginRight:0,
    '& svg':{
      marginBottom:0,
      marginRight:0,
    },
  }
}));

const AdminGreenSwitch = withStyles(theme =>({
  switchBase: {
    color: theme.palette.primary.dark,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);
const AdminGreenTextField = withStyles(theme =>({
  root: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}))(TextField);

const UniversalSearchDialog = ({ size}) => {
  const {
    state: {showingHelp},
    actions: { setShowingSearch, setShowingHelp },
  } = useContext(UserContext);
  const classes = useStyles();
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [query, setQuery] = useState('');
  const [throttleQuery, setThrottleQuery] = useState('');
  const { isLoading, data, refetch } = useSearchResults(throttleQuery);
  const tableData = useMemo(()=>data && !data.error ? data : [],[data]);
  
  const handleToggleOpen = useCallback(() => {
    setShowingHelp(false);
    setShowingSearch(false);
  },[]);
  
  const handleInputChange = useCallback((event) => {
    setQuery(event.target.value);
  },[]);
  
  const handleSearch = useCallback((event) => {
    if(query === throttleQuery){
      refetch()
    }else{
      setThrottleQuery(query)
    }
  },[query]);
  
  return (
    <>
      <Dialog
        minWidth={'md'}
        maxWidth={'md'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="add-patient-list-dialog-title" className={classes.form}
      >
        <DialogTitle id="add-patient-list-dialog-title">{showingHelp ? <><HelpRounded/> Quick Help</> :  <><TurnedIn/> Universal Search</>}</DialogTitle>
        {showingHelp
           ?
           <DialogContent>
             <DialogContentText>
               Here&apos;s our list of Keyboard Shortcuts to help improve your workflow.
               <br/>Most Shortcuts can be triggered with either: Control, Command, or Alt.
                <br/>
                <br/>
               <Divider/>
               <List>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>F</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Opens Universal Search'}/>
                 </ListItem>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>N</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Opens Page\'s Primary Creation Dialog'}/>
                 </ListItem>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>1</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Navigate to Dashboard'}/>
                 </ListItem>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>2</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Navigate to Contact Management'}/>
                 </ListItem>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>3</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Navigate to Contact List Management'}/>
                 </ListItem>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>4</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Navigate to Campaign Management'}/>
                 </ListItem>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>5</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Navigate to Campaign Reports'}/>
                 </ListItem>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>6</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Navigate to Email / SMS Templates'}/>
                 </ListItem>
                 <ListItem>
                   <ListItemAvatar>
                     <Avatar>7</Avatar>
                   </ListItemAvatar>
                   <ListItemText primary={'Navigate to Widgets'}/>
                 </ListItem>
               </List>
             </DialogContentText>
        </DialogContent>
           :
           <DialogContent>
          {
            isLoading ?
               <ProgressBar style={{margin: '50px auto 50px'}}/>
               :
               <>
                 <DialogContentText>
                   Quickly search through your Contacts, Organizations, Places, Lists, Automations & Settings.
                 </DialogContentText>
                 <form noValidate onSubmit={handleSearch}>
             
                   <AdminGreenTextField
                      autoFocus={true}
                      value={query}
                      onChange={handleInputChange}
                      variant={'outlined'}
                      fullWidth={true}
                      label={<><Search/> Start Typing...</>} placeholder={'e.g., Sales List'}
                   />
           
           
                 </form>
               </>
          }
          {throttleQuery && tableData && <Box style={{maxHeight: 400, overflowY: 'auto'}}>
      
            <>
              <Typography variant="h6" style={{margin: '10px auto'}}>People:</Typography>
              <List>
                {tableData.contacts && tableData.contacts.map((item, index) => {
                  return <ListItem key={index} className={classes.listItem}
                                   onClick={() => navigate("../contact/" + item.id, {replace: true})}>
                    <ListItemIcon className={classes.listAvatarIcon} onClick={() => {
                    }}>
                      <Avatar className={classes.listAvatar}>{<Person/>}</Avatar>
                    </ListItemIcon>
                    <ListItemText
                       primary={<>{(item.first_name ?? '')} {(item.last_name ?? '')}</>}
                       secondary={<>
                         {(item.email ?? '')}
                         {(item.email !== null && item.phone_number !== null && ' | ')}
                         {(item.phone_number !== null ? item.phone_number : '')}
                         {(item.phone_number !== null && item.username !== '' && ' | ')}
                         {(item.username ?? '')}
                         {(item.organization !== null && item.username !== '' && ' | ')}
                         {(item.organization ?? '')}
                       </>
                       }
                       onClick={() => {
                       }}
                    />
            
                  </ListItem>
                })}
              </List>
            </>
            <>
              <Typography variant="h6" style={{margin: '10px auto'}}>Contact Lists:</Typography>
              <List>
                {tableData.lists && tableData.lists.map((item, index) => {
                  return <ListItem key={index} className={classes.listItem}
                                   onClick={() => navigate("../list/" + item.id, {replace: true})}>
                    <ListItemIcon className={classes.listAvatarIcon} onClick={() => {
                    }}>
                      <Avatar className={classes.listAvatar}>{<Assignment/>}</Avatar>
                    </ListItemIcon>
                    <ListItemText
                       primary={<>{(item.list_name ?? '')}</>}
                       onClick={() => {
                       }}
                    />
            
                  </ListItem>
                })}
              </List>
            </>
            <>
              <Typography variant="h6" style={{margin: '10px auto'}}>Campaigns:</Typography>
              <List>
                {tableData.campaigns && tableData.campaigns.map((item, index) => {
                  return <ListItem key={index} className={classes.listItem}
                                   onClick={() => navigate("../campaign/" + item.id, {replace: true})}>
                    <ListItemIcon className={classes.listAvatarIcon} onClick={() => {
                    }}>
                      <Avatar className={classes.listAvatar}>{<Email/>}</Avatar>
                    </ListItemIcon>
                    <ListItemText
                       primary={<>{(item.name ?? '')}</>}
                       onClick={() => {
                       }}
                    />
            
                  </ListItem>
                })}
              </List>
            </>
    
    
          </Box>}
        </DialogContent>}
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}>
            Close
          </Button>
          {!showingHelp && <Button onClick={handleSearch}
                   className={classes.addButton}
                   disabled={!query}>
            Search
          </Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
export default UniversalSearchDialog;
