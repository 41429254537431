import React, {useMemo, useState, useEffect} from 'react'
import Screen from '../../components/Screen/Screen'
import {
  Box, Tooltip,
  Grid,
} from '@mui/material'
import EntityBrowser from "../../components/EntityBrowser";
import EntityViewer from "../../components/EntityViewer/EntityViewer";
import PersonaBrowser from "../../components/PersonaBrowser/PersonaBrowser";
import ProjectList from "../../components/ProjectList/ProjectList";
import ProjectNav from "../../components/ProjectNav/ProjectNav";
import EntityGraph, {TreeView} from "../../components/EntityGraph/EntityGraph";
import AttachedEntityViewer from "../../components/AttachedEntityViewer/AttachedEntityViewer";
import { csv } from 'd3-fetch';

import {useParams} from "react-router-dom";
import {Favorite, Map, Place, Public} from "@mui/icons-material";
import states from "../../lib/constants/usa_states";
import ContactHeader from "../../components/ContactHeader/ContactHeader";
import {generateUUID, isUUID} from "../../lib/helpers/helpers";
const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}

const loadData = async (type, pageSize, page) => {
    console.log(`Loading data for ${type}`);
    switch (type) {
        case 'cancelled_local':
            try {
                const data = await csv('http://localhost:3003/data_store/cancelled_users.csv');
                const processedData = data.map((item, index) => ({
                    id: generateUUID() || index, // Simple indexing for ID
                    ...item
                }));
                const overrideColumns = [
                    {
                        field: 'Member Number 16 Digits',
                        headerName: 'Member Number',
                        width: 180,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'First Name',
                        headerName: 'First Name',
                        width: 120,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Last Name',
                        headerName: 'Last Name',
                        width: 120,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Individual Email',
                        headerName: 'Email',
                        width: 200,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <a href={`mailto:${params.value}`}>{params.value}</a>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Combined_Telephone',
                        headerName: 'Phone',
                        width: 150,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Standard Address',
                        headerName: 'Address',
                        width: 220,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Begin_AAA_Year',
                        headerName: 'Member Since',
                        width: 130,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Tenure',
                        headerName: 'Member Tenure',
                        width: 130,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Coverage_Level',
                        headerName: 'Coverage Level',
                        width: 150,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Date_of_Last_Purchase',
                        headerName: 'Last Purchase Date',
                        width: 170,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    }
                ];

                return {data: processedData, overrideColumns: overrideColumns};
            } catch (error) {
                console.error("Error loading CSV file:", error);
                // Fallback or error data
                return [];
            }
        case 'pending_local':
            return [];

            try {
                const data = await csv('http://localhost:3003/data_store/pending_users.csv');
                const processedData = data.map((item, index) => ({
                    id: generateUUID() || index, // Simple indexing for ID
                    ...item
                }));
                const overrideColumns = [
                    {
                        field: 'Member Number 16 Digits',
                        headerName: 'Member Number',
                        width: 180,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'First Name',
                        headerName: 'First Name',
                        width: 120,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Last Name',
                        headerName: 'Last Name',
                        width: 120,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Individual Email',
                        headerName: 'Email',
                        width: 200,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <a href={`mailto:${params.value}`}>{params.value}</a>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Combined_Telephone',
                        headerName: 'Phone',
                        width: 150,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Standard Address',
                        headerName: 'Address',
                        width: 220,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Begin_AAA_Year',
                        headerName: 'Member Since',
                        width: 130,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Tenure',
                        headerName: 'Member Tenure',
                        width: 130,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Coverage_Level',
                        headerName: 'Coverage Level',
                        width: 150,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Date_of_Last_Purchase',
                        headerName: 'Last Purchase Date',
                        width: 170,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    }
                ];

                return {data: processedData, overrideColumns: overrideColumns};
            } catch (error) {
                console.error("Error loading CSV file:", error);
                // Fallback or error data
                return [];
            }
        case 'leads':
            try {
                const data = await csv('http://localhost:3003/data_store/combined_leads.csv');
                const processedData = data.map((item, index) => ({
                    id: generateUUID() || index, // Simple indexing for ID
                    ...item
                }));
                const overrideColumns = [
                    {
                        field: 'First Name',
                        headerName: 'First Name',
                        width: 150,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Last Name',
                        headerName: 'Last Name',
                        width: 180,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Title',
                        headerName: 'Title',
                        width: 300,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Company',
                        headerName: 'Company',
                        width: 220,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Email',
                        headerName: 'Email',
                        width: 250,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <a href={`mailto:${params.value}`}>{params.value}</a>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'First Phone',  // Assuming you meant the primary contact number
                        headerName: 'Phone',
                        width: 150,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Person Linkedin Url',
                        headerName: 'LinkedIn',
                        width: 250,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <a href={params.value} target="_blank" rel="noopener noreferrer">{params.value}</a>
                            </Tooltip>
                        )
                    },
                    {
                        field: 'Company Address',
                        headerName: 'Address',
                        width: 300,
                        renderCell: (params) => (
                            <Tooltip title={params.value}>
                                <span>{params.value}</span>
                            </Tooltip>
                        )
                    }
                ];

                return {data: processedData, overrideColumns: overrideColumns};
            } catch (error) {
                console.error("Error loading CSV file:", error);
                // Fallback or error data
                return [];
            }
        
        case 'all':
        case 'active':
        default:
            try {
                const response = await fetch(`http://localhost:5005/api/v1/organization_faqs/query_gcp_data?pageSize=${pageSize}&page=${page}&dataset_id=AAA_Hoosier_Existing_Members&table_name=existing_members`);
                const rawData = await response.json(); // Parsing the response as JSON
                const processedData = rawData.data.map((item, index) => ({
                    id: generateUUID() || index, // Simple indexing for ID, assuming generateUUID is defined
                    ...item
                }));
                const overrideColumns = [
                    {
                        field: 'Member Number 16 Digits',
                        headerName: 'Member Number',
                        width: 180,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'First Name',
                        headerName: 'First Name',
                        width: 120,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Last Name',
                        headerName: 'Last Name',
                        width: 120,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Individual Email',
                        headerName: 'Email',
                        width: 200,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <a href={`mailto:${params.value}`}>{params.value}</a>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Combined_Telephone',
                        headerName: 'Phone',
                        width: 150,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Standard Address',
                        headerName: 'Address',
                        width: 220,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Begin_AAA_Year',
                        headerName: 'Member Since',
                        width: 130,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Tenure',
                        headerName: 'Member Tenure',
                        width: 130,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Coverage_Level',
                        headerName: 'Coverage Level',
                        width: 150,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Date_of_Last_Purchase',
                        headerName: 'Last Purchase Date',
                        width: 170,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    }
                ];
                
                return {data: processedData, total: rawData.total_count, overrideColumns: overrideColumns};
            } catch (error) {
                console.error("Error loading GCP:", error);
                // Fallback or error data
                return [];
            }
        
        case 'cancelled':
            try {
                const response = await fetch(`http://localhost:5005/api/v1/organization_faqs/query_gcp_data?pageSize=${pageSize}&page=${page}&dataset_id=AAA_Hoosier_Cancelled_Members&table_name=cancelled_members`);
                const rawData = await response.json(); // Parsing the response as JSON
                const processedData = rawData.data.map((item, index) => ({
                    id: generateUUID() || index, // Simple indexing for ID, assuming generateUUID is defined
                    ...item
                }));
                const overrideColumns = [
                    {
                        field: 'Member Number 16 Digits',
                        headerName: 'Member Number',
                        width: 180,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'First Name',
                        headerName: 'First Name',
                        width: 120,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Last Name',
                        headerName: 'Last Name',
                        width: 120,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Individual Email',
                        headerName: 'Email',
                        width: 200,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <a href={`mailto:${params.value}`}>{params.value}</a>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Combined_Telephone',
                        headerName: 'Phone',
                        width: 150,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Standard Address',
                        headerName: 'Address',
                        width: 220,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Begin_AAA_Year',
                        headerName: 'Member Since',
                        width: 130,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Tenure',
                        headerName: 'Member Tenure',
                        width: 130,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Coverage_Level',
                        headerName: 'Coverage Level',
                        width: 150,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Date_of_Last_Purchase',
                        headerName: 'Last Purchase Date',
                        width: 170,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    }
                ];
                
                return {data: processedData, total: rawData.total_count, overrideColumns: overrideColumns};
            } catch (error) {
                console.error("Error loading GCP:", error);
                // Fallback or error data
                return [];
            }
        case 'pending':
            try {
                const response = await fetch(`http://localhost:5005/api/v1/organization_faqs/query_gcp_data?pageSize=${pageSize}&page=${page}&dataset_id=AAA_Hoosier_Prospects&table_name=prospects`);
                const rawData = await response.json(); // Parsing the response as JSON
                const processedData = rawData.data.map((item, index) => ({
                    id: generateUUID() || index, // Simple indexing for ID, assuming generateUUID is defined
                    ...item
                }));
                const overrideColumns = [
                    {
                        field: 'Member Number 16 Digits',
                        headerName: 'Member Number',
                        width: 180,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'First Name',
                        headerName: 'First Name',
                        width: 120,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Last Name',
                        headerName: 'Last Name',
                        width: 120,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Individual Email',
                        headerName: 'Email',
                        width: 200,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <a href={`mailto:${params.value}`}>{params.value}</a>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Combined_Telephone',
                        headerName: 'Phone',
                        width: 150,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Standard Address',
                        headerName: 'Address',
                        width: 220,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Begin_AAA_Year',
                        headerName: 'Member Since',
                        width: 130,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Member_Tenure',
                        headerName: 'Member Tenure',
                        width: 130,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Calc_Coverage_Level',
                        headerName: 'Coverage Level',
                        width: 150,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    },
                    {
                        field: 'Date_of_Last_Purchase',
                        headerName: 'Last Purchase Date',
                        width: 170,
                        renderCell: (params) => (
                           <Tooltip title={params.value}>
                               <span>{params.value}</span>
                           </Tooltip>
                        )
                    }
                ];
                
                return {data: processedData, total: rawData.total_count, overrideColumns: overrideColumns};
            } catch (error) {
                console.error("Error loading GCP:", error);
                // Fallback or error data
                return [];
            }
    }
};

const CRMScreen = () => {
    
    
    const [chosenData, setChosenData] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [overrideColumns, setOverrideColumns] = useState();
    const [organizationFaqs, setOrganizationFaqs] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);
// Sample Data for TreeView
    const sampleData = {
        name: 'Root Entity',
        children: [
            { name: 'Child 1', children: [{ name: 'Grandchild 1' }, { name: 'Grandchild 2' }] },
            { name: 'Child 2' }
        ]
    };
// Nodes and Edges for EntityGraph
    const nodes = [
        { id: '1', type: 'customNode', data: { label: 'Entity 1' }, position: { x: 0, y: 0 } },
        { id: '2', type: 'customNode', data: { label: 'Entity 2' }, position: { x: 100, y: 100 } },
        { id: '3', type: 'customNode', data: { label: 'Entity 3' }, position: { x: 200, y: 200 } }
    ];

    const edges = [
        { id: 'e1-2', source: '1', target: '2', animated: true },
        { id: 'e2-3', source: '2', target: '3', animated: false },
        { id: 'e3-1', source: '3', target: '1', animated: true }
    ];


const data = { name: 'John Doe', phone: '+15024578803', email: 'john.doe@umbrella.ai', company: 'Umbrella AI'}
const sidebarConfig = useMemo(() => [
        {
            icon: <Map />,
            text: 'People',
            slug: 'people',
            children: [
                {
                icon: <Public />,
                text: 'All Users',
                slug: 'all',
                path: '/browse/people/all',
            },
                {
                    icon: <Place />,
                    text: 'All Leads',
                    slug: 'leads',
                    path: '/browse/people/leads',
                },
                {
                    icon: <Map />,
                    text: 'All Active Users',
                    slug: 'active',
                    path: '/browse/people/active',
                },
                {
                    icon: <Map />,
                    text: 'All Pending Users',
                    slug: 'pending',
                    path: '/browse/people/pending',
                },
                {
                    icon: <Map />,
                    text: 'All Cancelled Users',
                    slug: 'cancelled',
                    path: '/browse/people/cancelled',
                },
            ],
        },
    {
        text: "Favorites", Icon: Favorite, secondaryText: "0", children: [
            { text: "No Favorites added" }
        ]
    },,
    ], []);
    const { category, sub_category: pageTab } = useParams();
    let personID = null;
    if(isUUID(category)){
        personID = category
    }
console.log("category, personID, sub_category",category, personID, pageTab)
    

    useEffect(() => {
        const fetchAndSetData = async () => {
            const data = await loadData(category, pageSize, page);
            console.log("fetchAndSetData",data)
            setChosenData(data.data);
            if(data?.total){
                setRowCount(data.total);
            }
            setOverrideColumns(data.overrideColumns);
            setIsLoading(false)
        };
        setIsLoading(true)
        fetchAndSetData();
    }, [category, pageSize, page]); // Effect runs whenever the category changes

    console.log("chosenData",chosenData)

    return (
    <>
      <Screen title={'Contacts'} showSidebarNav={!personID} sidebarConfig={sidebarConfig}>
          {personID && <ContactHeader sidebarMargin={true}/>}
        <Box sx={{ transitionStyle, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={transitionStyle}>
                  {/*  <TreeView data={sampleData} />
                    <EntityGraph nodes={nodes} edges={edges} />*/}
                    {!personID && <PersonaBrowser/>}

                    {personID && <Grid container spacing={2} sx={{padding: 2}}>
                        <Grid item xs={12} md={4}>
                            <EntityViewer entityType="Person" data={data}/>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <AttachedEntityViewer parentEntityType={'people'} data={data} selectedTab={pageTab}/>
                        </Grid>
                    </Grid>}

                    {!personID && <EntityBrowser internalUse={'people'} demoData={chosenData} overrideColumns={overrideColumns} isLoading={isLoading}
                                                 rowCount={rowCount} pageSize={pageSize} setPageSize={setPageSize} setPage={setPage}/>}
                </Grid>
              </Grid>
        </Box>
      </Screen>
    </>
  )
}

export default CRMScreen
