import React, { useState } from 'react';
import { Switch, FormControlLabel, FormGroup, Typography, Divider, Box, Icon } from '@mui/material';

const CommunicationPreferences = () => {
    const [preferences, setPreferences] = useState({
        unsubscribedAllEmails: false,
        emailFailType: 'none',  // "none", "hardBounce", "softBounce", "spamReported"
        smsMarketing: true,
        smsOperational: true,
        acceptCalls: true,
    });

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setPreferences(prev => ({ ...prev, [name]: checked }));
    };

    const handleIssueChange = (issueType) => {
        setPreferences(prev => ({
            ...prev,
            emailFailType: prev.emailFailType === issueType ? 'none' : issueType
        }));
    };

    const issuesExist = preferences.emailFailType !== 'none';

    return (
        <Box sx={{ typography: 'caption'}}>
            <Typography variant="subtitle2">Communication Preferences</Typography>
            <FormGroup sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <FormControlLabel control={<Switch size="small" checked={preferences.smsMarketing} onChange={handleChange} name="smsMarketing" />} label="Marketing SMS" />
                <FormControlLabel control={<Switch size="small" checked={preferences.smsOperational} onChange={handleChange} name="smsOperational" />} label="Operational SMS" />
                <FormControlLabel control={<Switch size="small" checked={!preferences.unsubscribedAllEmails} onChange={handleChange} name="unsubscribedAllEmails" />} label="Accept Emails" />
                <FormControlLabel control={<Switch size="small" checked={preferences.acceptCalls} onChange={handleChange} name="acceptCalls" />} label="Accept Calls" />
            </FormGroup>
            <Divider sx={{ my: 1 }} />
            <Typography variant="subtitle2">Issues</Typography>
            <FormGroup>
                {issuesExist ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Icon>error_outline</Icon>
                        <Typography>Has hard bounced</Typography>
                    </Box>
                ) : (
                    <Typography sx={{ fontSize: 14, color: 'text.secondary' }}>No issues</Typography>
                )}
            </FormGroup>
        </Box>
    );
};

export default CommunicationPreferences;
