// PetTemplate.js
import React from 'react';
import {
   Card, CardContent, CardMedia, Typography, List, ListItem, ListItemText, ListItemIcon, Chip
} from '@mui/material';
import {
   Pets as PetsIcon, Cake as CakeIcon, Category as CategoryIcon, Favorite as FavoriteIcon,
   Healing as HealingIcon, Home as HomeIcon, Restaurant as RestaurantIcon,
   EventAvailable as EventAvailableIcon, Assignment as AssignmentIcon, ContactPhone as ContactPhoneIcon,
   Info as InfoIcon, Public as PublicIcon, VerifiedUser, FitnessCenter as FitnessCenterIcon,
   School as SchoolIcon, Warning as WarningIcon, MedicalServices as MedicalServicesIcon
} from '@mui/icons-material';

const PetTemplate = ({ data }) => (
   <Card sx={{ m: 2, boxShadow: 3 }}>
      <CardMedia
         component="img"
         height="140"
         image={data.photo}
         alt={`${data.name} the ${data.type}`}
      />
      <CardContent>
         <Typography gutterBottom variant="h5" component="div">
            {data.name} - {data.breed} <PetsIcon />
         </Typography>
         <List dense>
            <ListItem>
               <ListItemIcon>
                  <CategoryIcon />
               </ListItemIcon>
               <ListItemText primary="Type" secondary={data.type} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <CakeIcon />
               </ListItemIcon>
               <ListItemText primary="Age" secondary={`${data.age} years old`} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <FavoriteIcon />
               </ListItemIcon>
               <ListItemText primary="Favorite Toy" secondary={data.favoriteToy} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <HealingIcon />
               </ListItemIcon>
               <ListItemText primary="Medical Info" secondary={data.medicalInfo} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <HomeIcon />
               </ListItemIcon>
               <ListItemText primary="Adopted" secondary={data.isAdopted ? 'Yes' : 'No'} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <RestaurantIcon />
               </ListItemIcon>
               <ListItemText primary="Diet" secondary={data.diet} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <EventAvailableIcon />
               </ListItemIcon>
               <ListItemText primary="Last Vet Visit" secondary={data.lastVetVisit} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <AssignmentIcon />
               </ListItemIcon>
               <ListItemText primary="Registration Status" secondary={data.registrationStatus} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <VerifiedUser />
               </ListItemIcon>
               <ListItemText primary="Spay/Neuter Status" secondary={data.spayNeuterStatus} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <FitnessCenterIcon />
               </ListItemIcon>
               <ListItemText primary="Exercise Requirements" secondary={data.exerciseRequirements} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <SchoolIcon />
               </ListItemIcon>
               <ListItemText primary="Training Information" secondary={data.trainingInfo} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <WarningIcon />
               </ListItemIcon>
               <ListItemText primary="Behavioral Traits" secondary={data.behavioralTraits} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <MedicalServicesIcon />
               </ListItemIcon>
               <ListItemText primary="Allergies" secondary={data.allergies} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <ContactPhoneIcon />
               </ListItemIcon>
               <ListItemText primary="Emergency Contacts" secondary={data?.emergencyContacts?.map(contact => `${contact.name} (${contact.phone})`)?.join(', ')} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <InfoIcon />
               </ListItemIcon>
               <ListItemText primary="Insurance Information" secondary={`${data.insuranceInfo?.provider} - Policy: ${data.insuranceInfo?.policyNumber}`} />
            </ListItem>
            <ListItem>
               <ListItemIcon>
                  <PublicIcon />
               </ListItemIcon>
               <ListItemText primary="Travel History" secondary={data.travelHistory} />
            </ListItem>
         </List>
      </CardContent>
   </Card>
);

export default PetTemplate;
