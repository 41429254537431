// RefreshButton.js

import React from 'react';
import {CircularProgress, IconButton} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

const RefreshButton = ({ onRefresh, isLoading }) => {
    return (
        <IconButton onClick={onRefresh} aria-label="refresh" size={'small'} title={'Refresh'}
                    sx={{ float: 'right' }} >
            {isLoading ? <CircularProgress size={'small'}/> : <RefreshIcon fontSize={'small'}/>}
        </IconButton>
    );
};

export default RefreshButton;
