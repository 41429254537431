import Screen from '../../components/Screen/Screen'
import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
} from '@mui/material'
import ChatModelSelectionBox from '../../components/ChatModelSelectionBox'
const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}

const ChatScreen = () => {

  return (
    <>
      <Screen title={'AI Chat'}>
        <Box sx={{ p: 2 }}>
          <Card sx={transitionStyle}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={transitionStyle}>
                  <ChatModelSelectionBox/>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Screen>
    </>
  )
}

export default ChatScreen
