import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { prettyDateTime } from '../../../lib/helpers/helpers';
import { Delete, Phone, Send, Sms } from '@mui/icons-material';
import {makeStyles} from '@mui/styles/'
import './style.css';
import { useSnackbar } from 'notistack';
import usePermission from '../../../lib/hooks/usePermission';
import useOrganization from '../../../lib/hooks/useOrganization';
import { postMailboxMessage } from '../../../lib/api/postMailboxMessage';
import useOrganizationDefaultResponses from '../../../lib/hooks/useOrganizationDefaultResponses';
import { deleteOrganizationResponse, postMailboxMessageSeen, postOrganizationResponse } from '../../../lib/api';
import Avatar from 'boring-avatars';
import useCurrentUser from '../../../lib/hooks/useCurrentUser';
const useStyles = makeStyles(theme => ({
  chatBubble: {
    animation: 'Lmuha 0.3s ease forwards',
    background: '#E5E5EA', //'#1e8555',
    borderRadius: '18px 18px 18px 0',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.20)',
    color: '#000', //'#000',
    display: 'inline-block',
    fontSize: 14,
    maxWidth: '50%',
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    padding: 12,
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
  },
  chatBubbleUser: {
    animation: 'Lmuha 0.3s ease forwards',
    background: '#1b9feb', //'#1e8555',
    borderRadius: '18px 18px 0px 18px',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.20)',
    color: '#fff', //'#000',
    display: 'inline-block',
    fontSize: 14,
    maxWidth: '50%',
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    padding: 12,
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
  },
  timestampLeft: {
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    display: 'block',
    margin: '5px 0 10px',
    float: 'none',
  },
  timestampRight: {
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    display: 'block',
    margin: '5px 0 10px',
    float: 'none',
    textAlign: 'right',
  },
  avatar: {
    animation: 'Lmuha 0.3s ease forwards',
    borderRadius: '50% 50% 0 50%',
    boxShadow: 'rgba(0,0,0,0) 0px 1px 2px 0px',
    height: 40,
    minWidth: 40,
    padding: 3,
    transform: 'scale(0)',
    transformOrigin: 'bottom right',
    width: 40,
  },
  container: {
    display: 'inline-block',
    order: 0,
    padding: 6,
  },
  fromOrganization: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fromUser: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  convoContainer: {
    overflowY: 'auto',
    maxHeight: '500px',
  },
}));

const FromMessage = ({ row, orgIcon }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.fromOrganization}>
        <div className={classes.chatBubbleUser}>{row.message}</div>
        <div className={classes.container}>
          <Avatar
            className="avatar"
            size={45}
            name={row.organization_id}
            variant="beam"
            colors={['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']}
            src={orgIcon}
          />
        </div>
      </div>
      <span className={classes.timestampRight}>{prettyDateTime(row.created_at)}</span>
    </div>
  );
};
const ToMessage = ({ row }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.fromUser}>
        <div className={classes.container}>
          <Avatar
            className="avatar"
            size={45}
            name={row.user_id || row.contact_id || row.visitor_id || row.from_phone_number || row.from_email || ''}
            variant="beam"
            colors={['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']}
          />
        </div>
        <div className={classes.chatBubble}>{row.message}</div>
      </div>
      <span className={classes.timestampLeft}>
        {prettyDateTime(row.created_at)}{' '}
        {row.thread_type === 'voice' ? (
          <Tooltip placement="right" title={'Sent via Voice Message'} aria-label={'Sent via Voice Message'}>
            <Phone style={{ fontSize: 13, verticalAlign: 'middle' }} />
          </Tooltip>
        ) : row.thread_type === 'chat' ? (
          <Tooltip placement="right" title={'Sent via Live Chat'} aria-label={'Sent via Live Chat'}>
            <Sms style={{ fontSize: 13, verticalAlign: 'middle' }} />
          </Tooltip>
        ) : (
          <Tooltip placement="right" title={'Sent via Text Message'} aria-label={'Sent via Text Message'}>
            <Sms style={{ fontSize: 13, verticalAlign: 'middle' }} />
          </Tooltip>
        )}
      </span>
    </div>
  );
};

export default function ViewConvo({ refetch, convo }) {
  const chatRef = useRef(null);
  const classes = useStyles();
  const [prefillMsg, setPrefillMsg] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [replyPhoneNumber, setReplyPhoneNumber] = useState('');
  const [toPhoneNumber, setToPhoneNumber] = useState('');
  const [threadType, setThreadType] = useState(null);
  const [threadID, setThreadID] = useState(null);
  const [contactID, setContactID] = useState(null);
  const [visitorID, setVisitorID] = useState(null);
  const [recipientUserId, setRecipientUserID] = useState(null);
  const [campaignID, setCampaignID] = useState(null);
  const [orgIcon, setOrgIcon] = useState('');
  const { userID } = useCurrentUser();
  const {
    isLoading: isLoadingOrganization,
    isRefetching: isRefetchingOrganization,
    data: organization,
    refetch: refetchOrganization,
  } = useOrganization();
  const {
    isLoading: isLoadingOrganizationDefaultResponses,
    isRefetching: isRefetchingOrganizationDefaultResponses,
    data: organizationDefaultResponses,
    refetch: refetchOrganizationDefaultResponses,
  } = useOrganizationDefaultResponses();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const hasPermissionSend = usePermission('inbox.publish');
  const hasPermissionResponses = usePermission('organization_settings.edit');

  const [messages, setMessages] = useState([
    {
      id: 1,
      campaign_name: 'Thanksgiving Newsletter',
      chat_id: null,
      campaign_id: 123,
      contact_id: 1231,
      from_phone_number: '+15024578803',
      from_carrier: 'Verizon',
      to_phone_number: '+15024578803',
      to_carrier: 'T-Mobile',
      message: 'Hello from a SMS campaign response',
      media: ['https://...', 'https://...'],
      created_at: new Date(),
    },
    {
      id: 2,
      campaign_name: 'Black Friday',
      chat_id: 1234,
      campaign_id: null,
      contact_id: null,
      from_email: 'me@austincameron.com',
      message: 'Hello from a Chat Box',
      media: [],
      created_at: new Date(),
    },
    {
      id: 3,
      campaign_name: '',
      chat_id: null,
      campaign_id: null,
      contact_id: 122,
      message: 'Hello from random SMS',
      media: ['..'],
      created_at: new Date(),
    },
  ]);
  useEffect(() => {
    if (organization) {
      setOrgIcon(`https://sendboth.s3.amazonaws.com/organizations/${organization.id}_icon.png?r=${Math.random()}`);
    }
    if (organization && organization.phone_numbers && organization.phone_numbers.length) {
      setReplyPhoneNumber(organization.phone_numbers[0]);
    }
  }, [organization]);

  useEffect(() => {
    if (convo && Boolean(convo.length)) {
      //scroll down
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
      //set data
      setThreadType(convo[0]['thread_type']);
      setThreadID(convo[0]['thread_id']);
      setContactID(convo[0]['contact_id']);
      setVisitorID(convo[0]['visitor_id']);
      setRecipientUserID(convo[0]['user_id']);
      setCampaignID(convo[0]['campaign_id']);

      const lastPhoneMessage = convo.filter(item => item.from_phone_number != replyPhoneNumber)[0];
      setToPhoneNumber(lastPhoneMessage.from_phone_number);

      //now mark as seen
      const notSeen = convo.filter(item => !item.seen).map(item => item.id);
      postMailboxMessageSeen(notSeen).then(r => {
        refetch();
      });
    }
  }, [convo]);

  const handleSendMessage = useCallback(
    event => {
      if (hasPermissionSend) {
        const confirm = window.confirm('Confirm Send?');
        if (confirm && newMessage && threadType && (toPhoneNumber || contactID || visitorID || userID)) {
          const options = {
            threadType: threadType,
            userID: userID,
            threadID: threadID,
            contactID: contactID,
            visitorID: visitorID,
            campaignID: campaignID,
            toPhoneNumber: toPhoneNumber,
            message: newMessage,
            //media: attachmentUrl, //- todo when we have asset management working
          };
          postMailboxMessage(options).then(response => {
            if (response.error) {
              enqueueSnackbar(response.error, { variant: 'error' });
            } else {
              setNewMessage('');
              refetch();
            }
          });
        }
      } else {
        enqueueSnackbar('Please ask Admin for permission.', { variant: 'error' });
      }
    },
    [
      convo,
      threadType,
      newMessage,
      toPhoneNumber,
      hasPermissionSend,
      userID,
      threadID,
      visitorID,
      campaignID,
      contactID,
    ],
  );

  const handleNewResponse = useCallback(() => {
    if (hasPermissionResponses) {
      if (newMessage) {
        postOrganizationResponse(newMessage).then(response => {
          setNewMessage('');
          refetchOrganizationDefaultResponses();
        });
      }
    } else {
      enqueueSnackbar('Please ask Admin for permission.', { variant: 'error' });
    }
  }, [newMessage]);

  const handleDeleteResponse = item => {
    if (hasPermissionResponses) {
      const confirm = window.confirm(`Confirm Deletion of for ${item.message}?`);
      if (confirm) {
        deleteOrganizationResponse(item.id).then(response => {
          refetchOrganizationDefaultResponses();
        });
      }
    } else {
      enqueueSnackbar('Please ask Admin for permission.', { variant: 'error' });
    }
  };

  return (
    <Box style={{ padding: 20 }}>
      {!convo && (
        <Typography color={'textSecondary'} style={{ textAlign: 'center', marginTop: 200, marginBottom: 200 }}>
          No Conversation Selected.
        </Typography>
      )}
      <div className={classes.convoContainer} ref={chatRef}>
        {convo &&
          convo.map(row => {
            return (
              <>
                {row.ai_responded || row.responded_by ? (
                  <FromMessage key={row.id} orgIcon={orgIcon} row={row} />
                ) : (
                  <ToMessage key={row.id} row={row} />
                )}
              </>
            );
          })}
      </div>
      {convo && (
        <>
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel htmlFor="outlined-age-native-simple">Default Responses</InputLabel>

            <Select
              fullWidth={true}
              labelId="default-messages-label"
              id="default-messages"
              value={prefillMsg}
              onChange={e => setNewMessage(e.target.value)}
              input={<OutlinedInput name="prefilled_msg" labelWidth={135} />}
            >
              <ListItem value={''} style={{ textAlign: 'center' }}>
                <Button
                  disabled={!newMessage}
                  variant={'contained'}
                  color={'primary'}
                  style={{ margin: 'auto', display: 'block' }}
                  onClick={handleNewResponse}
                >
                  Save as Preset
                </Button>
              </ListItem>
              {organizationDefaultResponses &&
                organizationDefaultResponses.map(item => {
                  return (
                    <ListItem key={item.id} value={item.message}>
                      <ListItemText primary={item.message} />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => handleDeleteResponse(item)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </Select>
          </FormControl>

          <TextField
            id="outlined-multiline-static"
            label=""
            multiline
            fullWidth={true}
            rows="8"
            maxlength={137}
            placeholder="Enter your message..."
            value={newMessage}
            margin="dense"
            variant="outlined"
            inputProps={{ maxLength: 137 }}
            onChange={v => setNewMessage(v.target.value)}
          />

          <Typography
            variant="h7"
            style={{ marginTop: 20, fontSize: 14, display: 'inline-block' }}
            color="textSecondary"
            gutterBottom
          >
            Remaining Characters: <span>{160 - newMessage.length}</span>/160
          </Typography>

          <Button
            disabled={newMessage.length > 0 ? false : true}
            variant="contained"
            color="primary"
            style={{ float: 'right', marginTop: 10, marginBottom: 15 }}
            onClick={handleSendMessage}
          >
            Send Text &nbsp;
            <Send />
          </Button>
        </>
      )}
    </Box>
  );
}
