import { useQuery } from 'react-query';
import testKnowledgeBase from '../constants/testDatabase';

const fetchTestData = async (params) => {
    // Simulate fetching data which in reality is static for this example
    return new Promise(resolve => setTimeout(() => resolve(testKnowledgeBase), 1000));
};

const useTestData = (params) => {
    const result = useQuery(
        ['test_data_base', params], // Using an array to make the query key more specific and structured
        () => fetchTestData(params),
        {
            refetchOnWindowFocus: false, // Avoid refetching when the window gains focus
            staleTime: Infinity, // Data is static, no need to refetch unless app is restarted or forced
            cacheTime: Infinity, // Keep data in cache indefinitely
            placeholderData: testKnowledgeBase, // Show placeholder data immediately while loading
        }
    );
    return result;
}

export default useTestData;
