import axios from 'axios'
import { API_HOST } from '../constants/constants'
export const getDriverCheckins = async (orderId, token, userID) => {
  const apiURL =
    API_HOST + (orderId ? '/order_checkins/' + orderId : '/order_checkins')
  try {
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
