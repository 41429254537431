import { useQuery } from 'react-query';
import { getTrends } from '../api/getTrends';

const useResult = () => {
  const result = useQuery('trends', () => getTrends(), {
    refetchOnWindowFocus: false,
  });
  return result;
};

export default useResult;
