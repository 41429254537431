import axios from 'axios'
import useCurrentUser from '../hooks/useCurrentUser'
import { API_HOST } from '../constants/constants'
export const getSession = async () => {
  const apiURL = API_HOST + '/session'
  const { token, userID } = useCurrentUser()
  try {
    if (!userID || !token) {
      return false
    }
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
        'x-gg-user-id': userID,
      },
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
