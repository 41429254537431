import axios from 'axios'
import { API_HOST } from '../constants/constants'
export const getReviews = async (token, userID) => {
  if (!userID || !token) {
    return false
  }
  const apiURL = API_HOST + '/reviews'
  try {
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
