import { useQuery } from 'react-query';
import { getMailbox } from '../api/getMailbox';
import useCurrentUser from "./useCurrentUser";

const useMailbox = organizationId => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('mailbox_' + organizationId, () => getMailbox(organizationId, token, userID), {
    refetchOnWindowFocus: true,
    refetchInterval: 5000,
    refetchIntervalInBackground: 10000,
  });
  return result;
};

export default useMailbox;
