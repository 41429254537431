import { useQuery } from 'react-query'
import { getOrganization } from '../api'
import useCurrentUser from './useCurrentUser'

const useOrganization = (organizationId = undefined, subdomain = undefined) => {
  const { token, userID } = useCurrentUser()
  const result = useQuery(
    'organization_' + organizationId + '_' + subdomain,
    () => getOrganization(organizationId, subdomain, token, userID),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  )
  return result
}

export default useOrganization
