import React, { useState } from 'react';
import {
    Card, CardContent, CardMedia, Typography, CardActions, IconButton, Collapse,
    Link, Grid, List, ListItem, ListItemText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DocumentIcon from '@mui/icons-material/Description';
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import LinkIcon from '@mui/icons-material/Link';
import {PictureAsPdf, Phone} from '@mui/icons-material/';
import { prettyDateTime, prettyDate, toCapitalizeFromUnderScore, truncate } from '../../lib/helpers/helpers';

const IconMap = {
    Article: DocumentIcon,
    Document: DocumentIcon,
    PhoneCall: Phone,
    Vehicle: PlaceIcon,
    Person: PhotoLibraryIcon,
    Place: PlaceIcon,
    Organization: PlaceIcon,
    Event: EventIcon,
    Photo: PhotoLibraryIcon,
    Video: VideoLibraryIcon
};

export const EntityCard = ({ entity, isActive, onClick }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const renderMedia = () => {
        if (entity.type === 'Photo' && entity.sourceUrl) {
            return <CardMedia component="img" height="194" image={entity.sourceUrl} alt={entity.title} />;
        } else if (entity.type === 'Video' && entity.sourceUrl) {
            return (
                <CardMedia component="video" controls height="194">
                    <source src={entity.sourceUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </CardMedia>
            );
        }
        return null;
    };

    const renderTranscription = (transcriptions) => (
        <List dense>
            {transcriptions.map((transcript, index) => (
                <ListItem key={index}>
                    <ListItemText primary={`${transcript.timestamp} - ${transcript.speaker}`} secondary={transcript.text} />
                </ListItem>
            ))}
        </List>
    );

    const EntityIcon = IconMap[entity.type] || PictureAsPdf;

    return (
        <Card sx={{ maxWidth: isActive ? 600 : 345, m: 2, transition: 'all 0.3s ease-in-out', transform: isActive ? 'scale(1.1)' : 'scale(1)' }} onClick={() => onClick(entity)}>
            <CardContent>
                <Typography variant="h5" component="div">
                    <EntityIcon sx={{ verticalAlign: 'bottom', mr: 1 }} />
                    {entity.title || entity.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {truncate(entity.summary || entity.description || '', 100)}
                </Typography>
            </CardContent>
            {renderMedia()}
            <CardActions disableSpacing>
                <IconButton onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                    <ExpandMoreIcon sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                </IconButton>
                {entity.sourceUrl && (
                    <IconButton component={Link} href={entity.sourceUrl} target="_blank" rel="noopener">
                        <LinkIcon />
                    </IconButton>
                )}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Grid container spacing={2}>
                        {Object.entries(entity).map(([key, value]) => {
                            if (typeof value === 'string' || Array.isArray(value)) {
                                if (key === 'transcription') {
                                    return (
                                        <Grid item xs={12} key={key}>
                                            <Typography paragraph>
                                                <strong>{toCapitalizeFromUnderScore(key)}:</strong>
                                            </Typography>
                                            {renderTranscription(value)}
                                        </Grid>
                                    );
                                } else {
                                    return (
                                        <Typography key={key} paragraph>
                                            <strong>{toCapitalizeFromUnderScore(key)}:</strong> {Array.isArray(value) ? value.join(', ') : value}
                                        </Typography>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default EntityCard;
