import { useQuery } from 'react-query';
import {getOrganizationDefaultResponses} from "../api";

const useOrganizationDefaultResponses = () => {
  const result = useQuery('organization_responses', () => getOrganizationDefaultResponses(), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useOrganizationDefaultResponses;
