import React, { useState, useRef, useEffect } from 'react';
import { Card, CardContent, Typography, Button, IconButton, LinearProgress } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { TranscriptionViewer } from '../../TranscriptionViewer/TranscriptionViewer';
import {prettyDateTime, prettyNumber, prettyPhone} from "../../../lib/helpers/helpers";
import {SimpleAudioPlayer} from "../../SimpleAudioPlayer/SimpleAudioPlayer"; // Adjust this path as necessary

const PhoneCallTemplate = ({ data }) => {
   return (
      <Card sx={{ m: 2, boxShadow: 3 }}>
         <CardContent>
            <Typography variant="h5" gutterBottom>
               Call from: {prettyPhone(data.callerId)}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
               Duration: {prettyNumber(data.duration)} seconds
            </Typography>
            <Typography variant="body2">
               Date: {prettyDateTime(data.timestamp)}
            </Typography>
            <SimpleAudioPlayer src={data.audioUrl}/>
            {data.transcription && (
               <TranscriptionViewer data={data.transcription} />
            )}
         </CardContent>
      </Card>
   );
};

export default PhoneCallTemplate
