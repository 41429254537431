export const ENVIRONMENT = [
  // always point native to production
  ['production', window.location.origin.includes('capacitor://localhost')],
  [
    'development',
    !window.location.origin.includes('capacitor://localhost') &&
      !window.location.origin.includes('index.umbrella.ai'),
  ],
  ['staging', window.location.origin.includes('staging.index.umbrella.ai')],
  [
    'production',
    !window.location.origin.includes('staging.index.umbrella.ai') &&
      window.location.origin.includes('index.umbrella.ai'),
  ],
].find(([name, isActive]) => isActive)[0]

export const getSubdomainClientHost = (subdomain) =>
  ({
    development: `http://${subdomain}.localhost:3001`,
    staging: `https://${subdomain}.staging.index.umbrella.ai`,
    production: `https://${subdomain}.index.umbrella.ai`,
  })[ENVIRONMENT]

export const getClientHost = () =>
  ({
    development: `http://localhost:3003`,
    staging: `https://staging.index.umbrella.ai`,
    production: `https://index.umbrella.ai`,
  })[ENVIRONMENT]

export const CLIENT_HOST = {
    development: `http://localhost:3003`,
    staging: `https://staging.index.umbrella.ai`,
    production: `https://index.umbrella.ai`,
}[ENVIRONMENT]
export const API_HOST = {
  development: 'http://localhost:5044/v1',
  staging: 'https://staging.api.index.umbrella.ai/v1',
  production: 'https://api.index.umbrella.ai/v1',
}[ENVIRONMENT]

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: 'UA-137832774-xx',
  production: 'UA-137832774-xx',
}[ENVIRONMENT]
export const COLORS = ['#fd5c87', '#fd6f5c', '#9e9ea8', '#f6f9fc']
