import React from 'react';
import { Box, Paper, Typography, Avatar, Card, CardContent } from '@mui/material';
import {
   Timeline,
   TimelineItem,
   TimelineSeparator,
   TimelineDot,
   TimelineContent,
   TimelineConnector,
   TimelineOppositeContent
} from '@mui/lab';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const TranscriptionViewer = ({ data }) => {
    return (
        <Box sx={{ overflowY: 'auto', maxHeight: '80vh', maxWidth: 500, p: 0 }}>
           <Timeline>
              {data.length && data.map((entry, index) => (
                 <TimelineItem key={index}>
                    <TimelineOppositeContent
                       sx={{ m: 'auto 0' }}
                       align="right"
                       color="text.secondary"
                    >
                       <Typography variant="caption" display="block">
                          <AccessTimeIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                          {entry.timestamp}
                       </Typography>
                       <Typography variant="h6" component="span">
                          {entry.speaker}
                       </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                       <TimelineDot color="primary" variant="outlined">
                          <PersonIcon fontSize={'small'} />
                       </TimelineDot>
                       {index < data.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 1, px: 2 }}>
                       <Card variant="outlined">
                          <CardContent>
                             <Typography variant="body1">
                                {entry.text}
                             </Typography>
                          </CardContent>
                       </Card>
                    </TimelineContent>
                 </TimelineItem>
              ))}
           </Timeline>
        
        
        </Box>
    );
};
