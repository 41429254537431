import { useQuery } from 'react-query'
import { getUploadPresign } from '../api'
import useCurrentUser from './useCurrentUser'

const useUploadPresign = (fileExtension, filename, fileSize, contentType) => {
  const { token, userID } = useCurrentUser()
  const result = useQuery(
    'upload_presign_' + fileExtension + filename + fileSize + contentType,
    () =>
      getUploadPresign(
        fileExtension,
        filename,
        fileSize,
        contentType,
        token,
        userID
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  )
  return result
}

export default useUploadPresign
