// AttachedEntityViewer.js
import React, {useCallback, useContext, useState, useMemo} from 'react';
import {
    Button,
    Grid,
    LinearProgress,
    TextField,
    IconButton,
    InputAdornment,
    useTheme,
    useMediaQuery,
    Tab, Box, Card
} from '@mui/material';  // Most UI components come from @mui/material
import { makeStyles } from '@mui/styles';  // makeStyles comes from @mui/styles
import { TabContext, TabList } from '@mui/lab';  // TabContext and TabList come from @mui/lab
import {useNavigate, useParams} from 'react-router-dom'

import {
    Groups, Close, DirectionsCar, PictureAsPdf, Business,
    Email, Place, Pets, Chat, Phone, PhotoLibrary,
    Dashboard, Public, Search, Event,
    Settings, Storage, FamilyRestroom,
    ShoppingBasket, Insights, TipsAndUpdates, Psychology,
} from '@mui/icons-material';
import {TranscriptionViewer} from "../TranscriptionViewer/TranscriptionViewer";
import EntityBrowser from "../EntityBrowser";
import PersonTemplate from "../EntityViewer/templates/PersonTemplate";
import PetTemplate from "../EntityViewer/templates/PetTemplate";
import VehicleTemplate from "../EntityViewer/templates/VehicleTemplate";
import PhoneCallTemplate from "../EntityViewer/templates/PhoneCallTemplate";
import ConversationTemplate from "../EntityViewer/templates/ConversationTemplate";
import InsightsTemplate from "../EntityViewer/templates/InsightsTemplate";
import ActivityTemplate from "../EntityViewer/templates/ActivityTemplate";
import PreferencesTemplate from "../EntityViewer/templates/PreferencesTemplate";

const useStyles = makeStyles(theme => ({
    tabPanel:{
        margin:'25px 0px 0px',
        padding:0,
        width: '100%'
    },
    loading:{width:'100%',margin:50, color:'inherit'},
    icon:{
        display:'inline',
        '& svg':{
            marginBottom:-5,
            marginRight:5
        }
    }
}));
const AttachedEntityViewer = ({data, selectedTab = null, parentEntityType = null}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { category: entityID, sub_category } = useParams();
    
    
    const theme = useTheme();
    const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
    const [activeTab, setActiveTab] = useState(selectedTab || 'insights');
console.log("category, sub_category", entityID, sub_category)
    const a11yProps = (index, active=true) => {
        return {
            value: String(index),
            id: `scrollable-tab-${index}`,
            'aria-controls': `tab-${index}`,
        };
    }
    const handleTabChange = useCallback((event, value)=>{
        setActiveTab(value); // Update the active tab state
        console.log(value); // Update the active tab state
        navigate(`/browse/${parentEntityType}/${entityID}/${value}`); // Navigate to the corresponding child page
        
    },[entityID, parentEntityType])

    const demoTranscription = [
        {
            timestamp: '00:00:05',
            speaker: 'Speaker 1',
            text: 'Hello everyone, thank you for joining today’s meeting. I’m glad to see you all here.'
        },
        {
            timestamp: '00:00:20',
            speaker: 'Speaker 2',
            text: 'Thank you for having us. I’m looking forward to discussing the new project plans.'
        },
        {
            timestamp: '00:01:00',
            speaker: 'Speaker 1',
            text: 'Let’s start by reviewing the project outline and then we can go over the individual responsibilities.'
        },
        {
            timestamp: '00:02:30',
            speaker: 'Speaker 3',
            text: 'I have a question regarding the timeline. Are we still on track for the next phase?'
        },
        {
            timestamp: '00:03:15',
            speaker: 'Speaker 1',
            text: 'Yes, we are on track. Let’s discuss the details of the timeline now.'
        }
    ];
    const familyMembers = [
        { id: 2, name: "Jane Doe", age: 32, relation: "Mother" },
        { id: 3, name: "Jimmy Doe", age: 10, relation: "Son" },
        { id: 4, name: "Jenny Doe", age: 8, relation: "Daughter" },
        { id: 1, name: "Joseph Doe", age: 34, relation: "Brother" },
    ];
    const petData = [
        {
            name: 'Buddy',
            type: 'Dog',
            age: 5,
            breed: 'Golden Retriever',
            favoriteToy: 'Rubber Bone',
            medicalInfo: 'Up-to-date on vaccinations',
            isAdopted: true,
            diet: 'Dry food - Chicken and rice',
            registrationStatus: 'Registered',
            lastVetVisit: '2023-07-10',
            microchipID: '982000362738904',
            spayNeuterStatus: 'Neutered',
            trainingInfo: 'Completed basic obedience',
            behavioralTraits: 'Friendly with children, shy around other dogs',
            allergies: 'None known',
            specialNeeds: 'None',
            exerciseRequirements: 'High - needs two walks per day',
            rescueInformation: {
                organization: 'Happy Tails Rescue',
                rescueDate: '2019-08-15'
            },
            insuranceInfo: {
                provider: 'PetSafe Insurance',
                policyNumber: 'PS100392'
            },
            ownershipHistory: ['John Doe (previous owner, 2019)', 'Jane Smith (breeder, 2018)'],
            dietaryRestrictions: 'No grain products',
            vaccinationHistory: [
                { vaccine: 'Rabies', date: '2023-06-10' },
                { vaccine: 'Distemper', date: '2023-06-10' }
            ],
            emergencyContacts: [
                { name: 'John Doe', phone: '555-1234' },
                { name: 'Vet Clinic', phone: '555-5678' }
            ],
            veterinarianInfo: {
                name: 'Dr. Smith',
                clinic: 'Town Vet Clinic',
                phone: '555-5678'
            },
            geneticInfo: 'Purebred',
            legalStatus: 'Eligible for breeding',
            travelHistory: 'Traveled to Canada, 2023',
            photo: 'https://example.com/photos/buddy.jpg'
        },
        {
            name: 'Whiskers',
            type: 'Cat',
            age: 3,
            breed: 'Maine Coon',
            favoriteToy: 'Feather Wand',
            medicalInfo: 'Needs dental checkup',
            isAdopted: false,
            diet: 'Wet food - Tuna',
            registrationStatus: 'Not registered',
            lastVetVisit: '2024-01-15',
            microchipID: '993000123456789',
            spayNeuterStatus: 'Not neutered',
            trainingInfo: 'Litter trained',
            behavioralTraits: 'Curious and playful, dislikes dogs',
            allergies: 'None',
            specialNeeds: 'Dental care required',
            exerciseRequirements: 'Moderate - indoor play recommended',
            rescueInformation: {
                organization: 'City Cat Shelter',
                rescueDate: '2022-05-20'
            },
            insuranceInfo: {
                provider: 'Feline Cover',
                policyNumber: 'FC200787'
            },
            ownershipHistory: ['City Cat Shelter (current)'],
            dietaryRestrictions: 'Hypoallergenic diet',
            vaccinationHistory: [
                { vaccine: 'Feline Leukemia', date: '2023-12-20' }
            ],
            emergencyContacts: [
                { name: 'Jane Doe', phone: '555-2345' },
                { name: 'City Cat Shelter', phone: '555-6789' }
            ],
            veterinarianInfo: {
                name: 'Dr. Mew',
                clinic: 'Feline Health',
                phone: '555-9012'
            },
            geneticInfo: 'Not applicable',
            legalStatus: 'Pet only, no breeding rights',
            travelHistory: 'None',
            photo: 'https://example.com/photos/whiskers.jpg'
        }
    ];
    
    const vehicleData = [
        {
            make: 'Toyota',
            model: 'Corolla',
            year: 2021,
            licensePlate: 'XYZ-1234',
            vin: '1HGBH41JXMN109186',
            operable: true,
            insured: true,
            registrationState: 'California',
            curbWeight: 1315, // in kilograms
            oemTires: 'P195/65R15',
            photo: 'https://img.sm360.ca/ir/w640h480/images/article/tonerchevroletbuickgmclimitedgrandfalls-46/120558//2023_corolla_infrared_special_special_edition_002-scaled1696534031674.jpg',
            ownerInfo: {
                name: 'Alice Johnson',
                contact: '555-0101'
            },
            serviceHistory: [
                { date: '2022-06-15', description: 'Oil change' },
                { date: '2023-02-20', description: 'Brake pads replaced' }
            ],
            insuranceDetails: {
                provider: 'AllState',
                policyNumber: 'AS1234567'
            },
            warrantyStatus: 'Expires 2024-12-01',
            fuelType: 'Gasoline',
            mileage: '25000',
            registrationDetails: {
                number: 'XYZ-1234',
                expiryDate: '2024-01-01'
            },
            emissionStatus: 'Compliant',
            safetyFeatures: 'Airbags, ABS, Stability Control',
            location: 'Los Angeles, CA',
            financingInfo: 'None',
            color: 'Red',
            interiorFeatures: 'Cloth seats, basic infotainment system',
            tireCondition: 'Good',
            accidentHistory: 'None'
        },
        {
            make: 'Honda',
            model: 'Civic',
            year: 2019,
            licensePlate: 'ABC-5678',
            vin: '2HGBH41JXMN109187',
            operable: true,
            insured: false,
            registrationState: 'Nevada',
            curbWeight: 1260, // in kilograms
            oemTires: 'P205/55R16',
            photo: 'https://cars.usnews.com/pics/size/640x420/images/Auto/custom/15550/2024_Honda_Civic_Angular_Front_1.jpg',
            ownerInfo: {
                name: 'Bob Smith',
                contact: '555-0202'
            },
            serviceHistory: [
                { date: '2020-07-10', description: 'Air filter replaced' },
                { date: '2021-08-25', description: 'Battery replaced' }
            ],
            insuranceDetails: {
                provider: 'Geico',
                policyNumber: 'GE9876543'
            },
            warrantyStatus: 'Expired',
            fuelType: 'Gasoline',
            mileage: '40000',
            registrationDetails: {
                number: 'ABC-5678',
                expiryDate: '2025-05-15'
            },
            emissionStatus: 'Compliant',
            safetyFeatures: 'Airbags, Traction Control',
            location: 'Reno, NV',
            financingInfo: 'Loan with CarLoans Inc., balance $7,000',
            color: 'Blue',
            interiorFeatures: 'Leather seats, advanced infotainment system',
            tireCondition: 'Needs replacement soon',
            accidentHistory: 'Minor fender bender in 2020'
        },
        // Add similar detailed entries for Ford Mustang, Tesla Model 3, and Chevrolet Camaro
    ];
    const demoCallData = [
        {
            callerId: "+2345678901",
            duration: 245,
            timestamp: new Date().toISOString(),
            audioUrl: "http://localhost:3003/data_store/phone_calls/membership - Calabrio demo call - 213443.wav",
            transcription: [
                { timestamp: '00:00:05', speaker: 'Agent', text: 'Good morning, AAA Hoosier customer service. How may I assist you today?' },
                { timestamp: '00:00:15', speaker: 'Customer', text: 'Hi, I’m having trouble accessing my membership benefits online and need help resetting my password to log in.' },
                { timestamp: '00:00:30', speaker: 'Agent', text: 'I can certainly assist you with that. Can you please verify your membership number so I can pull up your account?' },
                { timestamp: '00:00:45', speaker: 'Customer', text: 'Sure, it’s 789456123.' },
                { timestamp: '00:01:00', speaker: 'Agent', text: 'Thank you. Let me reset your password and send you a link to your registered email. You should receive it shortly.' },
                { timestamp: '00:01:30', speaker: 'Customer', text: 'Got it, thank you so much for your help!' }
            ]
        },
        {
            callerId: "+3456789012",
            duration: 160,
            timestamp: new Date().toISOString(),
            audioUrl: "http://localhost:3003/data_store/phone_calls/membership 2 - Calabrio demo call - 213443.wav",
            transcription: [
                { timestamp: '00:00:05', speaker: 'Agent', text: 'Welcome to AAA Hoosier, what can I do for you today?' },
                { timestamp: '00:00:12', speaker: 'Customer', text: 'I’m looking to update the address on my account as I recently moved.' },
                { timestamp: '00:00:25', speaker: 'Agent', text: 'Certainly, I can help with that. Could you please provide your membership number and the new address you’d like to update?' },
                { timestamp: '00:00:40', speaker: 'Customer', text: 'My membership number is 456789123, and the new address is 1234 Elm Street, Indianapolis, IN.' },
                { timestamp: '00:01:00', speaker: 'Agent', text: 'Thank you for the information. I’ve successfully updated your address on file. Is there anything else I can assist you with today?' },
                { timestamp: '00:01:15', speaker: 'Customer', text: 'No, that’s all. Thanks for your help!' }
            ]
        },
        {
            callerId: "+4567890123",
            duration: 120,
            timestamp: new Date().toISOString(),
            audioUrl: "http://localhost:3003/data_store/phone_calls/membership 3 - Calabrio demo call - 213443.wav",
            transcription: [
                { timestamp: '00:00:05', speaker: 'Agent', text: 'AAA Hoosier customer service, how can I assist you today?' },
                { timestamp: '00:00:10', speaker: 'Customer', text: 'I have a question regarding a recent charge on my account that I don’t recognize.' },
                { timestamp: '00:00:25', speaker: 'Agent', text: 'I can help you with that. Could you provide me with your membership number and the details of the charge you’re concerned about?' },
                { timestamp: '00:00:40', speaker: 'Customer', text: 'My membership number is 789012345, and the charge is from two days ago for roadside assistance, but I didn’t use any services.' },
                { timestamp: '00:01:00', speaker: 'Agent', text: 'Thank you for the information. Let me check our records. It looks like this might be an error. I’ll issue a refund, and you should see it on your statement within a few days.' },
                { timestamp: '00:01:20', speaker: 'Customer', text: 'Thank you so much for resolving this!' }
            ]
        },
        {
            callerId: "+5678901234",
            duration: 180,
            timestamp: new Date().toISOString(),
            audioUrl: "http://localhost:3003/data_store/phone_calls/membership 4 - Calabrio demo call - 213443.wav",
            transcription: [
                { timestamp: '00:00:03', speaker: 'Agent', text: 'Thank you for calling AAA Hoosier. What issue are you experiencing today?' },
                { timestamp: '00:00:18', speaker: 'Customer', text: 'My roadside assistance service has been interrupted, and I’m currently stranded.' },
                { timestamp: '00:00:35', speaker: 'Agent', text: 'I’m sorry to hear that. Can you provide me with your current location and membership number so I can send immediate assistance your way?' },
                { timestamp: '00:00:50', speaker: 'Customer', text: 'I’m on I-465 near exit 27, and my membership number is 567890123.' },
                { timestamp: '00:01:10', speaker: 'Agent', text: 'Thank you. I’m dispatching a tow truck to your location now. They should arrive within 30 minutes. I’ll stay on the line until they get there.' },
                { timestamp: '00:01:45', speaker: 'Customer', text: 'Thank you, I appreciate your help!' }
            ]
        },
        {
            callerId: "+6789012345",
            duration: 205,
            timestamp: new Date().toISOString(),
            audioUrl: "http://localhost:3003/data_store/phone_calls/membership 5 - Calabrio demo call - 213443.wav",
            transcription: [
                { timestamp: '00:00:05', speaker: 'Agent', text: 'Hello, this is AAA Hoosier. Can I have your account number, please?' },
                { timestamp: '00:00:15', speaker: 'Customer', text: 'Sure, it’s 123456. I need to report an issue with the auto insurance policy I have with AAA.' },
                { timestamp: '00:00:30', speaker: 'Agent', text: 'I can help you with that. Could you describe the issue you’re experiencing?' },
                { timestamp: '00:00:45', speaker: 'Customer', text: 'I noticed that my premium has increased significantly, and I’d like to understand why.' },
                { timestamp: '00:01:05', speaker: 'Agent', text: 'Let me look into that for you. It seems like there was an update to your policy that caused the increase. I’ll review it and see if we can adjust the premium back to its previous amount.' },
                { timestamp: '00:01:45', speaker: 'Customer', text: 'That would be great, thank you for your assistance.' }
            ]
        },
        {
            callerId: "+7890123456",
            duration: 275,
            timestamp: new Date().toISOString(),
            audioUrl: "http://localhost:3003/data_store/phone_calls/membership 6 - Calabrio demo call - 213443.wav",
            transcription: [
                { timestamp: '00:00:06', speaker: 'Agent', text: 'AAA Hoosier support, what issue can I help you with today?' },
                { timestamp: '00:00:20', speaker: 'Customer', text: 'I received an error message while trying to renew my membership online.' },
                { timestamp: '00:00:35', speaker: 'Agent', text: 'I’m sorry to hear that. Let me take a look. Can you provide your membership number and the error message you received?' },
                { timestamp: '00:00:50', speaker: 'Customer', text: 'My membership number is 890123456, and the error said something about an invalid payment method.' },
                { timestamp: '00:01:10', speaker: 'Agent', text: 'Thank you. It seems like there was an issue with the card on file. I’ll update your payment method now, and you should be able to renew without any problems.' },
                { timestamp: '00:01:40', speaker: 'Customer', text: 'Great, I’ll try again. Thanks for your help!' }
            ]
        },
        {
            callerId: "+2390123456",
            duration: 125,
            timestamp: new Date().toISOString(),
            audioUrl: "http://localhost:3003/data_store/phone_calls/membership 7 - Calabrio demo call - 213443.wav",
            transcription: [
                { timestamp: '00:00:08', speaker: 'Agent', text: 'AAA Hoosier customer service, how can I assist you?' },
                { timestamp: '00:00:30', speaker: 'Customer', text: 'I received an error message while trying to update my auto insurance information.' },
                { timestamp: '00:00:45', speaker: 'Agent', text: 'I can help with that. Could you please provide your membership number and the exact message you received?' },
                { timestamp: '00:01:00', speaker: 'Customer', text: 'My membership number is 234567890, and the message said my information couldn’t be verified.' },
                { timestamp: '00:01:20', speaker: 'Agent', text: 'Thank you. It looks like there was an issue with your verification process. I’ll correct it now so you can update your information without any issues.' }
            ]
        },
        {
            callerId: "+1234567890",
            duration: 300,
            timestamp: new Date().toISOString(),
            audioUrl: "http://localhost:3003/data_store/phone_calls/Calabrio demo call - 213443.wav",
            transcription: [
                { timestamp: '00:00:05', speaker: 'Agent', text: 'Hello, thank you for calling AAA Hoosier. How can I assist you today?' },
                { timestamp: '00:00:10', speaker: 'Customer', text: 'Hi, I’m calling about a billing issue I’m facing with my AAA auto insurance.' },
                { timestamp: '00:00:25', speaker: 'Agent', text: 'I’m sorry to hear that. Can you provide me with your membership number and details about the billing issue?' },
                { timestamp: '00:00:40', speaker: 'Customer', text: 'My membership number is 345678901, and I noticed that I was charged twice for my last premium payment.' },
                { timestamp: '00:01:00', speaker: 'Agent', text: 'Thank you for the information. Let me check your account. It appears there was a duplicate charge. I’ll issue a refund for the extra payment, and you should see it reflected in your account within a few days.' },
                { timestamp: '00:01:30', speaker: 'Customer', text: 'That’s great, thank you so much for resolving this quickly!' }
            ]
        },
    ];
    const convoDemoData = [
        {
            topic: "Customer Support Inquiry",
            messages: [
                { sender: "Customer", type: "Email", timestamp: new Date().toISOString(), content: "I have an issue with my order." },
                { sender: "Support Agent", type: "Email", timestamp: new Date().toISOString(), content: "Could you please specify the problem?" }
            ]
        },
        {
            topic: "Service Feedback",
            messages: [
                { sender: "Customer", type: "SMS", timestamp: new Date().toISOString(), content: "Your service was excellent today." },
                { sender: "Service Team", type: "SMS", timestamp: new Date().toISOString(), content: "Thank you for your feedback!" }
            ]
        },
        {
            topic: "Order Confirmation",
            messages: [
                { sender: "Business", type: "Fax", timestamp: new Date().toISOString(), content: "Your order has been confirmed." }
            ]
        },
        {
            topic: "Product Inquiry",
            messages: [
                { sender: "Potential Customer", type: "Live Chat", timestamp: new Date().toISOString(), content: "Is this product available in blue?" },
                { sender: "Sales Representative", type: "Live Chat", timestamp: new Date().toISOString(), content: "Checking our inventory, one moment please." }
            ]
        },
        {
            topic: "Technical Support Call",
            messages: [
                { sender: "Customer", type: "Phone", timestamp: new Date().toISOString(), content: "Hello, my device won't start." },
                { sender: "Tech Support", type: "Phone", timestamp: new Date().toISOString(), content: "Have you tried turning it off and on again?" }
            ]
        },
        {
            topic: "Event Invitation",
            messages: [
                { sender: "Event Organizer", type: "MMS", timestamp: new Date().toISOString(), content: "You're invited to our exclusive event! See attached flyer.", attachment: "flyer.jpg" }
            ]
        }
    ];
    const demoInsightsData = [
        {
            severity: 'info',
            message: 'Added Pet Name: Charlie to the customer’s pet insurance policy.'
        },
        {
            severity: 'warning',
            message: 'Updated Address: Customer moved to a new address, likely affecting service area.'
        },
        {
            severity: 'success',
            message: 'Customer mentioned Child: Added a note for potential child safety product upsell.'
        },
        {
            severity: 'info',
            message: 'Expressed interest in upgrading membership to premium.'
        },
        {
            severity: 'error',
            message: 'Reported issue with roadside assistance delay last month.'
        },
        {
            severity: 'success',
            message: 'Customer provided positive feedback on the new mobile app features.'
        },
        {
            severity: 'warning',
            message: 'Customer inquired about cancellation policies, potential risk of churn.'
        }
    ];
    const demoActivityData = [
        {
            id: 1,
            event_type: 'page_visit',
            event_value: 'Home Page',
            created_at: new Date().toISOString(),
        },
        {
            id: 2,
            event_type: 'contact_created',
            event_value: 'New User',
            created_at: new Date().toISOString(),
        },
        {
            id: 3,
            event_type: 'chat_message',
            event_value: 'User asked about pricing',
            created_at: new Date().toISOString(),
        },
        {
            id: 4,
            event_type: 'ai_response',
            event_value: 'Provided pricing details',
            created_at: new Date().toISOString(),
        },
        {
            id: 5,
            event_type: 'agent_response',
            event_value: 'Confirmed pricing details',
            created_at: new Date().toISOString(),
        },
        {
            id: 6,
            event_type: 'faq_view',
            event_value: 'Viewed refund policy',
            created_at: new Date().toISOString(),
        },
        {
            id: 7,
            event_type: 'faq_vote',
            event_value: 'up',
            created_at: new Date().toISOString(),
        },
        {
            id: 8,
            event_type: 'faq_vote',
            event_value: 'down',
            created_at: new Date().toISOString(),
        },
        {
            id: 9,
            event_type: 'page_visit',
            event_value: 'Contact Us page',
            created_at: new Date().toISOString(),
        },
        {
            id: 10,
            event_type: 'contact_created',
            event_value: 'User signed up for newsletter',
            created_at: new Date().toISOString(),
        }
    ];
    const demoPreferences = [
        {
            name: 'Music',
            items: ['Jazz', 'Classical', 'Rock']
        },
        {
            name: 'Television',
            items: ['Documentaries', 'Comedies', 'Drama Series']
        },
        {
            name: 'Movies',
            items: ['Action', 'Sci-Fi', 'Horror']
        },
        {
            name: 'Sports',
            items: ['Soccer', 'Basketball', 'Tennis']
        },
        {
            name: 'News',
            items: ['Technology', 'Global', 'Local']
        },
        {
            name: 'Products',
            items: ['Electronics', 'Clothing', 'Home Appliances']
        },
        {
            name: 'Services',
            items: ['Streaming', 'Financial', 'Educational']
        },
        {
            name: 'Values',
            items: ['Integrity', 'Courage', 'Respect']
        },
        {
            name: 'Hobbies',
            items: ['Photography', 'Gardening', 'Baking']
        },
        {
            name: 'Tools',
            items: ['Power Tools', 'Software Development', 'Gardening Equipment']
        },
        {
            name: 'Places Likely to Visit',
            items: ['Japan', 'New Zealand', 'Canada']
        },
        {
            name: 'Food',
            items: ['Italian Cuisine', 'Mexican Cuisine', 'Indian Cuisine']
        },
        {
            name: 'Beverages',
            items: ['Coffee', 'Tea', 'Smoothies']
        },
        {
            name: 'Health',
            items: ['Meditation', 'Regular Check-ups', 'Balanced Diet']
        },
        {
            name: 'Wellness',
            items: ['Yoga', 'Spa Treatments', 'Mindfulness']
        },
        {
            name: 'Fitness',
            items: ['Gym', 'Cycling', 'Running']
        },
        {
            name: 'Literature',
            items: ['Modernist Novels', 'Poetry', 'Science Fiction']
        },
        {
            name: 'Gaming',
            items: ['Strategy Games', 'RPGs', 'Mobile Games']
        },
        {
            name: 'Fashion',
            items: ['Vintage', 'Contemporary', 'High Fashion']
        },
        {
            name: 'Art',
            items: ['Impressionism', 'Modern Art', 'Sculpture']
        },
        {
            name: 'Technology',
            items: ['Smart Home Devices', 'New Mobile Tech', 'Wearable Technology']
        },
        {
            name: 'Cooking',
            items: ['Baking', 'Grilling', 'Healthy Eating']
        },
        {
            name: 'Education',
            items: ['Online Courses', 'Workshops', 'Lifelong Learning']
        },
        {
            name: 'Finance',
            items: ['Investing', 'Saving for Retirement', 'Budgeting']
        },
        {
            name: 'Environmental',
            items: ['Recycling', 'Conservation', 'Sustainable Living']
        },
        {
            name: 'Social Media',
            items: ['Instagram', 'Twitter', 'LinkedIn']
        },
        {
            name: 'Pets',
            items: ['Dogs', 'Cats', 'Exotic Pets']
        },
        {
            name: 'Spirituality',
            items: ['Meditation Practices', 'Spiritual Retreats', 'Religious Studies']
        },
        {
            name: 'Professional',
            items: ['Networking', 'Skill Development', 'Promotions']
        },
        {
            name: 'Cultural',
            items: ['Festivals', 'Museum Visits', 'Cultural Exhibitions']
        },
        {
            name: 'Automobiles',
            items: ['Sports Cars', 'Electric Vehicles', 'Vintage Cars']
        },
        {
            name: 'Philanthropy',
            items: ['Local Food Banks', 'Educational Funds', 'Environmental Conservation']
        }
    ];
    
    
    
    
    const tabData = [
        { label: "Insights", icon: TipsAndUpdates, a11yKey: 'insights', component: () => <InsightsTemplate insights={demoInsightsData} /> },
        { label: "Activity", icon: Insights, a11yKey: 'activity', component: () => <ActivityTemplate activityData={demoActivityData}  /> },
        { label: "Preferences", icon: Psychology, a11yKey: 'preferences', component: () => <PreferencesTemplate preferences={demoPreferences} /> },
        { label: "Phone Calls", icon: Phone, a11yKey: 'phone-calls',
        component: () => (
       <Grid container spacing={2}>
           {demoCallData.map(phone_call => (
              <Grid item xs={12} sm={6} key={phone_call.id}>
                  <PhoneCallTemplate data={phone_call}  />
              </Grid>
           ))}
       </Grid>
    )  },
        { label: "Conversations", icon: Chat, a11yKey: 'conversations',
            component: () => (
               <Grid container spacing={2}>
                   {convoDemoData.map((conversation, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                          <ConversationTemplate data={conversation} />
                      </Grid>
                   ))}
               </Grid>
            )
        },
        {
            label: "Pets",
            icon: Pets,
            a11yKey: 'pets',
            component: () => (
               <Grid container spacing={2}>
                   {petData.map(pet => (
                      <Grid item xs={12} sm={6} key={pet.id}>
                          <PetTemplate data={pet} />
                      </Grid>
                   ))}
               </Grid>
            )
        },
        { label: "Vehicles", icon: DirectionsCar, a11yKey: 'vehicles',
            component: () => (
               <Grid container spacing={2}>
                   {vehicleData.map(vehicle => (
                      <Grid item xs={12} sm={6} key={vehicle.id}>
                          <VehicleTemplate data={vehicle} />
                      </Grid>
                   ))}
               </Grid>
            ) },
        {
            label: "Family",
            icon: FamilyRestroom,
            a11yKey: 'family',
            component: () => (
               <Grid container spacing={2}>
                   {familyMembers.map(member => (
                      <Grid item xs={12} sm={6} key={member.id}>
                      <Card sx={{ m: 1, boxShadow: 3 }} item xs={12} sm={6} key={member.id}>
                          <PersonTemplate key={member.id} data={member} summaryMode={true} />
                      </Card>
                      </Grid>
                   ))}
               </Grid>
            )
        },
        { label: "Places", icon: Place, a11yKey: 'places', component: (props) => <EntityBrowser {...props} internalUse='places' allowMap={true} />},
        { label: "Organizations", icon: Business, a11yKey: 'organizations', component: (props) => <EntityBrowser {...props} internalUse='organizations' /> },
        { label: "Documents", icon: PictureAsPdf, a11yKey: 'documents', component: (props) => <EntityBrowser {...props} internalUse='documents' /> },
        { label: "Media", icon: PhotoLibrary, a11yKey: 'media', component: (props) => <EntityBrowser {...props} internalUse='media' /> },
        { label: "Events", icon: Event, a11yKey: 'events', component: (props) => <EntityBrowser {...props} internalUse='event' allowCalendar={true} allowMap={true} />},
    ];


    const initialType = useMemo(() => {
        return tabData.find(tab => tab.a11yKey === selectedTab)?.a11yKey || tabData[0].a11yKey;
    }, [selectedTab, tabData]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            spacing={2}
        >
            <Grid item xs={12} container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{textAlign:'center', marginLeft: 0}}>
                <TabContext value={initialType}>
                    <Grid item xs={12} container justifyContent={'space-between'}>
                        <Grid item xs={11}>
                            <TabList
                                style={{ width: '100%' }}
                                value={initialType}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant={ "scrollable"}
                                scrollButtons="auto"
                                aria-label="associated entities"
                                //centered={true}
                            >
                                {tabData.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        label={
                                            <span className={classes.icon}>
                    <tab.icon fontSize="small" />&nbsp;{tab.label}
                </span>
                                        }
                                        {...a11yProps(tab.a11yKey)}
                                    />
                                ))}
                            </TabList>

                        </Grid>
                    </Grid>


                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <Box className={classes.tabPanel}>
                                {tabData.map((tab, index) => (
                                    <Box key={index} value={initialType} index={tab.label}>
                                        {activeTab === tab.a11yKey && (
                                           tab.component ? <tab.component data={tab.data} /> : <div>No Component Available</div>
                                        )}
                                    </Box>
                                ))}
                        </Box>
                    </Grid>
                </TabContext>
            </Grid>
        </Grid>
    );
};

export default AttachedEntityViewer;
