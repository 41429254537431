import React, {useMemo, useState} from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    List, Card,
    MenuItem,
    Select, Box,
    FormControl,
    InputLabel, Grid,
    ButtonGroup, Tooltip,
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import {ArrowUpward, ArrowDownward, Public} from '@mui/icons-material';
import EntityCard from './EntityCard2';
import useTestData from "../../lib/hooks/useTestData";
import {DataGrid} from "@mui/x-data-grid";
import {prettyDateTime, prettyNumber, prettyDate, toCapitalizeFromUnderScore, truncate} from "../../lib/helpers/helpers";
import {useParams, useNavigate} from "react-router-dom";
import menuItems from "../MainMenu/menuItems";
import PlaceBrowser from "../PlaceBrowser/PlaceBrowser";
import EventIcon from "@mui/icons-material/Event";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
const localizer = dayjsLocalizer(dayjs)

const defaultColumns = [
    {
        field: 'type',
        headerName: 'Entity Type',
        width: 150,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        )
    },
    {
        field: 'source',
        headerName: 'Source',
        width: 180,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <a href={params.value} target="_blank" rel="noopener noreferrer">{truncate(params.value, 50)}</a>
            </Tooltip>
        )
    },
    {
        field: 'content',
        headerName: 'Content',
        width: 300,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <span>{truncate(params.value, 100)}</span>
            </Tooltip>
        )
    },
    {
        field: 'sentiment',
        headerName: 'Sentiment',
        width: 120,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <strong style={{
                    color: params.value === 'negative' ? 'red' : params.value === 'neutral' ? 'gray' : 'green'
                }}>
                    {toCapitalizeFromUnderScore(params.value)}
                </strong>
            </Tooltip>
        )
    },
    {
        field: 'keywords',
        headerName: 'Keywords',
        width: 200,
        renderCell: (params) => (
            <Tooltip title={params.value?.join(', ')}>
                <span>{truncate(params.value?.join(', '), 100)}</span>
            </Tooltip>
        )
    },
    {
        field: 'interaction_count',
        headerName: 'Interactions',
        width: 130,
        renderCell: (params) => (
            <Tooltip title={`Total interactions: ${params.value}`}>
                <span>{params.value}</span>
            </Tooltip>
        )
    },
    {
        field: 'related_entities',
        headerName: 'Related Entities',
        width: 220,
        renderCell: (params) => (
            <Tooltip title={params.value?.join(', ')}>
                <span>{truncate(params.value?.join(', '), 100)}</span>
            </Tooltip>
        )
    },
    {
        field: 'confidentiality',
        headerName: 'Confidentiality',
        width: 140,
        renderCell: (params) => (
            <Tooltip title={params.value}>
                <span>{params.value ? 'Confidential' : 'Public'}</span>
            </Tooltip>
        )
    },
    {
        field: 'confidence_score',
        headerName: 'Confidence Score',
        width: 150,
        renderCell: (params) => (
            <Tooltip title={`${params.value?.toFixed(2)}%`}>
                <span>{`${params.value?.toFixed(2)}%`}</span>
            </Tooltip>
        )
    },
    {
        field: 'nsfw',
        headerName: 'NSFW',
        width: 100,
        renderCell: (params) => (
            <Tooltip title={params.value ? 'Not Safe for Work' : 'Safe'}>
                <span style={{ color: params.value ? 'red' : 'green' }}>
                    {params.value ? 'Yes' : 'No'}
                </span>
            </Tooltip>
        )
    },
    {
        field: 'scraped_at',
        headerName: 'Scraped At',
        width: 150,
        renderCell: (params) => {
            const content = params.value;
            return (
                <Tooltip title={prettyDateTime(content)} placement="top">
                    <span>{prettyDate(content)}</span>
                </Tooltip>
            );
        }
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 100,
        renderCell: (params) => {
            const content = params.value;
            return (
                <Tooltip title={prettyDateTime(content)} placement="top">
                    <span>{prettyDate(content)}</span>
                </Tooltip>
            );
        }
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        width: 110,
        renderCell: (params) => {
            const content = params.value;
            return (
                <Tooltip title={prettyDateTime(content)} placement="top">
                    <span>{prettyDate(content)}</span>
                </Tooltip>
            );
        }
    }
];
const EntityBrowser = ({internalUse = null, demoData = undefined, overrideColumns = undefined, allowCalendar = false, allowMap = false, disableGridView = false, overrideTitle = undefined, isLoading = undefined,
                           rowCount, pageSize, setPageSize, setPage}) => {
    const { category } = useParams(); // entity type
    const [viewMode, setViewMode] = useState(allowCalendar ? 'calendar' : allowMap ? 'map' : 'table' || 'list' || 'map');
    const [sort, setSort] = useState('created_at');
    const [sortAsc, setSortAsc] = useState(false);
    const [activeEntity, setActiveEntity] = useState(null);
    const {data, isLoading: isLoadingTestData, isRefetching, refetch} = useTestData()
    const navigate = useNavigate();

    const handleRowClick = (params) => {
        navigate(`/browse/${(internalUse || category)}/${params.id}`);
    };

    const handleEntityClick = (entity) => {
        setActiveEntity(activeEntity?.id === entity.id ? null : entity);
    };
    const filteredData = useMemo(() => {
        if (demoData) {
            return demoData
        }
        if (activeEntity) {
            // Extract related entities based on activeEntity's properties
            const relatedEntityIds = new Set();

            // Example: If activeEntity has relatedPhotos, relatedVideos, etc.
            if (activeEntity.relatedPhotos) relatedEntityIds.add(...activeEntity.relatedPhotos);
            if (activeEntity.relatedVideos) relatedEntityIds.add(...activeEntity.relatedVideos);
            if (activeEntity.relatedArticles) relatedEntityIds.add(...activeEntity.relatedArticles);
            if (activeEntity.citedBy) relatedEntityIds.add(...activeEntity.citedBy);
            if (activeEntity.relatedPeople) relatedEntityIds.add(...activeEntity.relatedPeople);
            if (activeEntity.vehicles) relatedEntityIds.add(...activeEntity.vehicles);
            if (activeEntity.phoneCalls) relatedEntityIds.add(...activeEntity.phoneCalls);
            if (activeEntity.attendedEvents) relatedEntityIds.add(...activeEntity.attendedEvents);
            if (activeEntity.participants) relatedEntityIds.add(...activeEntity.participants);
            if (activeEntity.discussedDocuments) relatedEntityIds.add(...activeEntity.discussedDocuments);
            if (activeEntity.place) relatedEntityIds.add(activeEntity.place);

            // Include the active entity itself and any directly related entities
            relatedEntityIds.add(activeEntity.id);

            // Filter the data to include only related entities
            return data.filter(item => relatedEntityIds.has(item.id));
        }
        const flattenChildren = (items) => items.reduce((acc, item) => {
            // Add the current item and recursively add any children it might have
            const childrenItems = item.children ? flattenChildren(item.children) : [];
            return acc.concat(item, childrenItems);
        }, []);
        const foundItem = menuItems.find(item => item.slug === internalUse);
        return foundItem && foundItem.children ? flattenChildren(foundItem.children) : data;
    }, [menuItems, internalUse, data, activeEntity, demoData]);

    const handleSortChange = (event) => {
        setSort(event.target.value);
    };

    const sortedData = useMemo(() => {
        if (!filteredData || !Array.isArray(filteredData)) {
            return [];
        }

        let sortedList = [...filteredData];

        // If an active entity is set, ensure it is at the top
        if (activeEntity) {
            sortedList = sortedList.filter(item => item.id !== activeEntity.id);
            sortedList.unshift(activeEntity);
        }

        const sorter = (a, b) => {
            let valueA = a[sort];
            let valueB = b[sort];

            // Example handling for dates, strings, or numbers:
            if (valueA instanceof Date && valueB instanceof Date) {
                // Sort dates
                return sortAsc ? valueA - valueB : valueB - valueA;
            } else if (typeof valueA === 'string' && typeof valueB === 'string') {
                // Sort strings
                return sortAsc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else {
                // Default to numeric sort
                return sortAsc ? valueA - valueB : valueB - valueA;
            }
        };

        return sortedList.slice(1).sort(sorter).unshift(activeEntity) && sortedList;
    }, [filteredData, sort, sortAsc, activeEntity]);


    console.log("sortedData ", sortedData)
    return (
        <>
            <AppBar position="static" color="transparent" elevation={1}>
                <Card>
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {prettyNumber(sortedData.length)} {(internalUse || category) ? toCapitalizeFromUnderScore(internalUse || category) : overrideTitle ?? "Entities"}
                    </Typography>
                    <ButtonGroup variant="text" color="inherit">

                        {allowCalendar && <IconButton onClick={() => setViewMode('calendar')}
                                     color={viewMode === 'calendar' ? 'primary' : 'inherit'}>
                            <EventIcon/>
                        </IconButton>}
                        {allowMap && <IconButton onClick={() => setViewMode('map')}
                                     color={viewMode === 'map' ? 'primary' : 'inherit'}>
                            <Public/>
                        </IconButton>}
                        {!disableGridView &&<>
                            <IconButton onClick={() => setViewMode('table')} color={viewMode === 'table' ? 'primary' : 'inherit'}>
                            <ViewListIcon/>
                        </IconButton>
                         <IconButton onClick={() => setViewMode('list')}
                                     color={viewMode === 'list' ? 'primary' : 'inherit'}>
                            <ViewModuleIcon/>
                        </IconButton>
                        </>}
                    </ButtonGroup>
                </Toolbar>
                {viewMode === 'list' &&
                        <Toolbar>
                    <FormControl size="small" variant="outlined" style={{marginRight: 8}}>
                        <InputLabel id="sort-label">Sort by</InputLabel>
                        <Select
                            labelId="sort-label"
                            id="sort-select"
                            value={sort}
                            label="Sort by"
                            onChange={handleSortChange}
                        >
                            <MenuItem value="created_at">Created Date</MenuItem>
                            <MenuItem value="updated_at">Updated Date</MenuItem>
                            {!internalUse && <MenuItem value="indexed_at">Indexed Date</MenuItem>}
                        </Select>
                    </FormControl>
                    <IconButton onClick={() => setSortAsc(!sortAsc)}>
                        {sortAsc ? <ArrowUpward/> : <ArrowDownward/>}
                    </IconButton>
                </Toolbar>
                    }</Card>
            </AppBar>
            {viewMode === 'list' ?
            <>
                <Box sx={{ overflowY: 'auto', maxHeight: 700, mb: 10, pb:8, width: '100%' }}>
                    <Grid container spacing={2}>
                        {sortedData?.map((item) => (
                            <Grid item xs={12} sm={6} md={activeEntity?.id === item.id ? 12 : 4} lg={activeEntity?.id === item.id ? 12 : 4} key={item.id}>
                                <EntityCard entity={item} isActive={activeEntity?.id === item.id} onClick={() => handleEntityClick(item)} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </>
            :
                viewMode === 'table' ?
                    <>
                    <DataGrid
                        columns={overrideColumns || defaultColumns || []}
                        rows={sortedData || []}
                        rowsPerPageOptions={[5]}
                        loading={isLoading}
                        onRowClick={handleRowClick}
                        
                        rowCount={rowCount}
                        pageSize={pageSize || 5}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        onPageChange={(newPage) => setPage(newPage)}
                        paginationMode={rowCount ? "server" : "client"}
                        //checkboxSelection
                        //disableSelectionOnClick
                    />
                </>
            :
                viewMode === 'calendar' ?
                    <>
                        <Calendar
                           localizer={localizer}
                           events={demoData}
                           startAccessor="start"
                           endAccessor="end"
                           style={{ height: 500 }}
                        />
                </>
                    :
                    <>
                        <PlaceBrowser fullSize={true} />
                </>

            }
        </>
    );
};

export default EntityBrowser;
