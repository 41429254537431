import React, {useMemo} from 'react'
import Screen from '../../components/Screen/Screen'
import {
    Box, Tooltip,
} from '@mui/material'
import {
    Public, Map, Place, Favorite,
} from "@mui/icons-material";
import {prettyDate, prettyDateTime, toCapitalizeFromUnderScore, truncate} from "../../lib/helpers/helpers";
import states from "../../lib/constants/usa_states";
import PlaceBrowser from "../../components/PlaceBrowser/PlaceBrowser";
import EntityBrowser from "../../components/EntityBrowser";

const transitionStyle = {
  transition: 'width 0.3s ease-in-out',
}
const MapScreen = () => {
    const sidebarConfig = useMemo(() => [
        {
            icon: <Public />,
            text: 'World View',
            slug: 'worldview',
            path: '/worldview',
        },
        {
            icon: <Place />,
            text: 'USA',
            slug: 'usa',
            path: '/usa',
        },
        {
            icon: <Map />,
            text: 'By State',
            slug: 'by-state',
            children: states.map(item => ({ text: item, path: `/state/${item.toLowerCase().replace(/\s+/g, '-')}` })),
        },
        {
            icon: <Map />,
            text: 'By Continent',
            slug: 'by-continent',
            children: [
                {
                    text: 'Asia',
                    path: '/continent/asia',
                },
                {
                    text: 'Europe',
                    path: '/continent/europe',
                },
                {
                    text: 'Africa',
                    path: '/continent/africa',
                },
                {
                    text: 'North America',
                    path: '/continent/north-america',
                },
                {
                    text: 'South America',
                    path: '/continent/south-america',
                },
                {
                    text: 'Australia',
                    path: '/continent/australia',
                },
                {
                    text: 'Antarctica',
                    path: '/continent/antarctica',
                }
            ],
        },
        {
            text: "Favorites", Icon: Favorite, secondaryText: "0", children: [
                { text: "No Favorites added" }
            ]
        },
    ], []);
  return (
    <>
      <Screen title={'Location Index'} showSidebarNav={true} sidebarConfig={sidebarConfig}>
        <Box sx={{ transitionStyle, p: 0 }}>
            <EntityBrowser allowMap={true}/>
        </Box>
      </Screen>
    </>
  )
}

export default MapScreen
