import React, { useState, useRef } from 'react';
import { Button, Chip, Dialog, Divider, DialogActions, DialogContent, DialogTitle, Box, IconButton, TextField, Typography, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const EntityTags = ({ entityType, testData = [] }) => {
    const [tags, setTags] = useState(testData);  // Initialize state with testData
    const [modalOpen, setModalOpen] = useState(false);
    const tagInputRef = useRef(null);

    const handleAddTag = (newTag) => {
        if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleModalAddTag = () => {
        const newTag = tagInputRef.current.value;
        handleAddTag(newTag);
        tagInputRef.current.value = "";  // Clear input after adding
        closeModal();
    };

    return (
        <Box sx={{ typography: 'caption', mt:2 }}>
            <Divider sx={{ mb:2 }}/>
            <Typography variant="subtitle2">Tags</Typography>
            <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={1} mb={2}>
                    {tags.map(tag => (
                        <Chip
                            key={tag}
                            label={tag}
                            onDelete={() => handleDeleteTag(tag)}
                            size="small"
                            variant="outlined"
                        />
                    ))}
                </Stack>
                <Box display="flex" justifyContent="flex-end">
                    <Button variant="outlined" size="small" startIcon={<AddIcon fontSize={"small"} />} onClick={openModal}>
                        Add
                    </Button>
                </Box>
            </Stack>


            <Dialog open={modalOpen} onClose={closeModal}>
                <DialogTitle>Add a New Tag</DialogTitle>
                <DialogContent>
                    <TextField
                        inputRef={tagInputRef}  // Changed from ref to inputRef for TextField
                        type="text"
                        placeholder="Type new tag"
                        fullWidth  // Changed from style width to fullWidth for better practice
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalAddTag}>Add</Button>
                    <Button onClick={closeModal}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EntityTags;
